import React, {useEffect} from 'react';
import {Form, message, Modal} from "antd";
import {getContestProblem, putContestProblem} from "../../../lib/api_contest";
import ContestProblemForm from "./contest_problem_form";

/**
 * 比赛题目编辑弹窗
 * @param id
 * @param onOk
 * @param onCancel
 * @returns {JSX.Element}
 * @constructor
 */
const ContestProblemEditModal = ({id, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const fetchData = () => {
        getContestProblem(id)
            .then(res => {
                if (res.code === 0) {
                    if (!res.data.color) {
                        res.data.color = "#ffffff";
                    }
                    form.setFieldsValue(res.data);
                }
            })
    }

    useEffect(fetchData, []);

    const handleFinish = () => {
        form.validateFields()
            .then((values) => {
                console.log('Received values of form: ', values);
                putContestProblem(id, values)
                    .then(res => {
                        if (res.code === 0) {
                            message.success("保存成功!");
                            handleOk();
                        } else {
                            message.error(res.message);
                        }
                    })
            })
    };

    const handleOk = () => {
        onOk && onOk();
    }

    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title={"修改题目"}
            open={true}
            onCancel={handleCancel}
            onOk={handleFinish}
            width={700}
        >
            <ContestProblemForm form={form} onFinish={handleFinish} initialValues={{color: "#ffffff"}}/>
        </Modal>)
}

export default ContestProblemEditModal;