import React from "react";

/**
 * 比赛总时长的秒数转换为对应的小时，分钟，天等
 * @param duration
 * @returns {string}
 * @constructor
 */
const ContestDuration = ({duration}) => {
    if (duration < 60) return duration + "秒";
    if (duration < 60 * 60) return (duration / 60).toFixed(1) + "分钟";
    if (duration < 60 * 60 * 24) return (duration / 60 / 60).toFixed(1) + "小时";
    return (duration / 60 / 60 / 24).toFixed(1) + "天";
}

export default React.memo(ContestDuration);