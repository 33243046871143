/**
 * @name: 登录页面
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import Mobile_login from './mobile_login.jsx';
import {getAccountInfo} from "../../lib/api_account.js";
import {useAccountStore} from "../../stores/useAccountStore";
import {useRoomsStore} from "../../stores/useRoomsStore";

const Login = () => {
    const {setAccount} = useAccountStore();
    const {setRooms} = useRoomsStore();

    useEffect(() => {
        // 获得登录用户信息
        getAccountInfo()
            .then(res => {
                if (res.code === 0) {
                    // 设置用户信息
                    setAccount(res.data);
                    setRooms(res.data.rooms);
                } else {
                }
            })
    }, []);

    return (
        <div style={{width: 368, margin: "0 auto", paddingTop: 150}}>
            <h1 style={{textAlign: "center"}}>小码蚁编程 - 管理后台</h1>
            <Mobile_login/>
        </div>
    )
}

export default withRouter(Login);