import React from 'react';
import {Modal, Input, message, Form} from 'antd';
import {postChangePassword} from "../lib/api_account";
import md5 from 'md5';
import {usePasswordModalStore} from "../stores/usePasswordModalStore";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};

const Change_password = () => {
    const {open, hidePasswordModal} = usePasswordModalStore();
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                postChangePassword(md5(values.oldpassword), md5(values.password))
                    .then(result => {
                        if (result.code === 0) {
                            message.success("修改成功！");
                            handleCancel();
                        }
                    })
            })
    }
    const handleCancel = () => {
        hidePasswordModal();
    }
    return (
        <Modal
            title="修改密码"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form {...formItemLayout}
                  form={form}
            >
                <Form.Item label="旧密码"
                           name="oldpassword"
                           hasFeedback
                           rules={[
                               {
                                   required: true,
                                   message: '请输入旧密码!',
                               },
                           ]}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item label="新密码"
                           name="password"
                           hasFeedback
                           rules={[
                               {
                                   required: true,
                                   message: '请输入密码！',
                               },
                           ]}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item label="重复新密码"
                           name="confirm"
                           hasFeedback
                           rules={[
                               {
                                   required: true,
                                   message: '请再次输入密码！',
                               },
                               ({getFieldValue}) => ({
                                   validator(_, value) {
                                       if (!value || getFieldValue('password') === value) {
                                           return Promise.resolve();
                                       }
                                       return Promise.reject(new Error('两次密码输入不一致！'));
                                   },
                               }),
                           ]}>
                    <Input.Password/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default Change_password;
