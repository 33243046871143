import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {postTeacher} from "../../../lib/api_teachers";
import {Modal, Input, Form, Select} from 'antd';
import md5 from 'md5';
import FormDanXuan from './form_danxuan';
import FormDanXuanAnswer from './form_danxuan_answer';
import MdEditor from '../../../components/md-editor/md-editor';
import {putQuestion} from "../../../lib/api_questions";
import TagsSelect from "./tags_select";


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const QuestionEditModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(data)
    }, []);

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.id = data.id;
                // values.type = "单选题";
                putQuestion(values)
                // values.password = md5(values.password);
                // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    // 智能识别
    const handleShibie = (value) => {
        // console.log(e.target.value);
        var pattern = /^([^A]*)A\.([^A]*)B\.([^B]*)C\.([^C]*)D\.([^D]*)$/m;
        // console.log(pattern.match(e.target.value));
        let result = value.match(pattern);
        console.log(result);
        let title = result[1].trim();
        var pattern2 = /^\d*\.([\s\S]*)$/m;
        let result2 = title.match(pattern2);
        console.log(result2)
        title = result2[1].trim();

        form.setFieldsValue({
            title: title,
            content: JSON.stringify({
                title: title,
                options: [{
                    key: "A",
                    value: result[2].trim()
                }, {
                    key: "B",
                    value: result[3].trim()
                }, {
                    key: "C",
                    value: result[4].trim()
                }, {
                    key: "D",
                    value: result[5].trim()
                }]
            })
        })

    }
    return (
        <Modal
            title="添加单选题"
            visible={true}
            onCancel={onCancel}
            onOk={handleOk}
            width={800}
        >
            <MdEditor onChange={handleShibie}/>

            {/*<Input.TextArea rows={4} placeholder="智能识别" onChange={handleShibie}/>*/}
            <Form
                {...layout}
                form={form}
            >
                <Form.Item
                    label="摘要标题"
                    name="title"
                    rules={[{
                        required: true,
                        message: '请输入摘要标题'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="题目配置"
                    name="content"
                    rules={[{
                        required: true,
                        message: '请输入题目配置'
                    }]}
                >
                    <FormDanXuan/>
                </Form.Item>
                <Form.Item
                    label="答案配置"
                    name="answer"
                    rules={[{
                        required: true,
                        message: '请输入答案配置'
                    }]}
                >
                    <FormDanXuanAnswer/>
                </Form.Item>
                <Form.Item
                    label="标签"
                    name="tags"
                >
                    <TagsSelect/>
                </Form.Item>
            </Form>
        </Modal>
    )
};


QuestionEditModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default QuestionEditModal;