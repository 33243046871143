import React from 'react';
import {Form, Input} from 'antd';
import MdEditor from '../../../components/md-editor/md-editor';

/**
 * 文档表单
 * @param form  表单实例
 * @param onFinish  表单提交回调
 * @param initialValues 表单初始值
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentForm = ({form, onFinish, initialValues}) => {
    return (
        <Form
            labelCol={{flex: '110px'}}
            // wrapperCol={{flex: 1}}
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Form.Item name={"title"} label={"标题"}>
                <Input/>
            </Form.Item>
            <Form.Item name={"content"} label={"正文"}>
                <MdEditor/>
            </Form.Item>
        </Form>
    )
};


export default DocumentForm;