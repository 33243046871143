import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ConfigProvider} from 'antd';
// import zh_CN from 'antd/lib/locale-provider/zh_CN';
import zh_CN from 'antd/es/locale/zh_CN';
import moment from 'moment';
// import 'moment/dist/locale/zh-cn';
import 'moment/locale/zh-cn';
import {AxiosInterceptor} from "./lib/axiosInterceptor";

moment.locale('zh-cn');


ReactDOM.render((
    <BrowserRouter>
        <ConfigProvider locale={zh_CN}>
            <AxiosInterceptor>
                <App/>
            </AxiosInterceptor>
        </ConfigProvider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
