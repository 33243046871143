/**
 * @name: 手机号登录页面，todo 需要完善验证码登录功能
 * @description:
 * @author: Wood
 * @date: 2019/4/4
 * @update:
 */
import React, {useState} from 'react';
import md5 from 'md5';
import {LockOutlined, MobileOutlined} from '@ant-design/icons';
import {Button, Input} from 'antd';

import {postAccountLogin} from "../../lib/api_account.js";
import styles from './mobile_login.module.css'
import {useAccountStore} from "../../stores/useAccountStore";

const Mobile_login = () => {
    const [login_name, setLoginName] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const {setAccount} = useAccountStore();

    const handleLogin = () => {
        if (login_name.length === 0) {
            return setMessage('请输入手机号码');
        }
        if (password.length === 0) {
            return setMessage('请输入密码');
        }
        postAccountLogin(login_name, md5(password))
            .then((result) => {
                if (result.code === 0) {
                    setAccount(result.data)
                } else {
                    setMessage(result.message);
                }
            })
    };

    return (
        <div>
            <div className={styles.item}>
                <Input size="large"
                       prefix={<MobileOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                       value={login_name}
                       placeholder='请输入手机号码'
                       onChange={(event) => setLoginName(event.target.value)}/>
            </div>
            <div className={styles.item}>
                <Input size="large"
                       prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                       value={password}
                       placeholder='请输入密码'
                       type='password'
                       onChange={(event) => setPassword(event.target.value)}/>
            </div>
            <div className={styles.message}>{message}</div>
            <div className={styles.item}>
                <Button block
                        size='large'
                        type='primary'
                        className='button-color-sunset'
                        onClick={handleLogin}>
                    登录
                </Button>
            </div>
        </div>
    );
}
export default Mobile_login;
