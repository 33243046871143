import React, {Component, useEffect} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {Layout, Divider} from 'antd';
import './App.css';
import Calendar from './containers/calendar';
import Stat from './containers/stat/stat';
import Login from './containers/login/login';
import SiderBar from './components/sider-menu/sidebar';
import {getFirstMenu} from "./components/sider-menu/menu.config";
import Affairs from './containers/affairs/Router';
import Teaching from './containers/teaching/Router';
import Settings from './containers/settings/Router';
// import Operation from './containers/operation/Router';
import Change_password from './containers/change_password';
import Test from './containers/calendar/calendar-week';
import {useAccountStore} from "./stores/useAccountStore";


const App = ({history}) => {
    const {account} = useAccountStore();
    const [path, setPath] = React.useState("/");
    const [siderWidth, setSiderWidth] = React.useState(224);
    const routeChange = (route) => {
        console.log(route); // 这个route里面有当前路由的各个参数信息
        setPath(route.pathname)
        // 获取菜单情况
        let menu = getFirstMenu(route.pathname)
        if (menu && menu.children.length === 0) {
            setSiderWidth(92)
        } else {
            setSiderWidth(224)
        }
    }

    useEffect(() => {
        // 路由监听
        let historyinstance = history.listen(routeChange);
        routeChange(history.location)
        return () => {
            // 取消监听
            historyinstance && historyinstance();
        }
    }, []);
    if (!account) {
        return (<Login/>);
    }
    return (
        <Layout style={{minHeight: "100%"}}>
            {/*侧边导航栏*/}
            <SiderBar path={path}/>
            <Layout.Content style={{marginLeft: siderWidth}}>
                <Switch>
                    <Route exact path="/"
                           component={Calendar}/>
                    <Route path="/calendar"
                           component={Calendar}/>
                    <Route path="/affairs"
                           component={Affairs}/>
                    <Route path="/teaching"
                           component={Teaching}/>
                    <Route path="/settings"
                           component={Settings}/>
                    {/*<Route path="/operation"*/}
                    {/*       component={Operation}/>*/}
                    <Route exact path="/stat"
                           component={Stat}/>
                    <Route exact path="/test"
                           component={Test}/>
                </Switch>
                <Change_password/>

            </Layout.Content>
        </Layout>
    );
}
export default withRouter(App);
