import {create} from 'zustand'
import cookies from "js-cookie";

let config = window.location.host.endsWith('yzsteam.com') ? {'domain': 'yzsteam.com', expires: 7} : {expires: 7};

export const useAccountStore = create((set) => ({
    account: null,
    setAccount: (account) => set(() => {
        cookies.set('authorization', account.login_key, config);
        return {account}
    }),
    signOut: () => set(() => {
        cookies.remove('authorization', config);
        return {account: null};
    }),
}));