/**
 * @name: 导航菜单配置
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {
    CalendarOutlined,
    CrownOutlined,
    FormOutlined,
    TeamOutlined,
    UserOutlined,
    LineChartOutlined,
    SettingOutlined,
    BookOutlined,
    CommentOutlined,
    NotificationOutlined,
    PictureOutlined,
} from '@ant-design/icons';

/**
 * 菜单配置
 * @type {[]}
 */
const menus = [{
    path: "/",
    minititle: "日历",
    title: "日历",
    icon: <CalendarOutlined/>,
    children: []
}, {
    path: "/affairs",
    minititle: "教务",
    title: "教务中心",
    icon: <TeamOutlined/>,
    children: [{
        path: "/affairs/classes",
        title: "班级管理",
        icon: <TeamOutlined/>,
        children: []
    },{
        path: "/affairs/students",
        title: "学员管理",
        icon: <UserOutlined/>,
        children: []
    },{
        path: "/affairs/notification",
        title: "上课提醒",
        icon: <NotificationOutlined />,
        children: []
    },{
        path: "/affairs/photos",
        title: "相册",
        icon: <PictureOutlined />,
        children: []
    },{
        path: "/affairs/dianping",
        title: "点评",
        icon: <PictureOutlined />,
        children: []
    }]
},{
    path: "/teaching",
    minititle: "课程",
    title: "课程中心",
    icon: <BookOutlined />,
    children: [{
        path: "/teaching/courses",
        title: "课程管理",
        icon: null,
        children: []
    },{
        path: "/teaching/tasks",
        title: "任务管理",
        icon: null,
        children: []
    },{
        path: "/teaching/documents",
        title: "文档管理",
        icon: null,
        children: []
    },{
        path: "/teaching/scratchs",
        title: "Scratch作品管理",
        icon: null,
        children: []
    },{
        path: "/teaching/challenges/python",
        title: "Python题库",
        icon: null,
        children: []
    },{
        path: "/teaching/questions",
        title: "试题库",
        icon: null,
        children: []
    },{
        path: "/teaching/papers",
        title: "试卷",
        icon: null,
        children: []
    },{
        path: "/teaching/exams",
        title: "测验",
        icon: null,
        children: []
    },{
        path: "/teaching/problems",
        title: "c++题库",
        icon: null,
        children: []
    },{
        path: "/teaching/contest",
        title: "比赛",
        icon: null,
        children: []
    },{
        path: "/teaching/training",
        title: "题单",
        icon: null,
        children: []
    }]
},{
    path: "/stat",
    minititle: "统计",
    title: "统计",
    icon: <LineChartOutlined/>,
    children: []
},{
    path: "/settings",
    minititle: "设置",
    title: "系统设置",
    icon: <SettingOutlined />,
    children: [{
        path: "/settings/teachers",
        title: "教师管理",
    },{
        path: "/settings/rooms",
        title: "教室管理",
    },{
        path: "/settings/wx_access_token",
        title: "公众号",
    },{
        path: "/settings/sys_dict_type",
        title: "数据字典",
    },{
        path: "/settings/wxauth",
        title: "微信授权",
    }
    ]
}];

/**
 * 根据路径获取一级菜单
 * @param path 路径
 * @return {*} 一级菜单
 */
export const getFirstMenu = function (path) {
    let pathname = path;
    let index = pathname.indexOf('/', 1);
    index = index === -1 ? pathname.length : index;
    let firstpath = pathname.substring(0, index);
    // console.log(props, firstpath);
    // 获取第一层菜单
    if (firstpath) {
        let menu2 = menus.find(_ => _.path === firstpath);
        if (menu2) {
            return menu2;
        }
    }
    return menus[0];
};

export default menus;