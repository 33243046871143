/**
 * @name: 班级详情
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {getStudent, resetPassword} from "../../../lib/api_students";
import {putLessonsUsers} from "../../../lib/api_lessons";
import {postUnlock} from "../../../lib/api_courses";
import {
    Button,
    Table,
    Tabs,
    PageHeader,
    Descriptions,
    Modal,
    Input,
    DatePicker,
    message,
    Radio,
    Divider,
    Card,
    Tag,
    Tooltip,
    Row,
    Col,
} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import StudentEditModal from '../students/student_edit_modal';
import moment from 'moment';
import styles from './detail.module.css'
import FollowUpList from './followup-list';
import ClassesList from '../classes/classes_list';
import DictName from '../../../components/dict/dict-name';
import CoursesList from './courses-list';
import XuKeModal from './xuke-modal';
import KeShiList from './keshi-list';
import CardCard from './card-card';
import LessonProgress from "../classes/lesson_progress";
import CoursesUsersEditModal from "./courses_users_edit_modal";

const {Column} = Table;

class StudentDetail extends Component {
    state = {
        student_info: {
            teams: [],
            courses: [],
            keshis: [],
        },
        editStudentInfo: null,
        courses_users_edit: null,
        xu_fei_modal: false,
        xu_fei: {
            create_on: moment().format("YYYY-MM-DD HH:mm"),
            keshi: 0,
            is_gift: 0
        },
        editItem: null,
    };

    componentDidMount() {
        this.loadStudentInfo();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取学生信息
    loadStudentInfo = () => {
        getStudent(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        student_info: result.data,

                    })
                } else {

                }
            })
    };

    // 修改课时记录
    handleCoursesUsersEdit = () => {
        let {courses_users_edit} = this.state;
        if (courses_users_edit) {
            let {lessons_id, user_id, create_on, keshi} = courses_users_edit;
            putLessonsUsers(lessons_id, user_id, create_on, keshi)
                .then(res => {
                    if (res.code === 0) {
                        this.loadStudentInfo();
                        this.setState({courses_users_edit: null})
                    } else {
                        message.error(res.message)
                    }
                })

        }
    };


    // handleXuKe = () => {
    //     if (!this.state.xu_fei.create_on) {
    //         message.error("请选择时间");
    //         return;
    //     }
    //     if (this.state.xu_fei.keshi === 0) {
    //         message.error("请填写课时");
    //         return;
    //     }
    //
    //     // 提交数据到服务器
    //     postKeshi(
    //         this.state.student_info.id,
    //         this.state.xu_fei.keshi,
    //         this.state.xu_fei.remark,
    //         this.state.xu_fei.create_on,
    //         this.state.xu_fei.is_gift
    //     ).then(res => {
    //         if (res.code === 0) {
    //             this.loadStudentInfo();
    //             this.setState({xu_fei_modal: false})
    //         } else {
    //             message.error(res.message)
    //         }
    //     })
    //
    // };

    handleUnlockProject = (classes_project_id) => {
        postUnlock(classes_project_id, this.state.student_info.id)
            .then(res => {
                if (res.code === 0) {
                    message.success("解锁成功");
                    this.loadStudentInfo();
                } else {
                    message.error(res.message)
                }
            })
    };

    // renderVideos = (dianping_videos)=>{
    //     if(!dianping_videos) return null;
    //
    //     {record.dianping_videos && (()=>{
    //         let config = JSON.parse( record.dianping_videos);
    //         if(!config) return null;
    //
    //         return (
    //             <div>
    //                 <video src={config.url} controls="controls">
    //                     您的浏览器不支持 video 标签。
    //                 </video>
    //             </div>
    //         )
    //     })
    //
    //     }
    // }

    // 隐藏重置密码对话框
    hideEditModal = () => {
        this.setState({
            editItem: null,
        });
    };

    handleEditModal = () => {
        // 重置密码操作
        this.setState({
            editModalConfirmLoading: true
        });
        resetPassword(this.state.editItem.id)
            .then(result => {
                if (result.code === 0) {
                    this.setState({
                        editModalConfirmLoading: false,
                        editItem: null
                    });
                    message.success("重置密码成功！");
                    // this.loadClasses()
                } else {
                    this.setState({
                        editModalConfirmLoading: false,
                    });
                    message.error(result.message);
                }
            })
            .catch(err => {
                this.setState({
                    editModalConfirmLoading: false,
                });
                message.error(err);
            });

    };

    render() {
        let {student_info} = this.state;
        let keshi_pay = 0;
        let keshi_gift = 0;
        let keshi_pay_total = 0;
        let keshi_gift_total = 0;
        student_info.keshis.forEach(item => {
            if (item.is_gift === 0) {
                keshi_pay += item.keshi;
                if (item.keshi > 0) {
                    keshi_pay_total += item.keshi;
                }
            }
            if (item.is_gift === 1) {
                keshi_gift += item.keshi;
                if (item.keshi > 0) {
                    keshi_gift_total += item.keshi;
                }
            }
        });

        const tagcolors = ["", "#87d068", ""];
        // 计算总的支付课时
        return (
            <PageContent title={'学生详情'} routes={[
                {
                    path: '/',
                    breadcrumbName: "首页",
                },
                {
                    path: '/students',
                    breadcrumbName: '学生管理',
                },
                {
                    path: '/123',
                    breadcrumbName: student_info.nick_name,
                },
            ]}>
                <PageHeader
                    ghost={false}
                    style={{marginBottom: 12}}
                    // onBack={() => window.history.back()}
                    title={student_info.nick_name/**/}
                    extra={[
                        <Button key="3"
                                type="primary"
                                onClick={() => this.setState({xu_fei_modal: true})}>
                            报课
                        </Button>,
                        <Button key="2"
                                onClick={() => this.setState({editItem: student_info})}>
                            重置密码
                        </Button>,
                        <Button key="1"
                                onClick={() => this.setState({editStudentInfo: {...student_info}})}>
                            修改
                        </Button>,
                    ]}

                >
                    <Descriptions bordered size={"small"}>
                        <Descriptions.Item label="学生姓名">{student_info.nick_name}</Descriptions.Item>
                        <Descriptions.Item label="性别">
                            <DictName type_value="sex" value={student_info.sex}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="出生年月">{student_info.birthday}</Descriptions.Item>
                        <Descriptions.Item label="年级">
                            <DictName type_value="grade" value={student_info.grade}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="学校" span={2}>
                            <DictName type_value="schools" value={student_info.school}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="家长姓名">{student_info.parent_name}</Descriptions.Item>
                        <Descriptions.Item label="联系电话" span={2}>{student_info.parent_phone}</Descriptions.Item>
                        <Descriptions.Item label="课程名称" span={3}>{student_info.course_name}</Descriptions.Item>
                        <Descriptions.Item label="报名时间">{student_info.sign_up_time}</Descriptions.Item>
                        <Descriptions.Item label="支付课时">{keshi_pay_total}</Descriptions.Item>
                        <Descriptions.Item label="赠送课时">{keshi_gift_total}</Descriptions.Item>
                        <Descriptions.Item label="备注" span={3}>{student_info.remark}</Descriptions.Item>
                        {/*<Descriptions.Item label="剩余课时" span={3}>付费：{keshi_pay}，赠送：{keshi_gift}*/}
                        {/*<a onClick={() => this.setState({xu_fei_modal: true})}>续课</a>*/}
                        {/*{student_info.cards && student_info.cards.map(card=><div>*/}
                        {/*{card.name}：{card.keshi_remaining}/{card.keshi_original}课时，{card.payments_remaining}/{card.payments}元*/}
                        {/*</div>)}*/}

                        {/*</Descriptions.Item>*/}


                    </Descriptions>
                </PageHeader>
                <Row gutter={12} style={{marginBottom: 12}}>
                    <Col span={12}>
                        <CardCard user_id={this.props.id}/>
                    </Col>
                    <Col span={12}>
                        <Card title={"跟进记录"} size={"small"}>
                            <FollowUpList user_id={this.props.id}/>
                        </Card>
                    </Col>
                </Row>
                <Card bordered={false}>
                    <div>
                        <LessonProgress progress={student_info.progress}/>

                        {/*{student_info.progress && student_info.progress.map(progres =>*/}
                        {/*    <div style={{marginBottom: 5}}>*/}
                        {/*        <span style={{marginRight: 8, fontWeight: "bold"}}>*/}
                        {/*        {progres.name}：*/}
                        {/*        </span>*/}
                        {/*        {progres.lessons.map(lesson =>*/}
                        {/*            <Tooltip title={lesson.name}>*/}
                        {/*                <Tag*/}
                        {/*                    color={lesson.status === 1 ? "#87d068" : (lesson.status > 0 ? "#d0110d" : "")}>*/}
                        {/*                    {lesson.tag_index}*/}
                        {/*                </Tag>*/}
                        {/*            </Tooltip>)}*/}
                        {/*    </div>)}*/}
                    </div>

                    {student_info.id &&
                    <Tabs defaultActiveKey="3">
                        <Tabs.TabPane tab={`所在班级(${student_info.teams.length})`} key="1">
                            <ClassesList list={student_info.teams}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`上课记录(${student_info.courses.length})`} key="2">
                            <Table dataSource={student_info.courses}
                                   rowKey={"lessons_id"}
                                   pagination={false}
                                   defaultExpandAllRows
                                   expandIconAsCell={false}
                                   expandIconColumnIndex={-1}
                                   expandedRowKeys={student_info.courses.filter(item => !!item.dianping_content).map(item => item.lessons_id)}
                                   expandedRowRender={record => (
                                       <>
                                       {record.dianping_content && <div>
                                           <div>
                                               {record.dianping_content}
                                           </div>
                                           {record.dianping_photos &&
                                           <div>
                                               {record.dianping_photos.split(',').map(item => (
                                                   <div className={styles.photo}
                                                        style={{backgroundImage: `url(${item}?x-oss-process=style/suofang60)`}}
                                                        onClick={() => this.setState({imageprew: item})}
                                                   />))}
                                           </div>
                                           }
                                           {record.dianping_videos && (() => {
                                               let config = JSON.parse(record.dianping_videos);
                                               if (!config) return null;

                                               return (
                                                   <div>
                                                       <video src={config.url} preload="none" controls="controls">
                                                           您的浏览器不支持 video 标签。
                                                       </video>
                                                   </div>
                                               )
                                           })()

                                           }

                                       </div>}
                                       </>)}
                            >
                                <Column
                                    title="上课时间"
                                    dataIndex="create_on"
                                    key="create_on"
                                    ellipsis={true}
                                />
                                <Column
                                    title="课程名称"
                                    dataIndex="name"
                                    key="name"
                                    ellipsis={true}
                                    width={150}
                                />
                                <Column
                                    title="班级"
                                    dataIndex="team_name"
                                    key="team_name"
                                    ellipsis={true}
                                />
                                <Column
                                    title="讲师"
                                    dataIndex="teacher_name"
                                    key="teacher_name"
                                    width={80}
                                />
                                <Column
                                    title="课时"
                                    dataIndex="keshi"
                                    key="keshi"
                                    width={60}
                                />
                                <Column
                                    title="备注"
                                    dataIndex="remark"
                                    key="remark"
                                    ellipsis={true}
                                />

                                <Column
                                    title="操作"
                                    dataIndex="id"
                                    key="id"
                                    width={80}
                                    render={(id, record) => (
                                        <a onClick={() => this.setState({courses_users_edit: {...record}})}>修改</a>
                                    )}
                                />
                            </Table>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`课时记录(${student_info.keshis.length})`} key="3">
                            <KeShiList user_id={student_info.id}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`解锁Scratch课程`} key="4">
                            <CoursesList user_id={this.props.id}/>
                        </Tabs.TabPane>
                    </Tabs>
                    }

                    {this.state.editStudentInfo &&
                        <StudentEditModal
                            onOk={() => {
                                this.loadStudentInfo();
                                this.setState({editStudentInfo: null})
                            }}
                            onCancel={() => {
                                this.setState({editStudentInfo: null})
                            }}
                            visible={!!this.state.editStudentInfo}
                            user_info={this.state.editStudentInfo}
                            id={this.state.editStudentInfo.id}
                        />
                    }
                    {this.state.courses_users_edit  &&
                        <CoursesUsersEditModal
                            data={this.state.courses_users_edit}
                            onOk={() => {
                                this.loadStudentInfo();
                                this.setState({courses_users_edit: null})
                            }}
                            onCancel={() => {
                                this.setState({courses_users_edit: null})
                            }}
                        />
                    }



                    {this.state.xu_fei_modal &&
                    <XuKeModal
                        visible={this.state.xu_fei_modal}
                        onOk={() => {
                            this.loadStudentInfo();
                            this.setState({xu_fei_modal: false})
                        }}
                        onCancel={() => {
                            this.setState({xu_fei_modal: false})
                        }}
                        user_id={this.state.student_info.id}
                    />
                    }

                </Card>
                <Modal
                    title={false}
                    visible={!!this.state.imageprew}
                    footer={null}
                    // width={720}
                    bodyStyle={{padding: 0}}
                    onCancel={() => this.setState({imageprew: null})}
                >
                    <img src={this.state.imageprew} style={{maxWidth: '100%'}}/>
                </Modal>
                <Modal
                    title="重置密码"
                    visible={!!this.state.editItem}
                    onOk={this.handleEditModal}
                    onCancel={this.hideEditModal}
                    confirmLoading={this.state.editModalConfirmLoading}
                    okText="确认"
                    cancelText="取消"
                >
                    <div>
                        确定重置密码吗？重置密码：111000
                    </div>
                    <div>
                        学生帐号：{this.state.editItem && this.state.editItem.login_name}<br/>
                        学生姓名：{this.state.editItem && this.state.editItem.nick_name}
                    </div>
                </Modal>
            </PageContent>
        )
    }
}


StudentDetail.propTypes = {
    id: PropTypes.number.isRequired,
};

export default StudentDetail;
