import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'antd';
import Form, {connectForm} from 'form-render';
import {postData} from "../../../lib/api_data";
import AliyunOSSUpload from '../../../components/aliyun-oss-upload';

const schema = {
    "type": "object",
    "properties": {
        "content": {
            "title": "跟进内容",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "props": {
                "allowClear": false
            },
            "type": "string",
            "format": "textarea"
        },
        "files_config": {
            "title": "附件",
            "required": false,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "props": {
                "allowClear": false
            },
            "type": "string",
            "widget": "AliyunOSSUpload"

        },
    },
    "column": 1,
    "labelWidth": 150,
    "displayType": "row",
    "showDescIcon": true
};


class SysDictTypeAddModal extends Component {
    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
    };

    onFinish = (formData, errors) => {
        if (errors.length > 0) {
        } else {
            console.log(formData);
            formData.user_id = this.props.user_id;
            formData.teacher_id = this.props.account.id;
            postData("followup", formData)
                .then(res => {
                    if (res.code === 0) {
                        this.props.onOk && this.props.onOk(res.data);
                    }
                })
        }
    };

    render() {
        const {form, visible, user_id} = this.props;
        return (
            <Modal
                title="添加跟进记录"
                visible={visible}
                onOk={form.submit}
                onCancel={this.handleCancel}
                width={800}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    schema={schema}
                    widgets={{
                        AliyunOSSUpload: ({value, onChange}) => {
                            return <AliyunOSSUpload
                                value={value}
                                onChange={onChange}
                                prefix={`followup/${user_id}/`}
                                multiple={true}
                            />
                        }
                    }}
                    onFinish={this.onFinish}/>
            </Modal>
        )
    }
}


SysDictTypeAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    user_id: PropTypes.number
};

const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({});


export default connectForm(SysDictTypeAddModal);
