import React, {useEffect, useState} from 'react';
import {Modal, Table} from 'antd';
import {getQuestions} from "../../../lib/api_questions";

const QuestionsSelectModal = ({onOk, onCancel}) => {
    const [list, setList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const columns = [
        {
            title: '编号',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: '题目类型',
            dataIndex: 'type',
            key: 'type',
            width: 100,
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
    ];

    const fetchData = () => {
        getQuestions()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                }
            })
    }

    useEffect(fetchData, []);

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRows(newSelectedRowKeys);
    };

    const rowSelection = {
        // selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Modal
            title="题目选择"
            open={true}
            width={1000}
            onCancel={onCancel}
            onOk={() => {
                onOk && onOk(selectedRows)
            }}
        >
            <Table
                columns={columns}
                dataSource={list}
                rowKey={"id"}
                size={"small"}
                pagination={{
                    defaultPageSize: 20
                }}
                rowSelection={rowSelection}
            />
        </Modal>
    )
};

export default QuestionsSelectModal;