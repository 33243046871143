import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input, Form} from 'antd';
import FormOptions from './form_options';
import MdEditor from "../../../components/md-editor/md-editor";


const layout = {
    labelCol: {flex: "110px"},
    wrapperCol: {flex: "1"},
};


const FormDanXuan = ({value, onChange}) => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState({
        title: "",
        options: [{key: "A", value: ""}, {key: "B", value: ""}, {key: "C", value: ""}, {key: "D", value: ""}]
    });

    useEffect(() => {
        if (value) {
            let dt = JSON.parse(value);
            setConfig(dt);
            form.setFieldsValue(dt)
        } else {
            form.setFieldsValue({
                title: "",
                options: [{key: "A", value: ""}, {key: "B", value: ""}, {key: "C", value: ""}, {key: "D", value: ""}]
            })
        }

    }, [value]);

    const handleChange = (changedValues, allValues) => {
        // console.log(allValues);
        onChange && onChange(JSON.stringify(allValues))
    };

    return (
        <Form
            {...layout}
            form={form}
            onValuesChange={handleChange}
        >
            <Form.Item
                label="标题"
                name="title"
                rules={[{
                    required: true,
                    message: '请输入标题'
                }]}
            >
                <MdEditor/>
            </Form.Item>
            <Form.Item
                label="选项"
                name="options"
                rules={[{
                    required: true,
                    message: '请输入题目配置'
                }]}
            >
                <FormOptions/>
            </Form.Item>
        </Form>
    )
};


FormDanXuan.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default FormDanXuan;