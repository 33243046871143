import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input, Form, Button} from 'antd';
import FormOptions from './form_options';
import MdEditor from '../../../components/md-editor/md-editor';
import {PlusOutlined} from "@ant-design/icons";
import {postClone} from "../../../lib/api_scratchs";
import moment from "moment/moment";


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const FormScratchAnswer = ({value, onChange}) => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState({
        scratch_id: 0,
    });

    useEffect(() => {
        if (value) {
            let dt = JSON.parse(value);
            setConfig(dt);
            form.setFieldsValue(dt)
        } else {
            form.setFieldsValue({
                scratch_id: 0,
            })
        }

    }, [value]);

    const handleChange = (allValues) => {
        // console.log(allValues);
        onChange && onChange(JSON.stringify(allValues))
    };

    const handleCreateScratch = () => {
        postClone(1, -1)
            .then(result => {
                if (result.code === 0) {
                    let item = result.data;
                    setConfig({
                        scratch_id: item.id,
                    })
                    handleChange({
                        scratch_id: item.id,
                    })
                }
            })
    };

    return (
        <Form
            {...layout}
            form={form}
        >
            <Form.Item label="参考答案">
                {config.scratch_id === 0
                    ? <Button onClick={handleCreateScratch} icon={<PlusOutlined/>}>创建作品</Button>
                    : <a href={`https://xmybc.com/scratch/projects/${config.scratch_id}`} target="_blank">去改编</a>
                }
            </Form.Item>
        </Form>
    )
};

export default FormScratchAnswer;