/**
 * @name: python作业
 * @description:
 * @author: Wood
 * @date: 2019/4/21
 * @update:
 */
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {message, Card, Form} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getChallenge, putChallenge} from "../../../lib/api_challenges";
import ChallengesForm from "./challenges_form";

const ChallengesEdit = ({id, history}) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);

    const fetchData = () => {
        getChallenge(id)
            .then(result => {
                if (result.code === 0) {
                    let testcases = [];
                    if (result.data.config) {
                        let config = JSON.parse(result.data.config);
                        testcases = config.testcases;
                    }
                    console.log(testcases);

                    setData({
                        pkey: result.data.pkey,
                        name: result.data.name,
                        description: result.data.description,
                        statement: result.data.statement,
                        input_format: result.data.input_format,
                        constraints: result.data.constraints,
                        output_format: result.data.output_format,
                        tags: result.data.tags ? JSON.parse(result.data.tags) : [],
                        difficulty: result.data.difficulty,
                        is_public: result.data.is_public,
                        // config: result.data.config,
                        testcases: JSON.stringify(testcases),
                        tutorial_content: result.data.tutorial_content,
                    })
                } else {
                    message.error(result.message)
                }
            })
    }
    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                // 数据处理以及保存
                let config = {
                    name: values.name,
                    description: values.description || "",
                    statement: values.statement || "",
                    input_format: values.input_format || "",
                    constraints: values.constraints || "",
                    output_format: values.output_format || "",
                    testcases: JSON.parse(values.testcases || "[]")
                };
                console.log(config)

                putChallenge(id, {
                    pkey: values.pkey,
                    name: values.name,
                    description: values.description,
                    statement: values.statement,
                    input_format: values.input_format,
                    constraints: values.constraints,
                    output_format: values.output_format,
                    tags: JSON.stringify(values.tags),
                    difficulty: values.difficulty,
                    is_public: values.is_public ? 1 : 0,
                    config: JSON.stringify(config),
                    tutorial_content: values.tutorial_content,
                }).then(result => {
                    if (result.code === 0) {
                        message.success("修改成功！");
                        history.go(-1);
                    } else {
                        message.error(result.message);
                    }
                })
            })
    };

    return (
        <PageContent title={"修改题目"}>
            <Card bordered={false}>
                {data &&
                    <ChallengesForm form={form} onFinish={handleSubmit} initialValues={data}/>
                }
            </Card>
        </PageContent>
    )
}

export default withRouter(ChallengesEdit);