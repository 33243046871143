import PropTypes from 'prop-types';
import {useRoomsStore} from "../../stores/useRoomsStore";

const RoomName = ({id}) => {
    const {rooms} = useRoomsStore();
    let room = rooms.find(_ => _.id.toString() === id.toString());
    return room ? room.name : "";
};

RoomName.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default RoomName;