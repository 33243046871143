import React, {useEffect} from 'react'
import {Select} from "antd";

const options = [
    {
        value: 'scratch',
        label: 'scratch',
    },
    {
        value: 'python',
        label: 'python',
    },
    {
        value: 'c++',
        label: 'c++',
    },
    {
        value: '1级',
        label: '1级',
    },
    {
        value: '2级',
        label: '2级',
    },
    {
        value: '3级',
        label: '3级',
    },
    {
        value: '4级',
        label: '4级',
    },
]

const TagsSelect = ({value, onChange}) => {
    const [realvalue, setRealValue] = React.useState([]);

    useEffect(() => {
        if (value) {
            setRealValue(JSON.parse(value) || []);
        } else {
            setRealValue([]);
        }
    }, [value]);

    const handleChange = (value) => {
        onChange && onChange(JSON.stringify(value));
    };

    return (
        <Select
            mode="tags"
            style={{
                width: '100%',
            }}
            value={realvalue}
            options={options}
            onChange={handleChange}
        />
    )
}

export default TagsSelect;