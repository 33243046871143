/**
 * @name: 班级列表
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import React, {Component, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {getClasses, postClasses} from "../../../lib/api_classes.js";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Input, Alert, Tabs, Tag, Card} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import ClassesEdit from './classes_edit_modal';
import ClassesList from './classes_list';
import tags from './tags_config';
import useUrlState from "@ahooksjs/use-url-state";
import ClassesAddModal from "./classes_add_modal";


const Classes = () => {

    const [list, setList] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [curlist, setCurlist] = useState([]);
    const [endlist, setEndlist] = useState([]);
    const [editId, setEditId] = useState(null);
    const [state, setState] = useUrlState({
        teacher_id: 0, tag: "",
    });

    const loadClasses = () => {
        getClasses()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                    // reset();
                }
            })
    };
    useEffect(loadClasses, []);

    const reset = () => {
        console.log(list, state);
        let curlist = list.filter(item => item.status === 0
            && (parseInt(state.teacher_id) === 0 || item.creater === parseInt(state.teacher_id))
            && (state.tag === "" || (item.tags && item.tags.includes(state.tag)))
        );
        let endlist = list.filter(item => item.status === 1
            && (parseInt(state.teacher_id) === 0 || item.creater === parseInt(state.teacher_id))
            && (state.tag === "" || (item.tags && item.tags.includes(state.tag)))
        );
        console.log(curlist, endlist);

        // setTimeout(()=>{
        setCurlist(curlist);
        setEndlist(endlist);
        // },0)
    }
    useEffect(reset, [list, state])
    // useEffect(reset,[])


    // 获得教师列表
    let teacherids = Array.from(new Set(list.map(item => item.creater)));
    let teachers = [];
    teacherids.forEach(id => {
        let record = list.find(item => item.creater === id);
        if (record) {
            teachers.push({
                id: id,
                nick_name: record.teacher_name
            })
        }
    })

    return (
        <PageContent title={'班级管理'}>
            <Card bordered={false}>
                <div>
                    <Tag.CheckableTag
                        checked={parseInt(state.teacher_id) === 0}
                        onChange={() => setState({...state, teacher_id: 0})}
                    >
                        全部({list.length})
                    </Tag.CheckableTag>
                    {teachers.map((teacher, index) => (
                        <Tag.CheckableTag
                            checked={parseInt(state.teacher_id) === teacher.id}
                            key={index}
                            onChange={() => setState({...state, teacher_id: teacher.id})}
                        >
                            {teacher.nick_name}({list.filter(item => item.creater === teacher.id).length})
                        </Tag.CheckableTag>
                    ))}
                </div>
                <div>
                    <Tag.CheckableTag
                        checked={state.tag === ""}
                        onChange={() => setState({...state, tag: ""})}
                    >
                        全部({list.length})
                    </Tag.CheckableTag>
                    {tags.map((tag, index) => (
                        <Tag.CheckableTag
                            checked={state.tag === tag}
                            key={index}
                            onChange={() => setState({...state, tag: tag})}
                        >
                            {tag}
                        </Tag.CheckableTag>
                    ))}
                </div>
                <Tabs defaultActiveKey="1" tabBarExtraContent={
                    <Button type="primary" icon={<PlusOutlined/>}
                            onClick={() => setShowCreateModal(true)}>
                        创建班级
                    </Button>
                }>
                    <Tabs.TabPane tab={`进行中(${curlist.length})`} key="1">
                        <ClassesList list={curlist} onEdit={(record) => {
                            setEditId(record);
                            // setEditId(record.id);
                            // this.setState({editRecord: record})
                        }}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`已结课(${endlist.length})`} key="2">
                        <ClassesList list={endlist} onEdit={(record) => {
                            setEditId(record);
                            // setEditId(record.id);
                            // this.setState({editRecord: record})
                        }}/>
                    </Tabs.TabPane>
                </Tabs>

                {/*<Modal*/}
                {/*    title="创建班级"*/}
                {/*    visible={showCreateModal}*/}
                {/*    onOk={this.handleCreateModal}*/}
                {/*    onCancel={this.hideCreateModal}*/}
                {/*    confirmLoading={this.state.createModalConfirmLoading}*/}
                {/*    okText="确认"*/}
                {/*    cancelText="取消"*/}
                {/*>*/}
                {/*    <Input size="large" placeholder="请输入班级名称" value={this.state.createTitle}*/}
                {/*           onChange={this.handleCreateModalChange}/>*/}
                {/*    {this.state.createTitleError && <Alert message={this.state.createTitleError} type="error"/>}*/}
                {/*</Modal>*/}
                {showCreateModal&&
                    <ClassesAddModal
                        onOk={() => {
                            loadClasses();
                            setShowCreateModal(false);
                        }}
                        onCancel={() => {
                           setShowCreateModal(false);
                        }}
                    />
                }


                {!!editId &&
                    <ClassesEdit
                        onOk={() => {
                            loadClasses();
                            setEditId(null);
                        }}
                        onCancel={() => {
                            setEditId(null);
                        }}
                        data={editId}
                    />
                }

            </Card>
        </PageContent>
    );
}

// class Classes extends Component {
//     state = {
//         list: [],
//         visibleCreateModal: false,
//         createTitle: '',
//         createTitleError: '',
//         createModalConfirmLoading: false,
//
//         editRecord: null,
//         filter: {
//             teacher_id: 0,
//             tag: ""
//         },
//     };
//
//     componentDidMount() {
//         // console.log(this.props.location);
//         let {search} = this.props.location;
//         if (search) {
//             if (search.startsWith("?")) {
//                 search = search.slice(1);
//                 console.log(search);
//                 let obj = querystring.parse(search);
//                 console.log(obj);
//                 if (obj) {
//                     if (obj.teacher_id) {
//                         obj.teacher_id = parseInt(obj.teacher_id);
//                     } else {
//                         obj.teacher_id = 0;
//                     }
//                     this.setState({filter: obj})
//                 }
//             }
//         }
//         this.loadClasses();
//     }
//
//     componentWillUnmount() {
//         this.setState = (state, callback) => {
//             return;
//         };
//     }
//
//     loadClasses = () => {
//         getClasses()
//             .then((result) => {
//                 if (result.code === 0) {
//                     this.setState({
//                         list: result.data
//                     })
//                 } else {
//
//                 }
//             })
//     };
//
//     // 显示创建班级对话框
//     showCreateModal = () => {
//         this.setState({
//             visibleCreateModal: true,
//         });
//     };
//     hideCreateModal = () => {
//         this.setState({
//             visibleCreateModal: false,
//         });
//     };
//     handleCreateModalChange = (e) => {
//         this.setState({createTitle: e.target.value});
//     };
//     handleCreateModal = () => {
//         // 去除收尾空格
//         let title = this.state.createTitle;
//         title = title.replace(/(^\s*)|(\s*$)/g, "");
//         if (title.length === 0) {
//             // 提示错误
//             this.setState({
//                 createTitleError: '请输入班级名称',
//                 createTitle: title
//             })
//         } else {
//             // 新建班级操作
//             this.setState({
//                 createModalConfirmLoading: true
//             });
//             postClasses(title)
//                 .then(result => {
//                     if (result.code === 0) {
//                         this.setState({
//                             createModalConfirmLoading: false,
//                             visibleCreateModal: false
//                         });
//                         this.loadClasses()
//                     } else {
//                         this.setState({
//                             createModalConfirmLoading: false,
//                             createTitleError: result.message
//                         });
//                     }
//                 })
//                 .catch(err => {
//                     this.setState({
//                         createModalConfirmLoading: false,
//                         createTitleError: JSON.stringify(err)
//                     });
//                 });
//
//         }
//     };
//
//     handleTeacherChange = (teacher_id) => {
//         let {filter} = this.state;
//         filter.teacher_id = teacher_id;
//         this.setState({filter});
//         this.props.history.replace(`?teacher_id=${filter.teacher_id}&tag=${filter.tag}`)
//     };
//
//     handleTagChange = (tag) => {
//         let {filter} = this.state;
//         filter.tag = tag;
//         this.setState({filter});
//         this.props.history.replace(`?teacher_id=${filter.teacher_id}&tag=${filter.tag}`)
//     };
//
//     render() {
//         let {list, filter} = this.state;
//
//         let curlist = list.filter(item => item.status === 0
//             && (filter.teacher_id === 0 || item.creater === filter.teacher_id)
//             && (filter.tag === "" || (item.tags && item.tags.includes(filter.tag)))
//         );
//         let endlist = list.filter(item => item.status === 1
//             && (filter.teacher_id === 0 || item.creater === filter.teacher_id)
//             && (filter.tag === "" || (item.tags && item.tags.includes(filter.tag)))
//         );
//
//         // 获得教师列表
//         let teacherids = Array.from(new Set(list.map(item => item.creater)));
//         let teachers = [];
//         teacherids.forEach(id => {
//             let record = list.find(item => item.creater === id);
//             if (record) {
//                 teachers.push({
//                     id: id,
//                     nick_name: record.teacher_name
//                 })
//             }
//         })
//
//
//         return (
//             <PageContent title={'班级管理'}>
//                 <Card bordered={false}>
//                     <div>
//                         <Tag.CheckableTag checked={filter.teacher_id === 0}
//                                           onChange={() => this.handleTeacherChange(0)}>全部({list.length})</Tag.CheckableTag>
//                         {teachers.map((teacher, index) => (
//                             <Tag.CheckableTag checked={filter.teacher_id === teacher.id}
//                                               key={index}
//                                               onChange={() => this.handleTeacherChange(teacher.id)}>{teacher.nick_name}({list.filter(item => item.creater === teacher.id).length})</Tag.CheckableTag>
//                         ))}
//                     </div>
//                     <div>
//                         <Tag.CheckableTag checked={filter.tag === ""}
//                                           onChange={() => this.handleTagChange("")}>全部({list.length})</Tag.CheckableTag>
//                         {tags.map((tag, index) => (
//                             <Tag.CheckableTag checked={filter.tag === tag}
//                                               key={index}
//                                               onChange={() => this.handleTagChange(tag)}>{tag}</Tag.CheckableTag>
//                         ))}
//                     </div>
//                     <Tabs defaultActiveKey="1" tabBarExtraContent={<Button type="primary" icon={<PlusOutlined/>}
//                                                                            onClick={this.showCreateModal}>创建班级</Button>}>
//                         <Tabs.TabPane tab={`进行中(${curlist.length})`} key="1">
//                             <Classes_list list={curlist} onEdit={(record) => {
//                                 this.setState({editRecord: record})
//                             }}/>
//                         </Tabs.TabPane>
//                         <Tabs.TabPane tab={`已结课(${endlist.length})`} key="2">
//                             <Classes_list list={endlist} onEdit={(record) => {
//                                 this.setState({editRecord: record})
//                             }}/>
//                         </Tabs.TabPane>
//                     </Tabs>
//
//                     <Modal
//                         title="创建班级"
//                         visible={this.state.visibleCreateModal}
//                         onOk={this.handleCreateModal}
//                         onCancel={this.hideCreateModal}
//                         confirmLoading={this.state.createModalConfirmLoading}
//                         okText="确认"
//                         cancelText="取消"
//                     >
//                         <Input size="large" placeholder="请输入班级名称" value={this.state.createTitle}
//                                onChange={this.handleCreateModalChange}/>
//                         {this.state.createTitleError && <Alert message={this.state.createTitleError} type="error"/>}
//                     </Modal>
//
//                     <ClassesEdit
//                         onOK={() => {
//                             this.loadClasses();
//                             this.setState({editRecord: null})
//                         }}
//                         onClose={() => {
//                             this.setState({editRecord: null})
//                         }}
//                         data={this.state.editRecord}
//                     />
//                 </Card>
//             </PageContent>
//         );
//     }
// }
export default withRouter(Classes);
