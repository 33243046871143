import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Checkbox, Modal, Tag, Form, Radio, Row, Col, Button, InputNumber, Space, message, Input} from 'antd';
import {postQuestion} from "../../../lib/api_questions";
import MD from '../questions/md';
import styles from '../questions/examination_modal.module.css';

import {getExamUser, postExamUserPigai} from "../../../lib/api_exams";

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const ExaminationModal = ({exam_id, user_id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [config, setconfig] = useState({
        title: "试卷标题",
        desc: "试卷说明",
        score: 0,
        groups: []
    });

    // 获取试卷详情
    const fetchData = () => {
        getExamUser(exam_id, user_id).then(res => {
            if (res.code === 0) {
                if (res.data.examuser.result) {
                    statScore(JSON.parse(res.data.examuser.result))
                } else {
                    // 试卷内容
                    let content = JSON.parse(res.data.exam.content);
                    // 学生答案
                    let useranswer = JSON.parse(res.data.examuser.answer);
                    for (let i in content.groups) {
                        for (let j in content.groups[i].questions) {
                            let question = content.groups[i].questions[j];
                            let realanswer = null;
                            if (question.answer) {
                                realanswer = JSON.parse(question.answer).answer;
                            }
                            question.useranswer = null;
                            question.userscore = 0;
                            let uanswer = useranswer.find(m => m.id === question.id);
                            if (uanswer) {
                                question.useranswer = uanswer.answer;
                                if (question.useranswer === realanswer) {
                                    question.userscore = question.score;
                                }
                            }
                        }
                    }
                    statScore(content)
                }
                // setconfig(content)
            }
        })
    };

    useEffect(() => {
        fetchData();
        // setconfig(JSON.parse(data.content))
    }, []);

    const statScore = (content) => {
        console.log(content);
        let userscore = 0;
        for (let i in content.groups) {
            for (let j in content.groups[i].questions) {
                let question = content.groups[i].questions[j];
                if (question.userscore) {
                    userscore += question.userscore;
                }
            }
        }
        content.userscore = userscore;
        setconfig({...content})
    };

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.type = "单选题";
                postQuestion(values)
                    // values.password = md5(values.password);
                    // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };


    const renderItem = (item) => {
        switch (item.type) {
            case "单选题": {
                let itemconfig = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={itemconfig.title}/>
                    </div>
                    <div>
                        <Radio.Group value={item.useranswer}>
                            <Row>
                                {itemconfig.options.map(option => {
                                    let color = "#000000";
                                    if (option.key === answer.answer) {
                                        color = "green";
                                    } else if (option.key === item.useranswer) {
                                        color = "red"
                                    }
                                    return (
                                        <Col span={item.col ? 24 / item.col : 24}>
                                            <Radio value={option.key} checked style={{color: color}}>
                                                <div className={styles.options}>
                                                    <span>{option.key}.</span>
                                                    <MD value={option.value}/>
                                                </div>
                                            </Radio>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Radio.Group>
                    </div>
                    <div style={{color: item.userscore > 0 ? "green" : "red"}}>
                        正确答案：{answer.answer}<br/>
                        题目分数：{item.score}分<br/>
                        学生答案：{item.useranswer}<br/>
                        学生得分：<InputNumber value={item.userscore} onChange={(value) => {
                        item.userscore = value;
                        statScore(config)
                    }}/><br/>
                    </div>
                </div>)
            }
            case "多选题": {
                let itemconfig = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={itemconfig.title}/>
                    </div>
                    <div>
                        <Checkbox.Group value={item.useranswer ? item.useranswer.split('') : []}>
                            <Row>
                                {itemconfig.options.map(option => {
                                    let color = "#000000";
                                    if (option.key === answer.answer) {
                                        color = "green";
                                    } else if (option.key === item.useranswer) {
                                        color = "red"
                                    }
                                    return (
                                        <Col span={item.col ? 24 / item.col : 24}>
                                            <Checkbox value={option.key} checked style={{color: color}}>
                                            <div className={styles.options}>
                                                <span>{option.key}.</span>
                                                <MD value={option.value}/>
                                            </div>
                                        </Checkbox>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Checkbox.Group>
                    </div>
                    <div style={{color: item.userscore > 0 ? "green" : "red"}}>
                        正确答案：{answer.answer}<br/>
                        题目分数：{item.score}分<br/>
                        学生答案：{item.useranswer}<br/>
                        学生得分：<InputNumber value={item.userscore} onChange={(value) => {
                        item.userscore = value;
                        statScore(config)
                    }}/><br/>
                    </div>
                </div>)
            }
            case "判断题": {
                let itemconfig = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                        <div className={styles.title}>
                            <span>{item.id}.</span>
                            <MD value={itemconfig.title}></MD>
                        </div>
                        <div>

                            <Radio.Group value={answer.answer}>
                                <Space direction="vertical">
                                    <Radio value={"正确"}>
                                        <div className={styles.options}>
                                            正确
                                        </div>
                                    </Radio>
                                    <Radio value={"错误"}>
                                        <div className={styles.options}>
                                            错误
                                        </div>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                            {/*{config.options.map(option=>(*/}
                            {/*<div className={styles.options}>*/}
                            {/*<span>{option.key}.</span>*/}
                            {/*<MD value={option.value} />*/}
                            {/*</div>*/}
                            {/*))}*/}
                        </div>
                        <div style={{color: item.userscore > 0 ? "green" : "red"}}>
                            正确答案：{answer.answer}<br/>
                            题目分数：{item.score}分<br/>
                            学生答案：{item.useranswer}<br/>
                            学生得分：<InputNumber value={item.userscore} onChange={(value) => {
                            item.userscore = value;
                            statScore(config)
                        }}/><br/>
                        </div>
                    </div>
                )
            }
            case "python": {
                let itemconfig = JSON.parse(item.content);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={itemconfig.content}></MD>
                    </div>
                    <div>
                        <div>评分标准：</div>
                        <MD value={itemconfig.pfbz}></MD>

                    </div>
                    <div style={{color: item.userscore > 0 ? "green" : "red"}}>
                        {/*正确答案：{answer.answer}<br/>*/}
                        题目分数：{item.score}分<br/>
                        学生答案：
                        <Input.TextArea value={item.useranswer}
                                        autoSize={{minRows: 2, maxRows: 20}}
                        />

                        <br/>
                        学生得分：<InputNumber value={item.userscore} onChange={(value) => {
                        item.userscore = value;
                        statScore(config)
                    }}/><br/>
                    </div>

                </div>)
            }
            case "scratch": {
                let itemconfig = JSON.parse(item.content);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={itemconfig.content}></MD>
                    </div>
                    <div style={{color: item.userscore > 0 ? "green" : "red"}}>
                        {/*正确答案：{answer.answer}<br/>*/}
                        题目分数：{item.score}分<br/>
                        学生答案：
                        <a href={`https://xmybc.com/scratch/projects/${item.useranswer}`} target="_blank">
                            <Button type={"primary"} size={"large"}>查看程序</Button>
                        </a>
                        <br/>
                        学生得分：<InputNumber value={item.userscore} onChange={(value) => {
                        item.userscore = value;
                        statScore(config)
                    }}/><br/>
                    </div>

                </div>)
            }
            default:
                return (
                    <div>
                        {item.content}
                    </div>
                )
        }

    };

    const renderGroup = (group) => {
        return (<div className={styles.group}>
            <div className={styles.groupTitle}>
                <div>{group.title}</div>
            </div>
            <div>
                {group.questions.map(renderItem)}
            </div>
        </div>)
    };


    const handleSave = () => {
        console.log(config);
        postExamUserPigai(exam_id, user_id, JSON.stringify(config), config.userscore)
            .then(res => {
                if (res.code === 0) {
                    message.success("已保存");
                    onOk && onOk();
                }
            })
        // putPaper({
        //     id: data.id,
        //     title: config.title,
        //     content: JSON.stringify(config),
        //     score: config.score,
        // }).then(result=>{
        //     if (result.code === 0) {
        //         onOk && onOk();
        //         message.success("生成成功！")
        //     }
        //
        // })
    };
    const style = {
        // transform: CSS.Transform.toString(transform),
        // transition,
        border: "solid 1px #1890ff",
        borderRadius: 5,
        padding: "3px 3px",
        textAlign: "center",
        margin: 3,
        backgroundColor: "#ffffff",
        cursor: "pointer",
        display: "inline-block",
        width: 30,
        color: "#ffffff"
    };
    return (
        <Modal
            title="试卷批改"
            visible={true}
            onCancel={onCancel}
            onOk={handleOk}
            width={"90%"}
            style={{top: 20}}
            footer={false}
        >
            <Row>
                <Col flex={"250px"}>
                    <div style={{position: "fixed", top: 100, width: 250}}>
                        {config.groups.map(group => (
                            <div>
                                <div>{group.type}</div>
                                {group.questions.map(item =>
                                    <div style={{
                                        ...style,
                                        backgroundColor: item.userscore > 0 ? "green" : "red",
                                    }}>
                                        {item.id}
                                    </div>)}
                            </div>
                        ))}
                        <div>
                            试卷总分：{config.score}<br/>
                            学生得分：{config.userscore}
                        </div>
                        <div>
                            <Button onClick={handleSave} type={"primary"}>提交结果</Button>
                        </div>
                    </div>
                </Col>
                <Col flex={"1"}>
                    <div>

                        <div>{config.title}</div>
                        <div>
                            <MD value={config.desc}/>
                        </div>
                    </div>
                    {config.groups.map(renderGroup)}
                </Col>
            </Row>
        </Modal>
    )
};


ExaminationModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default ExaminationModal;