/**
 * @name: 头像菜单
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Menu} from 'antd';
import {useAccountStore} from "../../stores/useAccountStore";
import {usePasswordModalStore} from "../../stores/usePasswordModalStore";

const AccountMenu = () => {
    const {signOut} = useAccountStore();
    const {showPasswordModal} = usePasswordModalStore();
    return (
        <Menu>
            <Menu.Item>
                <a onClick={showPasswordModal}>修改密码</a>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item>
                <a onClick={signOut}>退出登录</a>
            </Menu.Item>

        </Menu>
    )
};

export default AccountMenu;