import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'antd';
import Form, {connectForm} from 'form-render';
import {putData} from "../../../lib/api_data";
import FormDataSelect from '../../../components/data/form-data-select';

const schema = {
    "type": "object",
    "properties": {
        "create_on": {
            "title": "时间",
            "type": "string",
            "format": "dateTime",
            "required": true,
            "props": {
                format: "YYYY-MM-DD HH:mm",
                showTime: {format: 'HH:mm'}
            }
        },
        "keshi": {
            "title": "课时",
            "type": "number",
            "required": true
        },
        "remark": {
            "title": "备注",
            "type": "string",
            "format": "textarea",
            "props": {}
        },
        "is_gift": {
            "title": "赠送课时",
            "type": "number",
            "required": true,
            "enum": [
                1,
                0
            ],
            "enumNames": [
                "是",
                "否"
            ],
            "widget": "radio"
        },
        "amount": {
            "title": "金额",
            "type": "number",
            "required": true
        },
        "card_id": {
            "title": "课程",
            "type": "number",
            "required": true,
            "widget": "FormDataSelect"
        },
    },
    "column": 1,
    "labelWidth": 150,
    "displayType": "row",
    "showDescIcon": true
};


class KeShiEditModal extends Component {
    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
    };

    onFinish = (formData, errors) => {
        if (errors.length > 0) {
        } else {
            console.log(formData);
            // formData.user_id = this.props.user_id;
            // formData.teacher_id = this.props.account.id;
            formData = Object.assign({}, this.props.data, formData);
            putData("keshi", formData)
                .then(res => {
                    if (res.code === 0) {
                        this.props.onOk && this.props.onOk(res.data);
                    }
                })
        }
    };


    componentDidMount() {
        const {form, visible, data} = this.props;
        console.log(data);
        form.setValues(data)
    }


    render() {
        const {form, visible, data} = this.props;
        return (
            <Modal
                title="修改课时记录"
                visible={visible}
                onOk={form.submit}
                onCancel={this.handleCancel}
                // width={800}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    schema={schema}
                    onFinish={this.onFinish}
                    widgets={{
                        FormDataSelect: ({value, onChange}) => {
                            console.log(value);
                            return <FormDataSelect
                                value={value}
                                onChange={onChange}
                                table={"cards"}
                                labelkey={"name"}
                                valuekey={"id"}
                                valueType={"number"}
                                filters={{
                                    user_id: data.user_id
                                }}
                            />
                        }
                    }}
                />
            </Modal>
        )
    }
}


KeShiEditModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default connectForm(KeShiEditModal);
