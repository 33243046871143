import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
    getStudents,
    deleteStudentFromClasses
} from "../../lib/api_students.js";
import {getClasses} from "../../lib/api_classes.js";
import {Button, Menu, Dropdown, Tabs, Card, DatePicker, Row, Col} from 'antd';
import PageContent from '../../components/page-content/page-content';
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util,
    Line,
} from "bizcharts";
import {getStat, postReStat} from "../../lib/api_stat";
import moment from 'moment';

const DataSet = require('@antv/data-set');

const {RangePicker} = DatePicker;

class Stat extends Component {
    state = {
        data: [],
        begin: moment().add(-1, "y").format("YYYY-MM"),
        end: moment().format("YYYY-MM"),
    };

    componentDidMount() {
        this.fetchData(this.state.begin, this.state.end)
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = (begin, end) => {
        getStat(begin, end)
            .then(res => {
                if (res.code === 0) {
                    this.setState({
                        data: res.data
                    })
                }
            })
    };

    // 重新统计
    reStat = () => {
        postReStat()
            .then(res => {
                if (res.code === 0) {
                    this.fetchData(this.state.begin, this.state.end);
                }
            })

    };
    onDateChange = (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            begin: dateString[0],
            end: dateString[1],
        })
        this.fetchData(dateString[0], dateString[1]);
    };

    render() {
        // const data = [
        //     {
        //         month: "2019-02",
        //         value: 6
        //     },
        //     {
        //         month: "2019-03",
        //         value: 42
        //     },
        //     {
        //         month: "2019-04",
        //         value: 63
        //     },
        //     {
        //         month: "2019-05",
        //         value: 87
        //     },
        //     {
        //         month: "2019-06",
        //         value: 134
        //     },
        //     {
        //         month: "2019-07",
        //         value: 394
        //     },
        //     {
        //         month: "2019-08",
        //         value: 315
        //     },
        //     {
        //         month: "2019-09",
        //         value: 302
        //     },
        //     {
        //         month: "2019-10",
        //         value: 361
        //     },
        //     {
        //         month: "2019-11",
        //         value: 422
        //     },
        //     {
        //         month: "2019-12",
        //         value: 380
        //     },
        //     {
        //         month: "2020-01",
        //         value: 261
        //     },
        //     {
        //         month: "2020-02",
        //         value: 259
        //     },
        //     {
        //         month: "2020-03",
        //         value: 208
        //     },
        //     {
        //         month: "2020-04",
        //         value: 133
        //     },
        //     {
        //         month: "2020-05",
        //         value: 350
        //     },
        //     {
        //         month: "2020-06",
        //         value: 1
        //     }
        // ];
        const cols = {
            value: {
                min: 0
            },
            // year: {
            //     range: [0, 1]
            // }
        };
        let {data} = this.state;

        const ds = new DataSet();

        const dv1 = ds.createView()
            .source(data)
            .transform({
                type: 'fold',
                fields: ['keshi_new', "keshi_new_gift", "keshi_use", "keshi_use_gift"], // 展开字段集
                key: 'key',                   // key字段
                value: 'value',               // value字段
                retains: ['month']        // 保留字段集，默认为除 fields 以外的所有字段
            });
        const dv2 = ds.createView()
            .source(data)
            .transform({
                type: 'fold',
                fields: ["keshi_total", "keshi_total_gift", "keshi_sy", "keshi_sy_gift", "keshi_use_total", "keshi_use_total_gift"], // 展开字段集
                key: 'key',                   // key字段
                value: 'value',               // value字段
                retains: ['month']        // 保留字段集，默认为除 fields 以外的所有字段
            });
        const dv3 = ds.createView()
            .source(data)
            .transform({
                type: 'fold',
                fields: ["amount_new", "amount_use"], // 展开字段集
                key: 'key',                   // key字段
                value: 'value',               // value字段
                retains: ['month']        // 保留字段集，默认为除 fields 以外的所有字段
            });
        const dv4 = ds.createView()
            .source(data)
            .transform({
                type: 'fold',
                fields: ["amount_total", "amount_sy", "amount_use_total"], // 展开字段集
                key: 'key',                   // key字段
                value: 'value',               // value字段
                retains: ['month']        // 保留字段集，默认为除 fields 以外的所有字段
            });

        const nameconfig = {
            "keshi_new": "新增课时",
            "keshi_new_gift": "新增赠送课时",
            "keshi_use": "消耗课时",
            "keshi_use_gift": "消耗赠送课时",
            "keshi_total": "总课时",
            "keshi_total_gift": "总赠送课时",
            "keshi_sy": "剩余课时",
            "keshi_sy_gift": "剩余赠送课时",
            "keshi_use_total": "总消耗课时",
            "keshi_use_total_gift": "总消耗赠送课时",
            "amount_new": "新增支付",
            "amount_use": "消耗金额",
            "amount_total": "总支付金额",
            "amount_sy": "总剩余金额",
            "amount_use_total": "总消耗金额",
        };
        return (
            <PageContent title={"数据统计"}>
                <Card bordered={false}>

                    <Row justify="space-between">
                        <Col>
                            <RangePicker picker="month"
                                         onChange={this.onDateChange}
                                         defaultValue={[moment(this.state.begin), moment(this.state.end)]}
                            />
                        </Col>
                        <Col>
                            <Button onClick={this.reStat}>重新统计</Button>

                        </Col>
                    </Row>

                    <div>
                        <Chart height={400} data={dv1.rows} scale={cols} forceFit>
                            <h3 style={{textAlign:"center"}}>
                                每月课时统计
                            </h3>
                            <Legend itemFormatter={(val) => {
                                return nameconfig[val]; // val 为每个图例项的文本值
                            }}/>
                            <Axis name="month"/>
                            <Axis name="value"/>
                            <Tooltip
                                crosshairs={{
                                    type: "y"
                                }}
                            />
                            {/*<Line shape="smooth" position="month*value" color="key" label="temperature"/>*/}
                            <Geom type="line" shape='smooth' position="month*value" color="key" size={2}

                                  tooltip={['key*value', (key, value) => {
                                      return {
                                          name: nameconfig[key],
                                          value: value
                                      }
                                  }]}
                            />
                            <Geom
                                type="point"
                                position="month*value"
                                size={4}
                                shape={"circle"}
                                color="key"
                                style={{
                                    stroke: "#fff",
                                    lineWidth: 1
                                }}
                            />
                        </Chart>

                        <Chart height={400} data={dv2.rows} scale={cols} forceFit>
                            <h3 style={{textAlign:"center"}}>
                                累计课时统计
                            </h3>
                            <Legend itemFormatter={(val) => {
                                return nameconfig[val]; // val 为每个图例项的文本值
                            }}/>
                            <Axis name="month"/>
                            <Axis name="value"/>
                            <Tooltip
                                crosshairs={{
                                    type: "y"
                                }}
                            />
                            {/*<Line shape="smooth" position="month*value" color="key" label="temperature"/>*/}
                            <Geom type="line" shape='smooth' position="month*value" color="key" size={2}

                                  tooltip={['key*value', (key, value) => {
                                      return {
                                          name: nameconfig[key],
                                          value: value
                                      }
                                  }]}
                            />
                            <Geom
                                type="point"
                                position="month*value"
                                size={4}
                                shape={"circle"}
                                color="key"
                                style={{
                                    stroke: "#fff",
                                    lineWidth: 1
                                }}
                            />
                        </Chart>

                        <Chart height={400} data={dv3.rows} scale={cols} forceFit>
                            <h3 style={{textAlign:"center"}}>
                                每月支付统计
                            </h3>
                            <Legend itemFormatter={(val) => {
                                return nameconfig[val]; // val 为每个图例项的文本值
                            }}/>
                            <Axis name="month"/>
                            <Axis name="value"/>
                            <Tooltip
                                crosshairs={{
                                    type: "y"
                                }}
                            />
                            {/*<Line shape="smooth" position="month*value" color="key" label="temperature"/>*/}
                            <Geom type="line" shape='smooth' position="month*value" color="key" size={2}

                                  tooltip={['key*value', (key, value) => {
                                      return {
                                          name: nameconfig[key],
                                          value: value.toFixed(2)
                                      }
                                  }]}
                            />
                            <Geom
                                type="point"
                                position="month*value"
                                size={4}
                                shape={"circle"}
                                color="key"
                                style={{
                                    stroke: "#fff",
                                    lineWidth: 1
                                }}
                            />
                        </Chart>

                        <Chart height={400} data={dv4.rows} scale={cols} forceFit>
                            <h3 style={{textAlign:"center"}}>
                                累计支付统计
                            </h3>
                            <Legend itemFormatter={(val) => {
                                return nameconfig[val]; // val 为每个图例项的文本值
                            }}/>
                            <Axis name="month"/>
                            <Axis name="value"/>
                            <Tooltip
                                crosshairs={{
                                    type: "y"
                                }}
                            />
                            {/*<Line shape="smooth" position="month*value" color="key" label="temperature"/>*/}
                            <Geom type="line" shape='smooth' position="month*value" color="key" size={2}

                                  tooltip={['key*value', (key, value) => {
                                      return {
                                          name: nameconfig[key],
                                          value: value.toFixed(2)
                                      }
                                  }]}
                            />
                            <Geom
                                type="point"
                                position="month*value"
                                size={4}
                                shape={"circle"}
                                color="key"
                                style={{
                                    stroke: "#fff",
                                    lineWidth: 1
                                }}
                            />
                        </Chart>

                    </div>
                </Card>
            </PageContent>
        )
    }
}


Stat.propTypes = {
    classes_id: PropTypes.number
};


export default withRouter(Stat);


