import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {message, Modal, Form} from 'antd';

const EasyFormModal = ({onOk, onCancel, data, title, restFunc, width, formComponent: FormComponent}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        if(restFunc) {
            setLoading(true);
            let postValues = {
                ...data,
                ...values
            };
            restFunc(postValues)
                .then(result => {
                    if (result.code === 0) {
                        onOk && onOk();
                    } else {
                        message.error(result.message);
                    }
                })
                .catch(err => {
                    message.error(err.message);
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    };


    return (
        <Modal
            title={title}
            open={!!data}
            onOk={() => form.submit()}
            onCancel={onCancel}
            confirmLoading={loading}
            destroyOnClose={true}
            width={width}
        >
            <FormComponent form={form} onFinish={handleSubmit} initialValues={{...data}}/>
        </Modal>
    )
};


EasyFormModal.propTypes = {
    data: PropTypes.object,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.any,
    formComponent: PropTypes.object,
    restFunc: PropTypes.func,
    width: PropTypes.number
};

EasyFormModal.defaultProps = {
    width: 800,
};

export default EasyFormModal;

