/**
 * @name: python作业
 * @description:
 * @author: Wood
 * @date: 2019/4/21
 * @update:
 */
import React from 'react';
import {withRouter} from 'react-router-dom';
import {Button, message, Input, Radio, Switch, Select, Card, Form} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {postChallenge} from "../../../lib/api_challenges";
import ChallengesForm from "./challenges_form";


const ChallengesAdd = ({history}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                // 数据处理以及保存
                let config = {
                    name: values.name,
                    description: values.description || "",
                    statement: values.statement || "",
                    input_format: values.input_format || "",
                    constraints: values.constraints || "",
                    output_format: values.output_format || "",
                    testcases: JSON.parse(values.testcases || "[]")
                };
                console.log(config)

                postChallenge({
                    pkey: values.pkey,
                    name: values.name,
                    description: values.description,
                    statement: values.statement,
                    input_format: values.input_format,
                    constraints: values.constraints,
                    output_format: values.output_format,
                    tags: JSON.stringify(values.tags),
                    difficulty: values.difficulty,
                    is_public: values.is_public ? 1 : 0,
                    config: JSON.stringify(config),
                    tutorial_content: values.tutorial_content,
                }).then(result => {
                    if (result.code === 0) {
                        message.success("添加成功！");
                        history.go(-1);
                    } else {
                        message.error(result.message);
                    }
                })
            })
    };

    return (
        <PageContent title={"添加题目"}>
            <Card bordered={false}>
                <ChallengesForm form={form} onFinish={handleSubmit} initialValues={{}} />
            </Card>

        </PageContent>
    )
}
export default withRouter(ChallengesAdd);
