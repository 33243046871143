import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {PlusOutlined, DeleteOutlined,ShoppingCartOutlined} from '@ant-design/icons';
import {Table, Divider, Popconfirm, Button, message, Tag, Input, Card, Space, Row, Col} from 'antd';
import PageContent from '../../../components/page-content/page-content';
// import {getQuestions, delQuestion} from "../../../lib/api_questions";
import {getPapers,delPaper} from "../../../lib/api_papers";
// import QustionAddModal from './question_add_modal_danxuan';
// import QuestionEditModal from './question_edit_modal_danxuan';
// import QustionAddModalPanDuan from './question_add_modal_panduan';
// import QuestionEditModalPanDuan from './question_edit_modal_panduan';
// import QustionAddModalPython from './question_add_modal_python';
// import QustionEditModalPython from './question_edit_modal_python';
import ExaminationModal from './paper_edit_modal';
// import styles from './index.module.css';

const {Column} = Table;

const Questions = () => {
    const [list, setlist] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, seteditModal] = useState(null);
    const [addModalpanduan, setaddModalpanduan] = useState(false);
    const [editModalpanduan, seteditModalpanduan] = useState(null);
    const [addModalpython, setaddModalpython] = useState(false);
    const [editModalpython, seteditModalpython] = useState(null);
    const [selected, setselected] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createPaper, setcreatePaper] = useState(null);

    const fetchData = () => {
        getPapers()
            .then((result) => {
                if (result.code === 0) {
                    setlist(result.data);
                }
            })
    };

    useEffect(fetchData, []);

    const handleDelete = (id) => {
        // console.log(selectedRows)
        delPaper(selectedRows.map(m => m.id))
            .then((result) => {
                if (result.code === 0) {
                    fetchData();
                }
            })
    };

    const handleAddPocket = () => {
        let index = 0;
        for(let i in selectedRows){
            let record = selectedRows[i];
            if (selected.some(m => m.id === record.id)) {
                // message.warn("重复添加")
            } else {
                selected.push(record);
                index += 1;
            }
        }
        setselected([...selected]);
        message.success(`成功添加${index}条`);
    };

    const createExamPaper = ()=>{
        setcreatePaper(selected);
    };

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        // selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <PageContent title={"试卷"}>

            <Card bordered={false}>
                <Row justify={"space-between"}
                     style={{marginBottom: 10}}>
                    <Col>
                        <Space>
                            <Popconfirm
                                title={`是否删除${selectedRows.length}条记录？`}
                                onConfirm={handleDelete}
                                // onCancel={cancel}
                                okText="删除"
                                cancelText="取消"
                                disabled={selectedRows.length === 0}
                            >
                                <Button disabled={selectedRows.length === 0} icon={<DeleteOutlined />} danger>删除</Button>
                            </Popconfirm>

                        </Space>
                    </Col>
                    <Col>

                    </Col>

                </Row>
                <Table dataSource={list} rowKey={"id"}
                       pagination={false}
                       size="small"
                       rowSelection={rowSelection}
                       // scroll={{
                       //     // x: 1500,
                       //     y: `calc(100vh - 230px)`,
                       // }}
                >
                    <Column
                        title="编号"
                        dataIndex="id"
                        key="id"
                        width={60}
                    />
                    <Column
                        title="标题"
                        dataIndex="title"
                        key="标题"
                    />
                    <Column
                        title="总分"
                        dataIndex="score"
                        key="总分"
                        width={60}
                    />
                    <Column
                        title="操作"
                        key="操作"
                        width={60}
                        // render={(record)=> (<a onClick={()=>setcreatePaper(record)}>编辑</a>)}
                        render={(record)=> (<Link to={`/teaching/papers/${record.id}`}>编辑</Link>)}
                    />
                </Table>
            </Card>
            {/*{addModal &&*/}
            {/*<QustionAddModal*/}
                {/*onOk={() => {*/}
                    {/*setAddModal(false);*/}
                    {/*fetchData();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                    {/*setAddModal(false);*/}
                {/*}}*/}
                {/*visible={addModal}*/}
            {/*/>*/}
            {/*}*/}
            {/*{editModal &&*/}
            {/*<QuestionEditModal*/}
                {/*data={editModal}*/}
                {/*onOk={() => {*/}
                    {/*seteditModal(null);*/}
                    {/*fetchData();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                    {/*seteditModal(null);*/}
                {/*}}*/}
            {/*/>*/}
            {/*}*/}

            {/*{addModalpanduan &&*/}
            {/*<QustionAddModalPanDuan*/}
                {/*onOk={() => {*/}
                    {/*setaddModalpanduan(false);*/}
                    {/*fetchData();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                    {/*setaddModalpanduan(false);*/}
                {/*}}*/}
                {/*visible={addModalpanduan}*/}
            {/*/>*/}
            {/*}*/}
            {/*{editModalpanduan &&*/}
            {/*<QuestionEditModalPanDuan*/}
                {/*data={editModalpanduan}*/}
                {/*onOk={() => {*/}
                    {/*seteditModalpanduan(null);*/}
                    {/*fetchData();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                    {/*seteditModalpanduan(null);*/}
                {/*}}*/}
            {/*/>*/}
            {/*}*/}

            {/*{addModalpython &&*/}
            {/*<QustionAddModalPython*/}
                {/*onOk={() => {*/}
                    {/*setaddModalpython(false);*/}
                    {/*fetchData();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                    {/*setaddModalpython(false);*/}
                {/*}}*/}
                {/*visible={addModalpython}*/}
            {/*/>*/}
            {/*}*/}
            {/*{editModalpython &&*/}
            {/*<QustionEditModalPython*/}
                {/*data={editModalpython}*/}
                {/*onOk={() => {*/}
                    {/*seteditModalpython(null);*/}
                    {/*fetchData();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                    {/*seteditModalpython(null);*/}
                {/*}}*/}
            {/*/>*/}
            {/*}*/}
            {createPaper &&
            <ExaminationModal
                data={createPaper}
                onOk={() => {
                    setcreatePaper(null);
                    fetchData();
                }}
                onCancel={() => {
                    setcreatePaper(null);
                }}
            />
            }

        </PageContent>
    );
};

export default Questions;
