import React, {Component, useEffect, useState} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Form, Input, Table} from "antd";
import {getLessonsUsers} from "../../../lib/api_lessons_users";
import moment from "moment";


const Dianping = () => {
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState({});
    const [total, setTotal] = useState(0);

    const fetchData = () => {
        getLessonsUsers(filter)
            .then(res => {
                setList(res.data.list);
                setTotal(res.data.total);
            })
    }

    useEffect(fetchData, [filter]);

    const columns = [{
        title: '时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, record, index) => {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
        }
    }, {
        title: '点评',
        dataIndex: 'dianping_content',
        key: 'dianping_content',
    }, {
        title: '照片',
        dataIndex: 'dianping_photos',
        key: 'dianping_photos',
        render: (text, record, index) => {
            if (text) {
                let photos = text.split(',');
                return photos.map((photo, index) => {
                    return <img key={index} src={photo} style={{height: 50, margin: 5}}/>;
                })
            }
        }
    }, {
        title: '视频',
        dataIndex: 'dianping_videos',
        key: 'dianping_videos',
        render: (text, record, index) => {
            if (text) {
                let video = JSON.parse(text);
                return <video src={video.url}
                              style={{height: 50, margin: 5}}
                />;
            }
        }
    }];

    const handleTableChange = (pagination) => {
        filter.page = pagination.current;
        filter.pageSize = pagination.pageSize;
        setFilter({...filter});
    }

    const handleSearch = (value) => {
        console.log(value);
        filter.key=value.key;
        setFilter({...filter});
        // this.state.filter.key = value;
        // this.setState({});
        // this.fetch({
        //     page: this.props.page,
        //     key: this.state.filter.key
        // })
    };

    return (
        <PageContent title={"点评"}>
            <Form
                layout={"inline"}
                style={{marginBottom: 20}}
                onFinish={handleSearch}
            >
                <Form.Item label={"关键字"} name={"key"}>
                    <Input placeholder={"请输入关键字"}/>
                </Form.Item>
                <Form.Item>
                    <Button type={"primary"} htmlType={"submit"}>搜索</Button>
                </Form.Item>
            </Form>
            <Card bordered={false}>
                <Table dataSource={list}
                       rowKey={"id"}
                       size={"small"}
                       columns={columns}
                       pagination={{current: filter.page, pageSize: filter.pageSize, total: total}}
                       onChange={handleTableChange}
                />
            </Card>
        </PageContent>
    )
}

export default Dianping;