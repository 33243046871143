import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Radio, Menu} from 'antd';
import PageContent from '../../components/page-content/page-content';
import moment from 'moment';

import {Switch, Route} from "react-router-dom";
import Calendar from './calendar';
import CalendarWeek from './calendar-week';


class CalendarList extends Component {
    state = {
        month: '',
        courses: [],
        teachers: [],
        students: [],
        filter: 0
    };

    componentDidMount() {
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    render() {
        return (
            <div>
                <Menu mode="horizontal" defaultSelectedKeys={["month"]} style={{textAlign:"center"}}>
                    <Menu.Item key="month">
                        <Link to={`/`}>月历</Link>

                    </Menu.Item>
                    <Menu.Item key="week">
                        <Link to={`/calendar/week`}>周历</Link>
                    </Menu.Item>
                </Menu>
                <Switch>
                    <Route exact path="/" component={Calendar}/>
                    <Route exact path="/calendar" component={Calendar}/>
                    <Route path="/calendar/week" component={CalendarWeek}/>
                </Switch>
            </div>
        )
    }
}


export default withRouter(CalendarList);
