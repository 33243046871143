import React from 'react';
import {Button, Form, Input, Radio, Select, Switch} from "antd";
import MdEditor from "../../../components/md-editor/md-editor";
import TestcasesEdit from "../../../components/testcases-edit/testcases-edit";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

const ChallengesForm = ({form, onFinish,initialValues}) => {
    return(
        <Form {...formItemLayout}
              form={form}
              onFinish={onFinish}
              initialValues={initialValues}
        >
            <Form.Item label="编号" name="pkey" rules={[{
                required: true,
                message: '请输入编号',
            }]}>
                <Input/>
            </Form.Item>
            <Form.Item label="题目名称" name="name" rules={[{
                required: true,
                message: '请输入题目名称',
            }]}>
                <Input/>
            </Form.Item>
            <Form.Item label="描述" name="description">
                <Input.TextArea/>
            </Form.Item>
            <Form.Item label="难度" name="difficulty" rules={[{
                required: true,
                message: '请选择难度',
            }]}>
                <Radio.Group>
                    <Radio value={0}>简单</Radio>
                    <Radio value={1}>一般</Radio>
                    <Radio value={2}>困难</Radio>
                    <Radio value={3}>高级</Radio>
                    <Radio value={4}>专家</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="题目背景" name="statement">
                <MdEditor/>
            </Form.Item>
            <Form.Item label="输入格式" name="input_format">
                <MdEditor/>
            </Form.Item>
            <Form.Item label="约束" name="constraints">
                <MdEditor/>
            </Form.Item>
            <Form.Item label="输出格式" name="output_format">
                <MdEditor/>
            </Form.Item>
            <Form.Item label="标签" name="tags">
                <Select mode="tags" placeholder="Tags Mode">
                    <Select.Option key={"循环"}>循环</Select.Option>
                    <Select.Option key={"条件判断"}>条件判断</Select.Option>
                    <Select.Option key={"排序"}>排序</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="公开" name="is_public" valuePropName="checked">
                <Switch/>
            </Form.Item>
            <Form.Item label="测试用例" name="testcases">
                <TestcasesEdit/>
            </Form.Item>

            <Form.Item wrapperCol={{span: 12, offset: 4}}>
                <Button type="primary" htmlType="submit">
                    提交保存
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ChallengesForm;