import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Select} from 'antd';
import {getStudents} from "../../../lib/api_students";

const StudentSelectModal = ({onOk, onCancel}) => {
    const [list, setlist] = useState([]);
    const [ids, setids] = useState("");

    const fetchData = () => {
        getStudents(0)
            .then(res => {
                if(res.code === 0){
                    setlist(res.data)
                }
            })
    };
    useEffect(fetchData, []);


    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setids(value);
    };

    const handleOk= ()=>{
        onOk && onOk(ids)
    };

    return (
        <Modal
            title="选择学员"
            visible={true}
            onOk={handleOk}
            onCancel={onCancel}
            destroyOnClose={true}
            width={800}
        >
            <Select
                mode="multiple"
                allowClear
                showSearch
                style={{
                    width: '100%',
                }}
                placeholder="Please select"
                filterOption={(input, option) => option.children.includes(input)}
                onChange={handleChange}
            >
                {list.map(m=>(
                    <Select.Option value={m.id} label={m.nick_name}>{m.nick_name} {m.is_file===1?"失效":"正常"}</Select.Option>
                ))}
            </Select>
        </Modal>
    )
};

StudentSelectModal.propTypes = {
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default StudentSelectModal;