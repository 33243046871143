import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input, Form, Radio, Checkbox} from 'antd';
import FormOptions from './form_options';
import MdEditor from '../../../components/md-editor/md-editor';


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const FormDanXuanAnswer = ({value, onChange}) => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState({
        answer: "",
    });

    useEffect(() => {
        if (value) {
            let dt = JSON.parse(value);
            if (!dt.answer) {
                dt.answer = []
            } else {
                dt.answer = dt.answer.split('');
            }
            setConfig(dt);
            form.setFieldsValue(dt)
        }
    }, [value]);

    const handleChange = (changedValues, allValues) => {
        // console.log(allValues);
        let value = allValues.answer;
        if (!value || value.length === 0) {
            onChange && onChange(JSON.stringify({answer: ""}))
        } else {
            // 排序
            let allValues = value.sort();
            allValues = allValues.join('');
            console.log(allValues);
            onChange && onChange(JSON.stringify({answer: allValues}))
        }
    };

    return (
        <Form
            {...layout}
            form={form}
            onValuesChange={handleChange}
        >
            <Form.Item
                label="答案"
                name="answer"
                rules={[{
                    required: true,
                    message: '请输入答案'
                }]}
            >
                <Checkbox.Group>
                    <Checkbox value="A">A</Checkbox>
                    <Checkbox value="B">B</Checkbox>
                    <Checkbox value="C">C</Checkbox>
                    <Checkbox value="D">D</Checkbox>
                </Checkbox.Group>
            </Form.Item>
        </Form>
    )
};


FormDanXuanAnswer.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default FormDanXuanAnswer;