import React from 'react';
import {Form, Modal} from "antd";
import TagsSelect from "./tags_select";
import {postQuestionTags} from "../../../lib/api_questions";

const TagsEditModal = ({ids, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            postQuestionTags(ids, values.tags).then((res) => {
                if(res.code === 0) {
                    onOk && onOk();
                }
            }).catch((e) => {
                console.log(e)
            })
        })
    }
    return (
        <Modal
            title={"批量设置标签"}
            open={true}
            onOk={handleSubmit}
            onCancel={onCancel}
        >
            <Form form={form} layout="vertical">
                <Form.Item label="标签" name="tags">
                    <TagsSelect/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default TagsEditModal;