/**
 * @name: 一级菜单
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from "react-router-dom";
import {Menu, Dropdown} from 'antd';
import styles from './first-sider.module.css';
import menuConfig from './menu.config';
import logo from '../../assets/images/logo.png';
import AccountMenu from '../account_menu/account_menu';
import {withRouter} from 'react-router-dom';
import {useAccountStore} from "../../stores/useAccountStore";

/**
 * 一级菜单
 * @param path 页面路径
 * @param onHover 鼠标移入事件
 * @constructor
 */
const FirstSider = ({path, onHover}) => {
    const {account} = useAccountStore();

    return (
        <div className={styles.firstSider}>
            <div className={styles.logo}>
                <Dropdown overlay={<AccountMenu/>}>
                    <img src={logo}/>
                </Dropdown>
            </div>

            <Menu
                mode="inline"
                theme="dark"
                defaultSelectedKeys={[path]}
                style={{
                    background: "#273543"
                }}
            >
                {menuConfig.map(menu => {
                    if (account.login_name === "admin" && menu.path === "/stat") return null;
                    // 存在子节点
                    if (menu.children.length > 0) {
                        return (
                            <Menu.Item
                                key={menu.path}
                                icon={menu.icon}
                                className={styles.menuItem}
                                onMouseEnter={_ => onHover && onHover(menu)}
                            >
                                <Link to={menu.children[0].path}>
                                    {menu.minititle}
                                </Link>
                            </Menu.Item>
                        )
                    } else {
                        return (
                            <Menu.Item key={menu.path} icon={menu.icon} className={styles.menuItem}>
                                <Link to={menu.path}>
                                    {menu.minititle}
                                </Link>
                            </Menu.Item>
                        )
                    }
                })}
            </Menu>
        </div>
    )
};

/**
 * 属性
 * @type {{path: "页面路径", onHover: function}}
 */
FirstSider.propTypes = {
    path: PropTypes.string,
    onHover: PropTypes.func,
};



export default withRouter(FirstSider);

