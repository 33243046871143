import PropTypes from 'prop-types';
import React, {Component, useEffect, useState} from 'react';
import {Input, message, Modal, Radio, InputNumber, Divider, Form} from 'antd';
// import {postCourses} from "../../lib/api_courses";
import AliyunOSSUpload from '../../../components/aliyun-oss-upload';

import MdEditor from '../../../components/md-editor/md-editor';
import {putCoursesLessons} from "../../../lib/api_courses_lessons";
import ResourcesConfig from './resources-config';
import default_form_item_layout from "../../../config/default_form_item_layout";

const LessonsAddModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const typeValue = Form.useWatch('type', form);
    const [type, setType] = useState(0);

    useEffect(() => {
        console.log(typeValue);
        // data.type = typeValue;
        setType(typeValue);
    }, [typeValue]);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                setLoading(true);
                values.id = data.id;
                putCoursesLessons(values)
                    .then(result => {
                        if (result.code === 0) {
                            handleOK();
                        } else {
                            message.error(result.message);
                        }
                    })
                    .catch(err => {
                        message.error(err.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    };
    const handleClose = () => {
        onCancel && onCancel();
    };

    const handleOK = () => {
        onOk && onOk();
    };

    return (
        <Modal
            title="添加课时"
            open={!!data}
            onOk={handleSubmit}
            onCancel={handleClose}
            confirmLoading={loading}
            destroyOnClose={true}
            width={700}
        >
            <Form {...default_form_item_layout}
                  form={form}
                  initialValues={data}
            >
                <div>
                    <Divider orientation="left">基本信息</Divider>
                    <Form.Item label={"编号"} name="tag_index" rules={[{
                        required: true,
                        message: '请输入编号',
                    }]}>
                        <InputNumber min={0}/>
                    </Form.Item>
                    <Form.Item label={"课时名称"} name="name" rules={[{
                        required: true,
                        message: '请输入课时名称',
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"课程描述"} name="description">
                        <Input.TextArea autoSize={{minRows: 2}}/>
                    </Form.Item>
                    <Form.Item label={"课程简述（公开）"} name={"description2"}>
                        <Input.TextArea autoSize={{minRows: 2}}/>
                    </Form.Item>
                    <Form.Item label={"课程目标"} name={"objective"}>
                        <Input.TextArea autoSize={{minRows: 2}}/>
                    </Form.Item>
                    <Form.Item label={"教学重难点"} name={"points"}>
                        <Input.TextArea autoSize={{minRows: 2}}/>
                    </Form.Item>
                    <Form.Item label={"评价模板"} name={"dianping_template"}>
                        <Input.TextArea autoSize={{minRows: 2}}/>
                    </Form.Item>
                    <Form.Item label="封面图" name={"image_url"}>
                        <AliyunOSSUpload multiple={false}/>
                    </Form.Item>
                    <Form.Item label="相关资源" name={"resources_config"}>
                        <ResourcesConfig courses_lessons_id={data.id}/>
                    </Form.Item>
                    <Form.Item label="是否隐藏" name={"ishide"}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value={0}>显示</Radio.Button>
                            <Radio.Button value={1}>隐藏</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="课程类型" name="type">
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value={0}>Scratch</Radio.Button>
                            <Radio.Button value={1}>Python</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>
                {type === 0 && (
                    <div>
                        <div>
                            <Divider orientation="left">Scratch</Divider>

                        </div>
                        <Form.Item label={"课程模板编号"} name={"scratch_id"}>
                            <InputNumber min={0}/>
                        </Form.Item>
                        <Form.Item label={"作业模板编号"} name={"homework_scratch_id"}>
                            <InputNumber min={0}/>
                        </Form.Item>
                        <Form.Item label={"参考答案"} name={"homework_answer_id"}>
                            <InputNumber min={0}/>
                        </Form.Item>
                        <Form.Item label={"作业描述"} name={"homework_desc"}>
                            <Input.TextArea autoSize={{minRows: 2}}/>
                        </Form.Item>
                        <Form.Item label="视频讲解" name={"video_url"}>
                            <AliyunOSSUpload multiple={false}/>
                        </Form.Item>
                    </div>
                )}
                {/*Python*/}
                {type === 1 && (
                    <div>
                        <div>
                            <Divider orientation="left">Python</Divider>
                        </div>
                        <Form.Item label="必做练习" name={"homeworks_config1"}>
                            <ResourcesConfig courses_lessons_id={data.id}/>
                        </Form.Item>
                        <Form.Item label="进阶练习" name={"homeworks_config2"}>
                            <ResourcesConfig courses_lessons_id={data.id}/>
                        </Form.Item>
                    </div>
                )}
            </Form>
        </Modal>
    )
}
export default LessonsAddModal;