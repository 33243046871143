import React from 'react';
import {DatePicker, Input, Modal, Form, message} from "antd";
import moment from "moment/moment";
import {putLessonsUsers} from "../../../lib/api_lessons";
const CoursesUsersEditModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                // console.log(values,data);
                putLessonsUsers(data.lessons_id, data.user_id, moment(values.create_on).format("YYYY-MM-DD HH:mm"), values.keshi)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };
    return (
        <Modal
            title="修改上课记录"
            open={true}
            onOk={handleSubmit}
            onCancel={onCancel}
        >
            <Form
                form={form}
                initialValues={{
                    create_on: moment(data.create_on),
                    keshi: data.keshi
                }}
            >
                <Form.Item label={"上课时间"} name={"create_on"}>
                    <DatePicker
                        format={"YYYY-MM-DD HH:mm"}
                        showTime={{format: 'HH:mm'}}
                    />
                </Form.Item>
                <Form.Item label={"课时"} name={"keshi"}>
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CoursesUsersEditModal;