/**
 * @name: 教务管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import Classes from './classes/classes.jsx';
import ClassesDetail from './classes/classes_detail';
import Students from './students/students.jsx';
import Student from './student/detail';
import Notification from './notification/notification';
import Photos from './photos/photos';
import Dianping from './dianping/dianping';

const Router = () => (
    <Switch>
        <Route exact path="/affairs/classes"
               component={Classes}/>
        <Route exact path="/affairs/classes/:classes_id"
               component={({match}) =>
                   <ClassesDetail id={parseInt(match.params.classes_id)}/>}/>
        <Route exact path="/affairs/students"
               component={Students}/>
        {/*<Route exact path="/affairs/students/:classes_id"*/}
        {/*component={({match}) =>*/}
        {/*<Students classes_id={parseInt(match.params.classes_id)}/>}/>*/}
        <Route exact path="/affairs/students/:student_id"
               component={({match}) =>
                   <Student id={parseInt(match.params.student_id)}/>}/>
        <Route exact path="/affairs/notification"
               component={Notification}/>
        <Route exact path="/affairs/photos"
               component={Photos}/>
        <Route exact path="/affairs/dianping"
               component={Dianping}/>
    </Switch>
);

export default Router;
