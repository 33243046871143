import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {postTeacher} from "../../../lib/api_teachers";
import {Checkbox, Modal, Input, Form, Radio, Row, Col, Button, InputNumber, Space, message} from 'antd';
import md5 from 'md5';
import FormDanXuan from './form_danxuan';
import FormDanXuanAnswer from './form_danxuan_answer';
import MdEditor from '../../../components/md-editor/md-editor';
import {postQuestion} from "../../../lib/api_questions";
import {postPaper} from "../../../lib/api_papers";
import FormPanDuan from './form_panduan';
import MD from './md';
import styles from './examination_modal.module.css';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from './SortableItem';

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const ExaminationModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [config, setconfig] = useState({
        title: "试卷标题",
        desc: "试卷说明",
        score: 0,
        groups: []
    });

    useEffect(() => {
        // 按照type进行分组
        config.groups = [];
        let types = ["单选题", "多选题", "判断题", "python", "scratch"];
        for (let i in types) {
            let type = types[i];
            let questions = data.filter(m => m.type === type);
            if (questions.length > 0) {
                let group = {
                    type: type,
                    title: `${type}（共${questions.length}题，每题2分，共${questions.length * 2}分）`,
                    score: 0,
                    score_avg: 2,
                    questions: []
                };
                for (let j in questions) {
                    group.questions.push({
                        score: 2,
                        qid: questions[j].id,
                        type: questions[j].type,
                        content: questions[j].content,
                        answer: questions[j].answer,
                    })
                }

                config.groups.push(group);
            }
        }

        // answer: "{\"answer\":\"B\"}"
        // content: "{\"title\":\"以下哪个是Python保存文件时的文件扩展名？（     ）\",\"options\":[{\"key\":\"A\",\"value\":\".yp\"},{\"key\":\"B\",\"value\":\".py\"},{\"key\":\"C\",\"value\":\".ppy\"},{\"key\":\"D\",\"value\":\".yyp\"}]}"
        // createdAt: "2022-08-10T13:38:30.000Z"
        // deletedAt: null
        // id: 26
        // score: 2
        // title: "以下哪个是Python保存文件时的文件扩展名？（     ）"
        // type: "单选题"
        // updatedAt: "2022-08-10T13:38:30.000Z"

        console.log(config);
        // setconfig({...config});
        reBuild();

        // config.data = data;
        // setconfig(config);
    }, []);

    // 重新编号
    const reBuild = () => {
        let index = 1;
        for (let i in config.groups) {
            let group = config.groups[i];
            for (let j in group.questions) {
                group.questions[j].id = index;
                index += 1;
            }
        }

        setconfig({...config});
    };

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.type = "单选题";
                postQuestion(values)
                    // values.password = md5(values.password);
                    // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };


    // 智能识别单选题
    const handleDanXuan = (value) => {
        // console.log(e.target.value);
        var pattern = /^([^A]*)A\.([^A]*)B\.([^B]*)C\.([^C]*)D\.([^D]*)$/m;
        // console.log(pattern.match(e.target.value));
        let result = value.match(pattern);
        console.log(result);
        let title = result[1].trim();
        var pattern2 = /^\d*\.([\s\S]*)$/m;
        let result2 = title.match(pattern2);
        console.log(result2)
        title = result2[1].trim();

        form.setFieldsValue({
            title: title,
            content: JSON.stringify({
                title: title,
                options: [{
                    key: "A",
                    value: result[2].trim()
                }, {
                    key: "B",
                    value: result[3].trim()
                }, {
                    key: "C",
                    value: result[4].trim()
                }, {
                    key: "D",
                    value: result[5].trim()
                }]
            })
        })

    }

    const handleChangeScore = (item, score) => {
        item.score = score;
        setconfig({...config});
    };

    const handleChangeScoreMuti = (group) => {
        for (let i in group.questions) {
            group.questions[i].score = group.score_avg;
        }
        setconfig({...config});
    };

    const handleRefrushTitle = (group) => {
        let score = 0;
        for (let i in group.questions) {
            score += group.questions[i].score;
        }
        group.score = score;
        group.title = `${group.type}（共${group.questions.length}题，每题${group.score_avg}分，共${group.score}分）`;
        // setconfig({...config});
        handleStatScore()
    };

    const handleStatScore = () => {
        let score = 0;
        for (let i in config.groups) {
            score += config.groups[i].score
        }
        config.score = score;
        setconfig({...config});
    };

    const renderItem = (item) => {
        switch (item.type) {
            case "单选题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={config.title}></MD>
                        <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                     addonAfter={"分"} style={{width: 100}}/>
                        {/*{config.title}*/}
                    </div>
                    <div>
                        <Radio.Group value={answer.answer}>
                            <Space direction="vertical">
                                {config.options.map(option => (
                                    <Radio value={option.key}>
                                        <div className={styles.options}>
                                            <span>{option.key}.</span>
                                            <MD value={option.value}/>
                                        </div>
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </div>
                </div>)
            }
            case "多选题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={config.title}></MD>
                        <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                     addonAfter={"分"} style={{width: 100}}/>
                        {/*{config.title}*/}
                    </div>
                    <div>
                        <Checkbox.Group value={answer.answer ? answer.answer.split('') : []}>
                            <Space direction="vertical">
                                {config.options.map(option => (
                                    <Checkbox value={option.key}>
                                        <div className={styles.options}>
                                            <span>{option.key}.</span>
                                            <MD value={option.value}/>
                                        </div>
                                    </Checkbox>
                                ))}
                            </Space>
                        </Checkbox.Group>
                    </div>
                </div>)
            }
            case "判断题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                        <div className={styles.title}>
                            <span>{item.id}.</span>
                            <MD value={config.title}></MD>
                            <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                         addonAfter={"分"} style={{width: 100}}/>
                            {/*{config.title}*/}
                        </div>
                        <div>

                            <Radio.Group value={answer.answer}>
                                <Space direction="vertical">
                                    <Radio value={"正确"}>
                                        <div className={styles.options}>
                                            正确
                                        </div>
                                    </Radio>
                                    <Radio value={"错误"}>
                                        <div className={styles.options}>
                                            错误
                                        </div>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                            {/*{config.options.map(option=>(*/}
                            {/*<div className={styles.options}>*/}
                            {/*<span>{option.key}.</span>*/}
                            {/*<MD value={option.value} />*/}
                            {/*</div>*/}
                            {/*))}*/}
                        </div>
                    </div>
                )
            }
            case "python": {
                let config = JSON.parse(item.content);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={config.content}></MD>
                        <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                     addonAfter={"分"} style={{width: 100}}/>
                        {/*{config.title}*/}
                    </div>
                    <div>
                        <div>评分标准：</div>
                        <MD value={config.pfbz}></MD>

                    </div>

                </div>)
            }
            case "scratch": {
                let config = JSON.parse(item.content);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <div style={{flex: 1}}>
                            <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                         addonAfter={"分"} style={{width: 100}}/>
                            <MD value={config.content}></MD>
                        </div>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <a href={`https://xmybc.com/scratch/projects/${config.scratch_id}`} target="_blank">
                            <Button type={"primary"} size={"large"}>去改编</Button>
                        </a>
                    </div>
                </div>)
            }
            default:
                return (
                    <div>
                        {item.content}
                    </div>
                )
        }

    };

    const renderGroup = (group) => {
        return (<div className={styles.group}>
            <div className={styles.groupTitle}>
                <Space>
                    <Input.Group compact>
                        <Input value={group.title} onChange={(e) => {
                            group.title = e.target.value;
                            setconfig({...config})
                        }} size="large" style={{width: 400}}/>
                        <Button size="large" onClick={() => handleRefrushTitle(group)}>刷新</Button>
                    </Input.Group>
                    <Input.Group compact>
                        <InputNumber size="large" addonAfter={"分"} style={{width: 100}}
                                     value={group.score_avg}
                                     onChange={(value) => {
                                         group.score_avg = value;
                                         setconfig({...config})
                                     }}
                        />
                        <Button size="large" onClick={() => handleChangeScoreMuti(group)}>批量设置</Button>

                    </Input.Group>
                </Space>
            </div>
            <div>
                {group.questions.map(renderItem)}
            </div>
        </div>)
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const handleDragEnd = (event, group) => {
        const {active, over} = event;

        console.log(event)

        if (active.id !== over.id) {
            let items = group.questions;
            const oldIndex = items.findIndex((item) => {
                return active.id === item.id
            });
            const newIndex = items.findIndex((item) => {
                return over.id === item.id
            });
            group.questions = arrayMove(items, oldIndex, newIndex)
            console.log(group.questions, oldIndex, newIndex)

            // setconfig({...config});
            reBuild();

            // setItems((items) => {
            //     const oldIndex = items.indexOf(active.id);
            //     const newIndex = items.indexOf(over.id);
            //
            //     return arrayMove(items, oldIndex, newIndex);
            // });
        }
    };

    const handleSave = () => {
        console.log(config);
        postPaper({
            title: config.title,
            content: JSON.stringify(config),
            score: config.score,
        }).then(result => {
            if (result.code === 0) {
                onOk && onOk();
                message.success("生成成功！")
            }

        })
    }
    return (
        <Modal
            title="生成试卷"
            visible={true}
            onCancel={onCancel}
            onOk={handleOk}
            width={"90%"}
            style={{top: 20}}
            footer={false}
        >
            <Row>
                <Col flex={"200px"}>
                    <div style={{position: "fixed", top: 100, width: 200}}>
                        {config.groups.map(group => (
                            <div>
                                <div>{group.type}</div>
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={event => handleDragEnd(event, group)}
                                >
                                    <SortableContext
                                        items={group.questions}
                                        strategy={rectSortingStrategy}
                                    >
                                        {group.questions.map(item => <SortableItem key={item.id} id={item.id}
                                                                                   data={item}/>)}
                                    </SortableContext>
                                </DndContext>
                            </div>
                        ))}
                        <div>
                            试卷总分：{config.score}
                        </div>
                        <div>
                            <Button>试卷预览</Button>
                            <Button onClick={handleSave}>保存试卷</Button>
                        </div>
                    </div>
                </Col>
                <Col flex={"1"} style={{overflow: "auto"}}>
                    <div>
                        试卷标题：
                        <Input value={config.title} onChange={(e) => {
                            config.title = e.target.value;
                            setconfig({...config});
                        }}/>
                        试卷说明：
                        <MdEditor value={config.desc} onChange={(value) => {
                            config.desc = value;
                            setconfig({...config});
                        }}/>
                    </div>
                    {config.groups.map(renderGroup)}
                </Col>
            </Row>
        </Modal>
    )
};


ExaminationModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default ExaminationModal;