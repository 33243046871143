/**
 * @name: 课程资源相关接口
 * @description:
 * @author: Wood
 * @date: 2019/4/3
 * @update:
 */
import axios from './rest-axios';

const getLessonsUsers = (filter) => axios.get(`/v1/lessons_users`, filter);
const putLessonsUsersDianPing = (model) => axios.put(`/v1/lessons_users/dianping`, model);

export {
    getLessonsUsers,
    putLessonsUsersDianPing,
};
