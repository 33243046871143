/**
 * @name: python作业
 * @description:
 * @author: Wood
 * @date: 2019/4/21
 * @update:
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import {Table, Divider, Popconfirm, Button, message, Tag, Input, Card, Space} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getList} from "../../../lib/api_challenges";

const {Column} = Table;

class Challenges extends Component {
    state = {
        list: [],
        pagination: {
            current: this.props.page,
            pageSize: 50,
            position: "both"
        },
        loading: false,
        filter: {
            key: ""
        }
    };

    componentDidMount() {
        this.fetch({
            page: this.props.page,
            key: this.state.filter.key
        })
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取数据
    fetch = (params = {}) => {
        console.log('params:', params);
        this.setState({loading: true});

        getList({
            results: 50,
            ...params
        })
            .then(result => {
                const pagination = {...this.state.pagination};
                pagination.total = result.totalCount;
                this.setState({
                    loading: false,
                    list: result.data,
                    pagination,
                })
            })
    };


    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.props.history.replace(`/teaching/challenges/python/${pager.current}`);
        // this.fetch({
        //     results: pagination.pageSize,
        //     page: pagination.current,
        //     sortField: sorter.field,
        //     sortOrder: sorter.order,
        //     ...filters,
        // });
    };

    handleSearch = (value) => {
        this.state.filter.key = value;
        this.setState({});
        this.fetch({
            page: this.props.page,
            key: this.state.filter.key
        })
    };

    render() {
        let {list} = this.state;
        return (
            <PageContent title={"Python 题库"}>
                <Card bordered={false}>
                    <Space>

                        <Link to={`/teaching/challenges/python/create`}><Button type='primary'
                                                                       icon={<PlusOutlined/>}>添加题目</Button></Link>

                        <Input.Search placeholder="input search text"
                                      onSearch={value => this.handleSearch(value)}
                                      style={{width: 300}}
                                      enterButton/>

                        <Button type='primary' className='button-color-green'
                                icon={<PlusOutlined/>}
                                onClick={() => this.props.history.push("/teaching/challenges/python/create/choice")}>新建选择题</Button>
                        <Button type='primary' className='button-color-green'
                                icon={<PlusOutlined/>}
                                onClick={() => this.props.history.push("/teaching/challenges/python/create/truefalse")}>新建判断题</Button>
                        <Button type='primary' className='button-color-green'
                                icon={<PlusOutlined/>}
                                onClick={this.newProblemHandler}>新建编程题</Button>

                    </Space>

                    <Table dataSource={list} rowKey={"id"}
                           pagination={this.state.pagination}
                           onChange={this.handleTableChange}
                           size="small"
                    >
                        <Column
                            title="编号"
                            dataIndex="id"
                            key="id"
                            width={60}
                            render={(id) => (
                                <Link to={`/teaching/challenges/python/${id}/edit`}>{id}</Link>
                            )}
                        />
                        <Column
                            title="编号"
                            dataIndex="pkey"
                            key="pkey"
                            width={60}
                        />
                        <Column
                            title="标题"
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="难度"
                            dataIndex="difficulty"
                            key="difficulty"
                            width={60}
                            render={(difficulty) => (
                                <span>
                                {difficulty === 0 && "简单"}
                                    {difficulty === 1 && "一般"}
                                    {difficulty === 2 && "困难"}
                                    {difficulty === 3 && "高级"}
                                    {difficulty === 4 && "专家"}
                            </span>
                            )}
                        />
                        <Column
                            title="标签"
                            dataIndex="tags"
                            key="tags"
                            render={(tags) => (
                                <span>
                                {tags && JSON.parse(tags).map(tag => <Tag>{tag}</Tag>)}
                            </span>
                            )}
                        />

                        <Column
                            title="公开"
                            dataIndex="is_public"
                            key="is_public"
                            render={(is_public) => (
                                <span>
                                {is_public === 1
                                    ? <Tag color="green">公开</Tag>
                                    : <Tag color="red">不公开</Tag>}

                            </span>
                            )}
                        />

                        <Column
                            title="操作"
                            key="action"
                            width={60}
                            render={(text, record) => (
                                <div>
                                    <Link to={`/teaching/challenges/python/${record.id}/edit`}>修改</Link>
                                    {/*<Divider type="vertical"/>*/}
                                    {/*<Popconfirm title='确定删除该作业吗？'*/}
                                    {/*onConfirm={() => this.handleRemove(record)}*/}
                                    {/*okText="确定"*/}
                                    {/*cancelText="取消"*/}
                                    {/*>*/}
                                    {/*<Link to={`/courses/${record.id}`}>删除</Link>*/}
                                    {/*</Popconfirm>*/}
                                </div>
                            )}
                        />
                    </Table>
                </Card>
            </PageContent>
        );
    }
}


Challenges.propTypes = {
    page: PropTypes.number.isRequired,
};


export default withRouter(Challenges);

