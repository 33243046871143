import React from 'react';
import styles from "./examination_modal.module.css";
import MD from "./md";
import {Button, Checkbox, InputNumber, Radio, Space} from "antd";


const QuestionDetail = ({data: item}) => {
    switch (item.type) {
        case "单选题": {
            let config = JSON.parse(item.content);
            let answer = JSON.parse(item.answer);
            return (<div className={styles.question}>
                <div className={styles.title}>
                    <MD value={config.title}></MD>
                </div>
                <div>
                    <Radio.Group value={answer.answer}>
                        <Space direction="vertical">
                            {config.options.map(option => (
                                <Radio value={option.key}>
                                    <div className={styles.options}>
                                        <span>{option.key}.</span>
                                        <MD value={option.value}/>
                                    </div>
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                </div>
            </div>)
        }
        case "多选题": {
            let config = JSON.parse(item.content);
            let answer = JSON.parse(item.answer);
            return (<div className={styles.question}>
                <div className={styles.title}>
                    <MD value={config.title}></MD>
                </div>
                <div>
                    <Checkbox.Group value={answer.answer ? answer.answer.split('') : []}>
                        <Space direction="vertical">
                            {config.options.map(option => (
                                <Checkbox value={option.key}>
                                    <div className={styles.options}>
                                        <span>{option.key}.</span>
                                        <MD value={option.value}/>
                                    </div>
                                </Checkbox>
                            ))}
                        </Space>
                    </Checkbox.Group>
                </div>
            </div>)
        }
        case "判断题": {
            let config = JSON.parse(item.content);
            let answer = JSON.parse(item.answer);
            return (<div className={styles.question}>
                    <div className={styles.title}>
                        <MD value={config.title}></MD>
                    </div>
                    <div>

                        <Radio.Group value={answer.answer}>
                            <Space direction="vertical">
                                <Radio value={"正确"}>
                                    <div className={styles.options}>
                                        正确
                                    </div>
                                </Radio>
                                <Radio value={"错误"}>
                                    <div className={styles.options}>
                                        错误
                                    </div>
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div>
            )
        }
        case "python": {
            let config = JSON.parse(item.content);
            return (<div className={styles.question}>
                <div className={styles.title}>
                    <MD value={config.content}></MD>
                </div>
                <div>
                    <div>评分标准：</div>
                    <MD value={config.pfbz}></MD>

                </div>

            </div>)
        }
        case "scratch": {
            let config = JSON.parse(item.content);
            return (<div className={styles.question}>
                <div className={styles.title}>
                    <div style={{flex: 1}}>
                        <MD value={config.content}></MD>
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <a href={`https://xmybc.com/scratch/projects/${config.scratch_id}`} target="_blank">
                        <Button type={"primary"} size={"large"}>去改编</Button>
                    </a>
                </div>
            </div>)
        }
        default:
            return (
                <div>
                    {item.content}
                </div>
            )
    }
}

export default QuestionDetail;