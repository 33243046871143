import React, {useEffect} from 'react';
import default_form_item_layout from "../../../config/default_form_item_layout";
import {DatePicker, Form, Input, InputNumber, Radio} from "antd";
import MdEditor from "../../../components/md-editor/md-editor";

export default function ContestForm({form, onFinish, initialValues}) {
    const start_time = Form.useWatch('start_time', form);
    const end_time = Form.useWatch('end_time', form);
    const auth = Form.useWatch('auth', form);

    useEffect(() => {
        if (end_time && start_time) {
            let duration = end_time.diff(start_time, "seconds");
            form.setFieldsValue({duration});
        }
    }, [start_time, end_time])

    // useEffect(() => {
    //     if (auth) {
    //         form.setFieldsValue({auth});
    //     }
    // }, [auth])

    return (
        <Form
            labelCol={{flex: "110px"}}
            wrapperCol={{flex: 1}}
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Form.Item name="title" label="标题" rules={[{
                required: true,
                message: '请输入${label}！',
            },]}>
                <Input/>
            </Form.Item>
            <Form.Item name="type" label="赛制" rules={[{
                required: true,
                message: '请选择${label}！',
            },]}>
                <Radio.Group>
                    <Radio value={0}>ACM</Radio>
                    <Radio value={1}>Rating/IOI</Radio>
                    <Radio value={2}>OI</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="start_time" label="开始时间" rules={[{
                required: true,
                message: '请选择${label}！',
            },]}>
                <DatePicker showTime/>
            </Form.Item>
            <Form.Item name="end_time" label="结束时间" rules={[{
                required: true,
                message: '请选择${label}！',
            },]}>
                <DatePicker showTime/>
            </Form.Item>
            <Form.Item name="duration" label="比赛时长">
                <InputNumber disabled/>
            </Form.Item>
            <Form.Item name="auth" label="是否公开" rules={[{
                required: true,
                message: '请选择${label}！',
            },]}>
                <Radio.Group>
                    <Radio value={0}>公开赛</Radio>
                    <Radio value={1}>私有赛（有密码）</Radio>
                    <Radio value={3}>保护赛（有密码）</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="pwd" label="密码">
                <Input disabled={auth === 0}/>
            </Form.Item>
            <Form.Item name="description" label="比赛说明">
                <MdEditor/>
            </Form.Item>
            <Form.Item name="visible" label="是否可见" rules={[{
                required: true,
                message: '请选择${label}！',
            },]}>
                <Radio.Group>
                    <Radio value={0}>隐藏</Radio>
                    <Radio value={1}>可见</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="user_id" label="创建者id">
                <Input/>
            </Form.Item>
            <Form.Item name="author" label="比赛创建者的用户名">
                <Input/>
            </Form.Item>
        </Form>
    );
}