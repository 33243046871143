import React, {useEffect} from 'react';
import {message, Modal, Input, Switch, Form} from 'antd';
import ModeSelect from '../../../components/mode-select/mode-select';
import {putScratch} from "../../../lib/api_scratchs";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

const PublishModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const is_opensourceValue = Form.useWatch('is_opensource', form);

    useEffect(() => {
        // console.log(is_opensourceValue);
        // console.log(form.getFieldsValue());
        if (!is_opensourceValue) {
            form.setFieldValue("is_editable", 0);
        }
    }, [is_opensourceValue]);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields()
            .then(values => {
                values.id = data.id;
                putScratch(values)
                    .then(result => {
                        if (result.code === 0) {
                            // this.loadData();
                            message.success("修改成功");
                            handleOk();
                        } else {
                            message.error(result.message);
                        }
                    })
            })
    };
    const handleCancel = () => {
        onCancel && onCancel();
    };
    const handleOk = () => {
        onOk && onOk();
    };

    return (
        <Modal
            title={"发布作品"}
            open={!!data}
            onOk={handleSubmit}
            onCancel={handleCancel}
            width={600}
            // footer={null}
            destroyOnClose
        >
            <Form {...formItemLayout}
                  onSubmit={handleSubmit}
                  form={form}
                  initialValues={{
                      name: data ? data.name : "",
                      project_desc: data && data.project_desc ? data.project_desc : "我在小码蚁学编程，这是我的作品，大家给我点个赞吧",
                      project_instruction: data && data.project_instruction ? data.project_instruction : "",
                      mobile_mode: data && data.mobile_mode ? data.mobile_mode : 1,
                      is_opensource: data && data.is_opensource,
                      is_editable: data && data.is_editable,
                  }}
            >
                <Form.Item
                    label="作品名称"
                    name="name"
                    rules={[{required: true, message: '请输入作品名称'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="作品描述"
                    name="project_desc"
                    rules={[{required: true, message: '请输入作品描述'}]}
                >
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item
                    label="操作说明"
                    name="project_instruction"
                >
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item
                    label="控制模式"
                    name="mobile_mode"
                    rules={[{required: true, message: '请选择控制模式'}]}
                >
                    <ModeSelect/>
                </Form.Item>
                <Form.Item
                    label="公开源码"
                    name="is_opensource"
                    valuePropName="checked"
                >
                    <Switch checkedChildren="公开" unCheckedChildren="保密"/>
                </Form.Item>
                <Form.Item
                    label="允许改编"
                    name="is_editable"
                    valuePropName="checked"
                    rules={[
                        ({getFieldValue}) => ({
                            validator: (rule, value) => {
                                if (value && !getFieldValue("is_opensource")) {
                                    return Promise.reject('先公开源码才能改编哦！');
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}
                >
                    <Switch checkedChildren="允许" unCheckedChildren="禁止"/>
                </Form.Item>
            </Form>

        </Modal>
    )
}
export default PublishModal;
