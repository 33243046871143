/**
 * @name: http请求基础
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import axios from 'axios';
// import config from '../config/config';
// import {jumpToAuth} from "../utils/index";
// import {toast} from 'react-toastify';
// import message from 'antd/lib/message';
// import 'antd/lib/message/style/css';
import cookies from 'js-cookie';
// import store from '../reducers';
import {message} from 'antd';
// import {signOut} from "../reducers/account";
import {useAccountStore} from "../stores/useAccountStore";
import instance from "./axiosInterceptor";

// import {
//     openIdeLogin
// } from '../reducers/modals';

// axios.defaults.baseURL = 'http://hacker.yunqilab.com/api';
// axios.defaults.baseURL = window.location.host.indexOf('localhost') > -1 ? "http://localhost:4003/api" : `${window.location.protocol}//${window.location.host}/api`; //config.api_url;

// 拦截request,设置全局请求为ajax请求
// axios.interceptors.request.use(config => {
//     config.headers['X-Requested-With'] = 'XMLHttpRequest';
//     // console.log('request begin');
//     return config;
// });


// // get接口测试，传入一个参数id，请求/test/:id接口，返回response并且将数据通过指定的action保存到store。
// export const getTest = id => async (dispatch, getState) => {
//     try {
//         let response = await getData(`/test/${id}`)
//         await dispatch(saveReducer(response.data))
//     } catch (error) {
//         console.log('error: ', error)
//     }
// }
//
// // 拦截响应response，并做一些错误处理
// axios.interceptors.response.use(response => {
//     const data = response.data;
//
//     console.log(data);
//
//     if (data.code === 1 && data.message) {
//         message.error(data.message)
//         // toast.error(data.message)
//     }
//
//     return data;
// }, err => { // 这里是返回状态码不为200时候的错误处理
//     // const signOut = useAccountStore().getState().signOut;
//     if (err && err.response) {
//         if (err.response.data && err.response.data.code === 1) {
//             message.error(err.response.data.message);
//         } else {
//             // message.error(err.message);
//         }
//         switch (err.response.status) {
//             case 400:
//                 err.message = '请求错误';
//                 break;
//
//             case 401:
//                 err.message = '未授权，请登录';
//                 // useAccountStore.getState().signOut();
//                 // useAccountStore().getState().signOut();
//                 // store.dispatch(showLoginModal());
//                 // store.dispatch(signOut());
//                 // jumpToAuth();
//                 // window.location.href = config.oauth2.url_auth
//                 break;
//
//             case 403:
//                 err.message = '拒绝访问';
//                 break;
//
//             case 404:
//                 err.message = `请求地址出错: ${err.response.config.url}`;
//                 break;
//
//             case 408:
//                 err.message = '请求超时';
//                 break;
//
//             case 500:
//                 err.message = '服务器内部错误';
//                 break;
//
//             case 501:
//                 err.message = '服务未实现';
//                 break;
//
//             case 502:
//                 err.message = '网关错误';
//                 break;
//
//             case 503:
//                 err.message = '服务不可用';
//                 break;
//
//             case 504:
//                 err.message = '网关超时';
//                 break;
//
//             case 505:
//                 err.message = 'HTTP版本不受支持';
//                 break;
//
//             default:
//         }
//     }
//
//     return Promise.reject(err);
// });


const get = (url, params) => new Promise((resolve, reject) => {
    instance({
        method: 'GET',
        url: url,
        headers: {authorization: cookies.get('authorization')},
        json: true,
        params: params
    })
        .then(res => {
            // console.log(`${url}\tGet请求到:`);
            // console.log(res);
            // alert('get:'+this.res);
            resolve(res);
        })
        .catch(error => {
            console.log(error);
            reject(error);
        });
});
const post = (url, data) => new Promise((resolve, reject) => {
    instance({
        method: 'POST',
        url: url,
        headers: {authorization: cookies.get('authorization')},
        json: true,
        data: data
    })
        .then(res => {
            // console.log(`${url}\tGet请求到:`);
            // console.log(res);
            // alert('get:'+this.res);
            resolve(res);
        })
        .catch(error => {
            console.log(error);
            reject(error);
        });
});
const put = (url, data) => new Promise((resolve, reject) => {
    instance({
        method: 'PUT',
        url: url,
        headers: {authorization: cookies.get('authorization')},
        json: true,
        data: data
    })
        .then(res => {
            // console.log(`${url}\tGet请求到:`);
            // console.log(res);
            // alert('get:'+this.res);
            resolve(res);
        })
        .catch(error => {
            console.log(error);
            reject(error);
        });
});


const adelete = (url, data) => new Promise((resolve, reject) => {
    instance({
        method: 'DELETE',
        url: url,
        headers: {authorization: cookies.get('authorization')},
        json: true,
        data: data
    })
        .then(res => {
            // console.log(`${url}\tGet请求到:`);
            // console.log(res);
            // alert('get:'+this.res);
            resolve(res);
        })
        .catch(error => {
            console.log(error);
            reject(error);
        });
});

// const mapDispatchToProps = dispatch => ({
//     openIdeLogin: () => dispatch(openIdeLogin())
// });
//
// export default injectIntl(connect(
//     null,
//     mapDispatchToProps
// )(axios));

export default {
    get,
    post,
    put,
    delete: adelete
};
