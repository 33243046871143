/**
 * @name: 班级详情
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {putClasses, getClass} from "../../../lib/api_classes.js";
import {deleteLessons, postLessons, putLessons, postDianmingLessons, postCopyLessons} from "../../../lib/api_lessons";
import {DownOutlined, PlusOutlined} from '@ant-design/icons';
import {
    Button,
    Modal,
    Input,
    Alert,
    Table,
    Divider,
    Popconfirm,
    Tabs,
    PageHeader,
    Tag,
    Col,
    DatePicker,
    Checkbox,
    Radio,
    Tooltip,
    Dropdown,
    Menu,
    message,
    Card,
    InputNumber,
    Descriptions,
    Space,
} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import moment from 'moment';
import DianmingDetail from '../../teaching/courses/dianming_detail_modal';
import StudentList from '../students/student_list';
import ClassesEditModal from './classes_edit_modal';
import LessonEditModal from './lesson_edit_modal';
import StudentAddModal from './classes_student_add_modal';
import RoomName from '../../../components/rooms/room-name';
import TagsDisplay from "./tags_display";
import LessonProgress from "./lesson_progress";
import DianMingModal from "./dianming_modal";
import LessonAddModal from "./lesson_add_modal";

const {Column} = Table;


class ClassDetail extends Component {
    state = {
        list: [],
        visibleCreateModal: false,
        createTitle: '',
        createTitleError: '',
        createModalConfirmLoading: false,

        editItem: null,
        editTitle: '',
        editTitleError: '',
        editModalConfirmLoading: false,

        class_info: {
            students: [],
            courses: []
        },
        edit_info: {
            name: '',
            remark: ''
        },
        showCourseAddModal: false,
        courseAddNames: '',
        CourseAddModalConfirmLoading: false,

        editCourse: null,

        dianmingCourse: null
    };

    componentDidMount() {
        this.loadClasses();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadClasses = () => {
        getClass(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        class_info: result.data,
                        edit_info: {
                            name: result.data.name,
                            remark: result.data.remark,
                            creater: result.data.creater,
                            status: result.data.status
                        }
                    })
                } else {

                }
            })
    };

    // 删除课程
    handleCourseRemove = (item) => {
        deleteLessons(item.id)
            .then(result => {
                this.loadClasses()
            })
    };

    // 批量添加课程
    handleCourseAdd = () => {
        // 去除收尾空格
        let title = this.state.courseAddNames;
        title = title.replace(/(^\s*)|(\s*$)/g, "");
        if (title.length === 0) {
            // 提示错误
            this.setState({
                editTitleError: '请输入课程名称',
                courseAddNames: title
            })
        } else {
            // 新建班级操作
            this.setState({
                CourseAddModalConfirmLoading: true
            });
            postLessons(this.state.class_info.id, title)
                .then(result => {
                    if (result.code === 0) {
                        this.setState({
                            CourseAddModalConfirmLoading: false,
                            showCourseAddModal: false
                        });
                        this.loadClasses()
                    } else {
                        this.setState({
                            CourseAddModalConfirmLoading: false,
                            editTitleError: result.message
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        CourseAddModalConfirmLoading: false,
                        editTitleError: JSON.stringify(err)
                    });
                });

        }
    };

    // 复制
    handleCopy = (id, date) => {
        postCopyLessons(id, date)
            .then(result => {
                if (result.code === 0) {
                    this.loadClasses()
                } else {
                    message.error(result.message);
                }
            })
    };

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };

    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    render() {
        let {class_info} = this.state;

        return (
            <PageContent title={'班级管理'}>
                <Card bordered={false}>
                    <PageHeader
                        onBack={() => window.history.back()}
                        title={<span>{class_info.name}{class_info.status === 1 && <Tag color="red">已结课</Tag>}</span>}
                        extra={[
                            <Button key="1" type="primary" onClick={() => this.setState({editRecord: class_info})}>
                                修改
                            </Button>,
                        ]}
                        style={{padding: 0}}
                    >
                        <Descriptions size="small" column={3}>
                            <Descriptions.Item label="讲师">{class_info.teacher_name}</Descriptions.Item>
                            <Descriptions.Item label="课程">{class_info.course_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="创建于">{moment(class_info.create_on).format("YYYY-MM-DD HH:mm")}</Descriptions.Item>
                            <Descriptions.Item label="标签">{class_info.tags ?
                                <TagsDisplay tags={JSON.parse(class_info.tags)}/> : null}</Descriptions.Item>
                        </Descriptions>
                        <div>
                            学生：{class_info.students.map(student =>
                            <Link to={`/affairs/students/${student.id}`}>
                                <Tag>{student.nick_name}</Tag>
                            </Link>)}
                        </div>
                        <div className="wrap">
                            <div className="content padding">
                                备注：{class_info.remark}
                            </div>
                        </div>
                    </PageHeader>

                    <div>
                        <LessonProgress progress={class_info.progress}/>
                    </div>

                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab={`课程安排(${class_info.courses.length})`} key="1">
                            <Button type="primary" icon={<PlusOutlined/>}
                                    onClick={() => this.setState({showCourseAddModal: true})}>批量添加课程</Button>

                            <Table dataSource={class_info.courses}
                                   rowKey={"id"}
                                   pagination={false}
                                   size={"small"}
                                   scroll={{x: 1300}}
                            >
                                <Column
                                    title="编号"
                                    width={50}
                                    dataIndex="order_index"
                                    key="order_index"
                                    fixed="left"
                                />
                                <Column
                                    title="课时名称"
                                    dataIndex="name"
                                    key="name"
                                    fixed="left"
                                    width={100}
                                />
                                <Column
                                    title="状态"
                                    dataIndex="status"
                                    key="status"
                                    width={60}
                                    fixed="left"
                                    render={(status, record) => (
                                        <span>
                                        {status === 1 && <span style={{color: "green"}}>已完成</span>}
                                            {status === 0 && "未完成"}
                                    </span>
                                    )}
                                />

                                <Column
                                    title="排课时间"
                                    dataIndex="course_time"
                                    key="course_time"
                                    width={140}
                                    fixed="left"
                                    render={(course_time, record) => (
                                        <div>
                                            {record.course_time &&
                                                <span>{moment(record.course_time).format("YYYY-MM-DD ddd HH:mm")}</span>}
                                            {record.course_end_time &&
                                                <span>{moment(record.course_end_time).format(" - HH:mm")}</span>}

                                        </div>
                                    )}
                                    defaultSortOrder='descend'
                                    sorter={(a, b) => moment(a.course_time).isBefore(b.course_time) ? -1 : 1}
                                />

                                {/*<Column*/}
                                {/*title="教室"*/}
                                {/*dataIndex="room_id"*/}
                                {/*key="room_id"*/}
                                {/*width={110}*/}
                                {/*render={(room_id) => <RoomName id={room_id} />}*/}
                                {/*/>*/}
                                {/*<Column*/}
                                {/*title="点名时间"*/}
                                {/*dataIndex="check_time"*/}
                                {/*key="check_time"*/}
                                {/*width={110}*/}
                                {/*render={(check_time) => {*/}
                                {/*return check_time ? moment(check_time).format("YYYY-MM-DD HH:mm") : ""*/}
                                {/*}}*/}
                                {/*/>*/}

                                <Column
                                    title="课时"
                                    dataIndex="keshi"
                                    key="keshi"
                                    width={50}
                                    // render={(keshi) => (
                                    //     <span>
                                    //     {keshi}课时
                                    // </span>
                                    // )}
                                />
                                <Column
                                    title="到课"
                                    dataIndex="daoke"
                                    key="daoke"
                                    width={50}
                                    render={(daoke, record) => (
                                        <a onClick={() => this.setState({dianmingDetailID: record.id})}>
                                            {daoke}人
                                        </a>
                                    )}
                                />
                                <Column
                                    title="点评"
                                    key="点评"
                                    width={50}
                                    render={(record) => {
                                        if (record.lus) {
                                            let lus = record.lus.filter(item => item.status === 1);
                                            let c = lus.length;
                                            if (c === record.daoke) {
                                                return <span
                                                    style={{color: "green"}}>{lus.length}/{record.daoke}</span>;
                                            }
                                            return <span style={{color: "red"}}>{lus.length}/{record.daoke}</span>;
                                        }
                                        return <span style={{color: "red"}}>0/{record.daoke}</span>;
                                    }}
                                />


                                <Column
                                    title="讲师"
                                    dataIndex="teacher_name"
                                    key="teacher_name"
                                    width={60}
                                />

                                {/*<Column*/}
                                {/*title="关联课程"*/}
                                {/*dataIndex="classes_project_name"*/}
                                {/*key="classes_project_name"*/}
                                {/*/>*/}
                                <Column
                                    title="课程"
                                    key="课程"
                                    render={(record) => {
                                        return (<div>
                                            {record.course_name} / {record.classes_project_name}
                                        </div>)
                                    }}
                                />


                                <Column
                                    title="备注"
                                    dataIndex="remark"
                                    key="remark"
                                />


                                <Column
                                    title="操作"
                                    key="action"
                                    width={197}
                                    fixed="right"
                                    render={(text, record) => {
                                        let currenttime = moment()
                                            .day(moment(record.course_time).day())
                                            .hour(moment(record.course_time).hour())
                                            .minute(moment(record.course_time).minute())
                                            .second(moment(record.course_time).second());
                                        return (
                                            <div style={{whiteSpace: "nowrap"}}>
                                                <a href="javascript:void(0)" onClick={() => {
                                                    this.setState({editCourse: {...record}})
                                                }}>修改</a>

                                                {record.status === 0 && <>
                                                    <Divider type="vertical"/>
                                                    <a href="javascript:void(0)" onClick={() => {
                                                        this.setState({
                                                            dianmingCourse: {
                                                                ...record,
                                                                time: record.course_time
                                                            }
                                                        })
                                                    }}>点名</a>
                                                    <Divider type="vertical"/>
                                                    <Popconfirm title='确定要删除该课程吗？'
                                                                onConfirm={() => this.handleCourseRemove(record)}
                                                                okText="确定"
                                                                cancelText="取消"
                                                    >
                                                        <a href="javascript:;">删除</a>
                                                    </Popconfirm>
                                                </>}
                                                {record.course_time && <>
                                                    <Divider type="vertical"/>
                                                    <Dropdown overlay={
                                                        <Menu>
                                                            <Menu.Item>
                                                                <a rel="noopener noreferrer" href="javascript:void(0)"
                                                                   onClick={() => this.handleCopy(record.id, moment(record.course_time).add(7, "d").format("YYYY-MM-DD"))}>
                                                                    {moment(record.course_time).add(7, "d").format("YYYY-MM-DD HH:mm dddd")}
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <a rel="noopener noreferrer" href="javascript:void(0)"
                                                                   onClick={() => this.handleCopy(record.id, currenttime.format("YYYY-MM-DD"))}>
                                                                    {currenttime.format("YYYY-MM-DD HH:mm dddd")}
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <a rel="noopener noreferrer" href="javascript:void(0)"
                                                                   onClick={() => this.handleCopy(record.id, moment(currenttime).add(7, "d").format("YYYY-MM-DD"))}>
                                                                    {moment(currenttime).add(7, "d").format("YYYY-MM-DD HH:mm dddd")}
                                                                </a>
                                                            </Menu.Item>
                                                        </Menu>}>

                                                        <a href="javascript:void(0)">复制 <DownOutlined/></a>

                                                    </Dropdown>
                                                </>}


                                                {/*<a href="javascript:void(0)" onClick={() => this.showEditModal(record)}>重置密码</a>*/}
                                                {/*<Divider type="vertical"/>*/}
                                                {/*<Link to={`/student/${record.id}`}>学情报告</Link>*/}
                                                {/*<React.Fragment>*/}
                                                {/*<Divider type="vertical"/>*/}
                                                {/*<Popconfirm title='确定将该学生移出班级吗？'*/}
                                                {/*onConfirm={() => this.handleRemove(record)}*/}
                                                {/*okText="确定"*/}
                                                {/*cancelText="取消"*/}
                                                {/*>*/}
                                                {/*<a href="javascript:;">移出班级</a>*/}
                                                {/*</Popconfirm>*/}
                                                {/*</React.Fragment>*/}

                                            </div>
                                        );
                                    }}
                                />
                            </Table>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`班级学生(${class_info.students.length})`} key="2">
                            <Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}
                                    style={{marginBottom: 10}}>关联学生</Button>
                            <StudentList list={class_info.students} classes_id={class_info.id}
                                         onChange={() => this.loadClasses()}/>


                        </Tabs.TabPane>
                    </Tabs>

                    {this.state.showCourseAddModal &&
                        <LessonAddModal
                            team_id={this.state.class_info.id}
                            onOk={() => {
                                this.loadClasses();
                                this.setState({
                                    showCourseAddModal: false
                                })
                            }}
                            onCancel={() => {
                                this.setState({
                                    showCourseAddModal: false
                                })
                            }}
                        />
                    }
                    {/*<Modal*/}
                    {/*    title="批量添加课程"*/}
                    {/*    visible={this.state.showCourseAddModal}*/}
                    {/*    onOk={this.handleCourseAdd}*/}
                    {/*    onCancel={() => this.setState({showCourseAddModal: false})}*/}
                    {/*    confirmLoading={this.state.CourseAddModalConfirmLoading}*/}
                    {/*    okText="确认"*/}
                    {/*    cancelText="取消"*/}
                    {/*>*/}
                    {/*    <Form>*/}

                    {/*        <Form.Item label={"课程名称（一行一个课程名）"}>*/}
                    {/*            <Input.TextArea placeholder="请输入课程名称"*/}
                    {/*                            autosize={{minRows: 10}}*/}
                    {/*                            value={this.state.courseAddNames}*/}
                    {/*                            onChange={(e) => {*/}
                    {/*                                this.setState({courseAddNames: e.target.value})*/}
                    {/*                            }}/>*/}

                    {/*        </Form.Item>*/}
                    {/*    </Form>*/}
                    {/*    {this.state.editTitleError && <Alert message={this.state.editTitleError} type="error"/>}*/}
                    {/*</Modal>*/}

                    {!!this.state.editCourse &&
                        <LessonEditModal
                            data={this.state.editCourse}
                            onCancel={() => this.setState({editCourse: null})}
                            onOk={() => {
                                this.setState({editCourse: null});
                                this.loadClasses()
                            }}
                        />
                    }

                    {!!this.state.dianmingCourse &&
                        <DianMingModal id={this.state.dianmingCourse.id}
                                       students={this.state.class_info.students}
                                       onOk={() => {
                                           this.setState({
                                               CourseAddModalConfirmLoading: false,
                                               dianmingCourse: null
                                           });
                                           this.loadClasses()
                                       }}
                                       onCancel={() => {
                                           this.setState({dianmingCourse: null})
                                       }}
                        />
                    }

                    {this.state.dianmingDetailID > 0 &&
                        <DianmingDetail
                            id={this.state.dianmingDetailID}
                            onCancel={() => this.setState({dianmingDetailID: 0})}
                        />
                    }


                    {this.state.editRecord &&
                        <ClassesEditModal
                            onOk={() => {
                                this.loadClasses();
                                this.setState({editRecord: null})
                            }}
                            onCancel={() => {
                                this.setState({editRecord: null})
                            }}
                            data={this.state.editRecord}
                        />
                    }

                </Card>
                {this.state.showAddModal &&
                    <StudentAddModal
                        onOk={() => {
                            this.loadClasses();
                            this.hideAddModal()
                        }}
                        onCancel={this.hideAddModal}
                        visible={this.state.showAddModal}
                        team_id={this.props.id}
                    />
                }

            </PageContent>
        );
    }
}


ClassDetail.propTypes = {
    id: PropTypes.number.isRequired,
};

export default withRouter(ClassDetail);
