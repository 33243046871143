import React from 'react';
import {Form, Input} from 'antd';
import MdEditor from '../../../components/md-editor/md-editor';

/**
 * 任务的表单
 * @param form  form 引用
 * @param onFinish
 * @param initialValues
 * @returns {JSX.Element}
 * @constructor
 */
const TaskForm = ({form, onFinish, initialValues}) => {
    return (
        <Form
            labelCol={{flex: '110px'}}
            wrapperCol={{flex: 1}}
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Form.Item name={"title"} label={"任务标题"}>
                <Input/>
            </Form.Item>
            <Form.Item name={"content"} label={"任务描述"}>
                <MdEditor/>
            </Form.Item>
        </Form>
    )
};


export default TaskForm;