import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Input, Form, Radio, Row, Col, Button, InputNumber, Space, message, Card, Checkbox} from 'antd';
import MdEditor from '../../../components/md-editor/md-editor';
import {postQuestion, getQuestion} from "../../../lib/api_questions";
import {getPaper, putPaper} from "../../../lib/api_papers";
import MD from '../questions/md';
import styles from '../questions/examination_modal.module.css';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from '../questions/SortableItem';
import PageContent from "../../../components/page-content/page-content";
import QuestionsSelectModal from "./questions_select_modal";
import {EditOutlined, EyeOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";


const ExaminationModal = ({id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [config, setconfig] = useState({
        title: "试卷标题",
        desc: "试卷说明",
        score: 0,
        groups: []
    });
    const [showQuestion, setShowQuestion] = useState(false);
    const [mode, setMode] = useState(0);

    /**
     * 下载试卷配置
     */
    const fetchData = () => {
        getPaper(id)
            .then(res => {
                if (res.code === 0) {
                    setconfig(JSON.parse(res.data.content))
                }
            })
    }

    useEffect(() => {
        fetchData()
    }, []);

    /**
     * 重新编号
     */
    const reBuild = () => {
        let index = 1;
        for (let i in config.groups) {
            let group = config.groups[i];
            for (let j in group.questions) {
                group.questions[j].id = index;
                index += 1;
            }
        }

        setconfig({...config});
    };

    /**
     * 重新加载题目
     * @param item
     */
    const handleReload = (item) => {
        getQuestion(item.qid).then(res => {
            if (res.code === 0) {
                // console.log(item);
                // console.log(res.data);
                item.answer = res.data.answer;
                item.content = res.data.content;
                reBuild();
                // setconfig({...config});
            }
        })
    }

    /**
     * 删除题目
     * @param item
     */
    const handleDelete = (item) => {
        for (let i in config.groups) {
            let group = config.groups[i];
            for (let j in group.questions) {
                if (group.questions[j].id === item.id) {
                    group.questions.splice(j, 1);
                    reBuild();
                    return;
                }
            }
        }
        // setconfig({...config});
    }

    /**
     * 批量添加题目
     */
    const handleAddQuestions = async (ids) => {
        console.log(ids);
        for (let i in ids) {
            await handleAddQuestion(ids[i]);
        }
        reBuild();
    }

    /**
     * 添加题目
     */
    const handleAddQuestion = async (id) => {
        let res = await getQuestion(id);
        if (res.code === 0) {
            //查找分组，添加到最后
            for (let i in config.groups) {
                let group = config.groups[i];
                if (group.type === res.data.type) {
                    group.questions.push({
                        id: group.questions.length + 1,
                        qid: id,
                        type: res.data.type,
                        answer: res.data.answer,
                        content: res.data.content,
                        score: group.score_avg
                    });
                    return;
                }
            }
            //没有分组，新建分组
            config.groups.push({
                type: res.data.type,
                title: `${res.data.type}（共1题，每题2分）`,
                score: 2,
                score_avg: 2,
                questions: [{
                    id: 1,
                    qid: id,
                    type: res.data.type,
                    answer: res.data.answer,
                    content: res.data.content,
                    score: 2
                }]
            })
        }
    }

    /**
     * 分数改变
     * @param item
     * @param score
     */
    const handleChangeScore = (item, score) => {
        item.score = score;
        setconfig({...config});
    };

    /**
     * 列数改变
     * @param item
     * @param col
     */
    const handleChangeCol = (item, col) => {
        console.log(col);
        item.col = col;
        setconfig({...config});
    };

    /**
     * 批量修改分数
     * @param group
     */
    const handleChangeScoreMuti = (group) => {
        for (let i in group.questions) {
            group.questions[i].score = group.score_avg;
        }
        setconfig({...config});
    };

    /**
     * 刷新标题
     * @param group
     */
    const handleRefrushTitle = (group) => {
        let score = 0;
        for (let i in group.questions) {
            score += group.questions[i].score;
        }
        group.score = score;
        group.title = `${group.type}（共${group.questions.length}题，每题${group.score_avg}分，共${group.score}分）`;
        // setconfig({...config});
        handleStatScore()
    };

    /**
     * 统计分数
     */
    const handleStatScore = () => {
        let score = 0;
        for (let i in config.groups) {
            score += config.groups[i].score
        }
        config.score = score;
        setconfig({...config});
    };

    /**
     * 渲染题目
     * @param item
     * @returns {JSX.Element}
     */
    const renderItem = (item) => {
        switch (item.type) {
            case "单选题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={config.title}></MD>
                        {mode === 0 &&
                            <>
                                <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                             addonAfter={"分"} style={{width: 100}}/>
                                <Button onClick={() => handleReload(item)}>重新加载</Button>
                                <Radio.Group value={item.col ? item.col : 1}
                                             onChange={(e) => handleChangeCol(item, e.target.value)}
                                             buttonStyle="solid">
                                    <Radio.Button value={1}>1列</Radio.Button>
                                    <Radio.Button value={2}>2列</Radio.Button>
                                    <Radio.Button value={3}>3列</Radio.Button>
                                    <Radio.Button value={4}>4列</Radio.Button>
                                </Radio.Group>
                                <Button onClick={() => handleDelete(item)} danger>删除</Button>
                            </>
                        }
                        {/*{config.title}*/}
                    </div>
                    <div>
                        <Radio.Group value={answer.answer}>
                            <Row>
                                {config.options.map(option => (
                                    <Col span={item.col ? 24 / item.col : 24}>
                                        <Radio value={option.key} rootClassName={styles.checkbox}>
                                            <div className={styles.options}>
                                                <span>{option.key}.</span>
                                                <MD value={option.value}/>
                                            </div>
                                        </Radio>
                                    </Col>
                                ))}
                            </Row>
                        </Radio.Group>
                    </div>
                </div>)
            }
            case "多选题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={config.title}></MD>
                        {mode === 0 &&
                            <>
                                <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                             addonAfter={"分"} style={{width: 100}}/>
                                <Button onClick={() => handleReload(item)}>重新加载</Button>
                                <Radio.Group value={item.col ? item.col : 1}
                                             onChange={(e) => handleChangeCol(item, e.target.value)}
                                             buttonStyle="solid">
                                    <Radio.Button value={1}>1列</Radio.Button>
                                    <Radio.Button value={2}>2列</Radio.Button>
                                    <Radio.Button value={3}>3列</Radio.Button>
                                    <Radio.Button value={4}>4列</Radio.Button>
                                </Radio.Group>
                                <Button onClick={() => handleDelete(item)} danger>删除</Button>
                            </>
                        }
                    </div>
                    <div>
                        <Checkbox.Group value={answer.answer ? answer.answer.split('') : []}>
                            <Row>
                                {config.options.map(option => (
                                    <Col span={item.col ? 24 / item.col : 24}>
                                        <Checkbox value={option.key} rootClassName={styles.checkbox}>
                                            <div className={styles.options}>
                                                <span>{option.key}.</span>
                                                <MD value={option.value}/>
                                            </div>
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </div>
                </div>)
            }
            case "判断题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                        <div className={styles.title}>
                            <span>{item.id}.</span>
                            <MD value={config.title}></MD>
                            {mode === 0 &&
                                <>
                                    <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                                 addonAfter={"分"} style={{width: 100}}/>
                                    <Button onClick={() => handleReload(item)}>重新加载</Button>
                                    <Button onClick={() => handleDelete(item)} danger>删除</Button>
                                </>
                            }
                            {/*{config.title}*/}
                        </div>
                        <div>

                            <Radio.Group value={answer.answer}>
                                <Space direction="vertical">
                                    <Radio value={"正确"} rootClassName={styles.checkbox}>
                                        <div className={styles.options}>
                                            正确
                                        </div>
                                    </Radio>
                                    <Radio value={"错误"} rootClassName={styles.checkbox}>
                                        <div className={styles.options}>
                                            错误
                                        </div>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                            {/*{config.options.map(option=>(*/}
                            {/*<div className={styles.options}>*/}
                            {/*<span>{option.key}.</span>*/}
                            {/*<MD value={option.value} />*/}
                            {/*</div>*/}
                            {/*))}*/}
                        </div>
                    </div>
                )
            }
            case "python": {
                let config = JSON.parse(item.content);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <div style={{flex: 1}}>
                            {mode === 0 &&
                                <>
                                    <Button onClick={() => handleReload(item)}>重新加载</Button>
                                    <Button onClick={() => handleDelete(item)} danger>删除</Button>
                                    <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                                 addonAfter={"分"} style={{width: 100}}/>
                                </>
                            }
                            <MD value={config.content}></MD>
                        </div>
                        {/*{config.title}*/}
                    </div>
                    <div>
                        <div>评分标准：</div>
                        <MD value={config.pfbz}></MD>
                    </div>
                    <div>
                        <Input.TextArea/>
                    </div>
                </div>)
            }
            case "scratch": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <div style={{flex: 1}}>
                            {mode === 0 &&
                                <>
                                    <Button onClick={() => handleReload(item)}>重新加载</Button>
                                    <Button onClick={() => handleDelete(item)} danger>删除</Button>
                                    <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                                 addonAfter={"分"} style={{width: 100}}/>
                                </>
                            }
                            <MD value={config.content}></MD>
                        </div>

                        {/*{config.title}*/}
                    </div>
                    <div style={{textAlign: "center"}}>

                        <a href={`https://xmybc.com/scratch/projects/${config.scratch_id}`} target="_blank">
                            <Button type={"primary"} size={"large"}>去改编</Button>
                        </a>
                    </div>
                </div>)
            }
            default:
                return (
                    <div>
                        {item.content}
                    </div>
                )
        }

    };

    /**
     * 渲染分组
     * @param group
     * @returns {JSX.Element}
     */
    const renderGroup = (group) => {
        return (<div className={styles.group}>
            <div className={styles.groupTitle}>
                {mode === 0
                    ? <Space>
                        <Input.Group compact>
                            <Input value={group.title} onChange={(e) => {
                                group.title = e.target.value;
                                setconfig({...config})
                            }} size="large" style={{width: 400}}/>
                            <Button size="large" onClick={() => handleRefrushTitle(group)}>刷新</Button>
                        </Input.Group>
                        <Input.Group compact>
                            <InputNumber size="large" addonAfter={"分"} style={{width: 100}}
                                         value={group.score_avg}
                                         onChange={(value) => {
                                             group.score_avg = value;
                                             setconfig({...config})
                                         }}
                            />
                            <Button size="large" onClick={() => handleChangeScoreMuti(group)}>批量设置</Button>

                        </Input.Group>
                    </Space>
                    : <Space>
                        <div>{group.title}</div>
                    </Space>
                }

            </div>
            <div>
                {group.questions.map(renderItem)}
            </div>
        </div>)
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const handleDragEnd = (event, group) => {
        const {active, over} = event;

        console.log(event)

        if (active.id !== over.id) {
            let items = group.questions;
            const oldIndex = items.findIndex((item) => {
                return active.id === item.id
            });
            const newIndex = items.findIndex((item) => {
                return over.id === item.id
            });
            group.questions = arrayMove(items, oldIndex, newIndex)
            console.log(group.questions, oldIndex, newIndex)

            // setconfig({...config});
            reBuild();

            // setItems((items) => {
            //     const oldIndex = items.indexOf(active.id);
            //     const newIndex = items.indexOf(over.id);
            //
            //     return arrayMove(items, oldIndex, newIndex);
            // });
        }
    };

    const handleSave = () => {
        console.log(config);
        putPaper({
            id: id,
            title: config.title,
            content: JSON.stringify(config),
            score: config.score,
        }).then(result => {
            if (result.code === 0) {
                onOk && onOk();
                message.success("生成成功！")
            }

        })
    }
    return (
        <PageContent title={"试卷编辑"}>
            <Card bordered={false}>
                <Row>
                    <Col flex={"200px"}>
                        <div style={{position: "fixed", top: 100, width: 200}}>
                            {config.groups.map(group => (
                                <div>
                                    <div>{group.type}</div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={event => handleDragEnd(event, group)}
                                    >
                                        <SortableContext
                                            items={group.questions}
                                            strategy={rectSortingStrategy}
                                        >
                                            {group.questions.map(item => <SortableItem key={item.id} id={item.id}
                                                                                       data={item}/>)}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            ))}
                            <div>
                                试卷总分：{config.score}
                            </div>
                            <Space direction={"vertical"}>
                                <Button onClick={() => setShowQuestion(true)} icon={<PlusOutlined/>}>添加题目</Button>
                                {mode === 0
                                    ? <Button onClick={() => {
                                        setMode(1)
                                    }} icon={<EditOutlined/>}>编辑模式</Button>
                                    : <Button onClick={() => {
                                        setMode(0)
                                    }} icon={<EyeOutlined/>}>预览模式</Button>
                                }
                                <Button onClick={handleSave} icon={<SaveOutlined/>}>保存试卷</Button>
                            </Space>
                        </div>
                    </Col>
                    <Col flex={"1"} style={{overflow: "auto"}}>
                        {mode === 0
                            ? <div>
                                试卷标题：
                                <Input value={config.title} onChange={(e) => {
                                    config.title = e.target.value;
                                    setconfig({...config});
                                }}/>
                                试卷说明：
                                {/*<MdEditor />*/}
                                <MdEditor
                                    value={config ? config.desc : ""}
                                    onChange={(value) => {
                                        config.desc = value;
                                        setconfig({...config});
                                    }}/>
                            </div>
                            : <div>
                                <div className={styles.paperTitle}>{config.title}</div>
                                <div className={styles.paperDesc}>{config.desc}</div>
                            </div>
                        }

                        {config.groups.map(renderGroup)}
                    </Col>
                </Row>
            </Card>
            {showQuestion &&
                <QuestionsSelectModal
                    onOk={(ids) => {
                        setShowQuestion(false);
                        handleAddQuestions(ids);
                    }}
                    onCancel={() => {
                        setShowQuestion(false);
                    }}
                />}
        </PageContent>
    )
};


ExaminationModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default ExaminationModal;