import React from 'react';
import {Form, Input} from "antd";
import {Colorpicker} from 'antd-colorpicker'

/**
 * 比赛题目表单
 * @param form
 * @param onFinish
 * @param initialValues
 * @returns {JSX.Element}
 * @constructor
 */
const ContestProblemForm = ({form, onFinish, initialValues}) => {

    return (
        <Form
            labelCol={{flex: '110px'}}
            wrapperCol={{flex: 1}}
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Form.Item name="pid" label="题目id" rules={[{
                required: true,
                message: '请输入${label}！',
            },]}>
                <Input/>
            </Form.Item>
            <Form.Item name="display_id" label="展示的id" rules={[{
                required: true,
                message: '请输入${label}！',
            },]}>
                <Input/>
            </Form.Item>
            <Form.Item name="display_title" label="展示标题" rules={[{
                required: true,
                message: '请输入${label}！',
            },]}>
                <Input/>
            </Form.Item>
            <Form.Item name="color" label="气球颜色">
                <Colorpicker popup onColorResult={(color) => color.hex}/>
            </Form.Item>
        </Form>)
}

export default ContestProblemForm;