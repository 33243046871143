import React, {useState, useEffect} from 'react';
import {Card, Table, Statistic, Row, Col} from 'antd';
import moment from 'moment';
import styles from './card-card.module.css';
import KeShiListModal from './keshi-list-modal';
import CardEditModal from './card-edit-modal';
import {getCards} from "../../../lib/api_students";
import {Link, withRouter} from 'react-router-dom';
import {useAccountStore} from "../../../stores/useAccountStore";

const {Column} = Table;

const CardCardWrapper = ({user_id}) => {
    const {account} = useAccountStore();
    const [cards, setCards] = useState([]);
    const fetchData = () => {
        console.log(account)
        getCards(user_id)
            .then(res => {
                if (res.code === 0) {
                    setCards(res.data)
                }
            })
    };
    useEffect(fetchData, []);
    return (<CardCard cards={cards} onRefrush={fetchData} account={account}/>)
}

const CardCard = ({cards, onRefrush,account}) => {
    const [card, setCard] = useState(null);
    const [editData, setEditData] = useState(null);

    if (!cards) return null;

    // 计算总额
    let t_keshi_original = 0;
    let t_keshi_remaining = 0;
    let t_payments = 0;
    let t_payments_remaining = 0;
    cards.forEach(card => {
        t_keshi_original += card.keshi_original;
        t_keshi_remaining += card.keshi_remaining;
        t_payments += card.payments;
        t_payments_remaining += card.payments_remaining;
    });

    // console.log(match.params.type_id);
    return (
        <Card title={"支付"} size={"small"}>
            <Row justify="space-between">
                <Col>
                    <Statistic title="总课时" value={t_keshi_original} valueStyle={{fontSize: 20}}/>
                </Col>
                <Col>
                    <Statistic title="剩余课时" value={t_keshi_remaining} valueStyle={{fontSize: 20}}/>
                </Col>
                <Col>
                    {account.login_name !== "admin" &&
                    <Statistic title="总支付" value={t_payments} precision={2} prefix={"¥"} valueStyle={{fontSize: 20}}/>
                    }
                </Col>
                <Col>
                    {account.login_name !== "admin" &&
                    <Statistic title="余额" value={t_payments_remaining} precision={2} prefix={"¥"}
                               valueStyle={{fontSize: 20}}/>
                    }
                </Col>
            </Row>
            <Table dataSource={cards}
                   rowKey={"id"}
                   pagination={false}
                   size={"small"}
                   rowClassName={record => {
                       if (record.keshi_remaining === 0 && record.payments_remaining === 0) {
                           return styles["disabled"]
                       }
                       return "";
                   }}
            >
                <Column
                    title="排序"
                    dataIndex="order_index"
                    key="排序"
                />
                <Column
                    title="办理时间"
                    dataIndex="createdAt"
                    key="办理时间"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD")}
                />
                <Column
                    title="课程"
                    dataIndex="name"
                    key="课程"
                />
                <Column
                    title="课时"
                    key="课时"
                    render={(record) => {
                        return <a onClick={() => setCard(record)}>{record.keshi_remaining}/{record.keshi_original}</a>
                    }}
                />
                {account.login_name !== "admin" &&
                <Column
                    title="余额（元）"
                    key="余额"
                    render={(record) => {
                        return `${record.payments_remaining}/${record.payments}`
                    }}
                />
                }
                <Column
                    title="均价"
                    key="均价"
                    dataIndex="payments_avg"
                />
                <Column
                    title="操作"
                    key="操作"
                    render={(record) => <a onClick={() => setEditData(record)}>修改</a>}
                />
            </Table>
            {editData && <CardEditModal
                data={editData}
                visible={!!editData}
                onOk={() => {
                    setEditData(null)
                    onRefrush && onRefrush()
                }}
                onCancel={() => {
                    setEditData(null)
                }}

            />}
            {card &&
            <KeShiListModal
                card={card}
                visible={!!card}
                onOk={() => {
                    setCard(null);
                    onRefrush && onRefrush()
                }}
                onCancel={() => {
                    setCard(null);
                    onRefrush && onRefrush()
                }}
            />
            }

        </Card>
    )
};


// export default CardCardWrapper;




export default withRouter(CardCardWrapper);
