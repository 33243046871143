import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Input, message, Modal, Form} from 'antd';
import {postCoursesLessons} from "../../../lib/api_courses_lessons";

const LessonsAddModal = ({visible, id, onOk, onCancel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                setLoading(true);
                values.id = id;
                postCoursesLessons(values)
                    .then(result => {
                        if (result.code === 0) {
                            handleOK();
                        } else {
                            message.error(result.message);
                        }
                    })
                    .catch(err => {
                        message.error(err.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
    };
    const handleClose = () => {
        onCancel && onCancel();
    };

    const handleOK = () => {
        onOk && onOk();
    };

    return (
        <Modal
            title="批量添加课时"
            open={visible}
            onOk={handleSubmit}
            onCancel={handleClose}
            confirmLoading={loading}
            destroyOnClose={true}
            width={700}
        >
            <Form form={form}>
                <Form.Item label={"课时名称"}
                           name="names"
                           help={"一行一个名称"}
                           rules={[{
                               required: true,
                               message: '请输入课时名称',
                           }]}>
                    <Input.TextArea autosize={{minRows: 5}}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}


LessonsAddModal.propTypes = {
    visible: PropTypes.bool,
    id: PropTypes.number.isRequired,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default LessonsAddModal;

