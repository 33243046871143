import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Input, Form,Radio} from 'antd';
import FormScratch from './form_scratch';
import {postQuestion} from "../../../lib/api_questions";
import FormScratchAnswer from "./form_scratch_answer";


const layout = {
    labelCol: {flex: "110px"},
    wrapperCol: {flex: "1"},
};


const QuestionAddModal = ({visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.type = "scratch";
                postQuestion(values)
                // values.password = md5(values.password);
                // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };




    return (
        <Modal
            title="添加Scratch编程题"
            open={visible}
            onCancel={onCancel}
            onOk={handleOk}
            width={1000}
        >
            <Form
                {...layout}
                form={form}
            >
                <Form.Item
                    label="摘要标题"
                    name="title"
                    rules={[{
                        required: true,
                        message: '请输入摘要标题'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="题目配置"
                    name="content"
                    rules={[{
                        required: true,
                        message: '请输入题目配置'
                    }]}
                >
                    <FormScratch/>
                </Form.Item>
                <Form.Item
                    label="答案配置"
                    name="answer"
                    rules={[{
                        required: true,
                        message: '请输入答案配置'
                    }]}
                >
                    <FormScratchAnswer/>
                </Form.Item>

            </Form>
        </Modal>
    )
};


QuestionAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default QuestionAddModal;