import React from 'react';
import {Form, Input, InputNumber} from 'antd';
import AliyunOSSUpload from '../../../components/aliyun-oss-upload';
import DictSelect from '../../../components/dict/dict-radio-group';
import default_form_item_layout from "../../../config/default_form_item_layout";

const CourseEditForm = ({form, onFinish, initialValues}) => {
    return (
        <Form {...default_form_item_layout}
              form={form}
              onFinish={onFinish}
              initialValues={initialValues}
        >
            <Form.Item name="name" label={"课程名称"} rules={[
                {
                    required: true,
                    message: '请输入课程名称',
                }
            ]}>
                <Input/>
            </Form.Item>
            <Form.Item name={"description"} label={"课程描述"}>
                <Input.TextArea autosize={{minRows: 5}}/>
            </Form.Item>
            <Form.Item name={"type"} label={"课程类型"} rules={[
                {
                    required: true,
                    message: '请选择课程类型',
                }
            ]}>
                <DictSelect type_value={"course_type"}/>
            </Form.Item>
            <Form.Item name={"keshi"} label={"总课时数"} rules={[
                {
                    required: true,
                    message: '请输入总课时数',
                }
            ]}>
                <InputNumber min={0}/>
            </Form.Item>
            <Form.Item name={"duration"} label={"上课时长（分钟）"} rules={[
                {
                    required: true,
                    message: '请输入上课时长',
                }
            ]}>
                <InputNumber min={0}/>
            </Form.Item>
            <Form.Item name={"image_url"} label="封面图">
                <AliyunOSSUpload multiple={false}/>
            </Form.Item>
        </Form>
    )
};


export default CourseEditForm;