import axios from './rest-axios';

const getQuestions = (state) => axios.get(`/v1/questions`,state);
const getQuestion = (id) => axios.get(`/v1/questions/${id}`);
const postQuestion = (model) => axios.post(`/v1/questions`, model);
const putQuestion = (model) => axios.put(`/v1/questions/${model.id}`, model);
const delQuestion = (ids) => axios.post(`/v1/questions/delete`, {ids});
const postQuestionTags = (ids, tags) => axios.post(`/v1/questions/tags`, {ids, tags});


export {
    getQuestions,
    getQuestion,
    postQuestion,
    putQuestion,
    delQuestion,
    postQuestionTags,
};
