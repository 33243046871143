import React, { useEffect, useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Table, Card} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {listDocuments, postDocument, putDocument} from "../../../lib/api_documents";
import EasyFormModal from '../../../components/easy_form_modal';
import DocumentForm from './document_form';

/**
 * 文档列表
 * @returns {JSX.Element}
 * @constructor
 */
const Documents = () => {
    const [list, setList] = useState([]);
    const [addModal, setAddModal] = useState(null);
    const [editRecord, setEditRecord] = useState(null);

    const fetchData = () => {
        listDocuments()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                }
            })
    };

    useEffect(fetchData, []);

    const columns=[
        {
            title:"编号",
            dataIndex:"id",
            key:"id"
        },
        {
            title: '名称',
            dataIndex: 'title',
            key: 'title',
            render: (title, record) => (
                <div>
                    <a onClick={() => {
                        setEditRecord(record);
                    }}>{title}</a>
                </div>
            )
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <div>
                    <a onClick={() => {
                        setEditRecord(record);
                    }}>修改</a>
                </div>
            )
        }
    ]

    return (
        <PageContent title={"文档管理"}>
            <Card bordered={false}>
                <Button type='primary' icon={<PlusOutlined/>}
                        onClick={() => setAddModal({})}
                        style={{marginBottom: 10}}>添加文档</Button>

                <Table dataSource={list}
                       rowKey={"id"}
                       pagination={false}
                       size={"small"}
                       columns={columns}
                />

                {!!addModal && <EasyFormModal
                    data={addModal}
                    title={"添加文档"}
                    onOk={() => {
                        setAddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setAddModal(false);
                    }}
                    formComponent={DocumentForm}
                    restFunc={postDocument}
                    width={1000}
                />}
                {!!editRecord && <EasyFormModal
                    data={editRecord}
                    title={"修改文档"}
                    onOk={() => {
                        setEditRecord(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        setEditRecord(null);
                    }}
                    formComponent={DocumentForm}
                    restFunc={putDocument}
                    width={1000}
                />}
            </Card>
        </PageContent>
    );
};

export default Documents;


