import PropTypes from 'prop-types';
import {useAccountStore} from "../../stores/useAccountStore";

const DictName = ({value, type_value}) => {
    const {account} = useAccountStore();
    if (!account) return "";
    let dict_types = account.dict_types;
    if (!dict_types) return "";
    if (value == null) return "";
    if (typeof value === undefined) return "";

    // if (!value) return "";
    const dict_type = dict_types.find(_ => _.value === type_value);
    if (dict_type) {
        let dict = dict_type.sys_dicts.find(_ => _.value === value.toString());
        if (dict) {
            return dict.key;
        }
    }
    return "";
};

DictName.propTypes = {
    value: PropTypes.string,
    type_value: PropTypes.string,
};

export default DictName;


