import React, {useEffect, useRef} from 'react';
import MarkdownIt from 'markdown-it';
import Editor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import {getSTSTestcases} from "../../lib/api_sts";
import AliOSS from "ali-oss";
import SparkMD5 from "spark-md5";

const mdParser = new MarkdownIt(/* Markdown-it options */);

const mathjax3 = require('markdown-it-mathjax3');
mdParser.use(mathjax3);

const MdEditor = ({value, onChange, toolbar}) => {
    const mdEditor = useRef(null);

    const handleEditorChange = ({html, text}) => {
        // console.log('handleEditorChange', html, text);
        onChange && onChange(text);
    }

    const getmd5 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                const spark = new SparkMD5.ArrayBuffer();
                spark.append(reader.result)
                let md5 = spark.end();
                resolve(md5)
            };
            reader.onerror = (err) => {
                reject(err)
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const handleImageUpload = (file, callback) => {
        (async () => {
            // console.log(file);
            let md5 = await getmd5(file);
            const suffix = file.name.slice(file.name.lastIndexOf('.'));
            // console.log("suffix", suffix)
            let alikey = "mde/" + md5 + suffix;
            let res = await getSTSTestcases(alikey);
            const client = new AliOSS({
                region: 'oss-cn-hangzhou',
                // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
                accessKeyId: res.AccessKeyId,
                accessKeySecret: res.AccessKeySecret,
                stsToken: res.SecurityToken,
                bucket: 'yzsteam'
            });
// info.onProgress();
            client.multipartUpload(res.key, file)
                .then(result => {
                    callback(`https://yzsteam.oss-cn-hangzhou.aliyuncs.com/${alikey}`)
                })
                .catch(error => {
                    // reject(error);
                    // info.onError(error);
                    console.log(error);
                    // this.props.ErrorStatus(error);
                });

        })().catch();

    }

    // useEffect(()=>{
    //     mdEditor.current.setText(value);
    // },[value])

    const deletespan = () => {
        let value = mdEditor.current.getMdValue();
        let cleanedHtml = value.replace(/<[^>]+>/g, '');
        mdEditor.current.setText(cleanedHtml);
    }
    const replacelt = () => {
        let value = mdEditor.current.getMdValue();
        let cleanedHtml = value.replace(/&lt;/g, '<');
        mdEditor.current.setText(cleanedHtml);
    }
    const replacegt = () => {
        let value = mdEditor.current.getMdValue();
        let cleanedHtml = value.replace(/&gt;/g, '>');
        mdEditor.current.setText(cleanedHtml);
    }
    const replacenbsp = () => {
        let value = mdEditor.current.getMdValue();
        let cleanedHtml = value.replace(/&nbsp;/g, ' ');
        mdEditor.current.setText(cleanedHtml);
    }

    const replacebr = () => {
        let value = mdEditor.current.getMdValue();
        let cleanedHtml = value.replace(/<br \/>/g, '  ');
        mdEditor.current.setText(cleanedHtml);
    }
    const replacen = () => {
        let value = mdEditor.current.getMdValue();
        let cleanedHtml = value.replace(/\n[\t| ]+/g, '\n');
        cleanedHtml = cleanedHtml.replace(/^\s*/g, '');
        cleanedHtml = cleanedHtml.replace(/\n\n\n/g, '\n\n');
        mdEditor.current.setText(cleanedHtml);
    }

    const replaceall = () => {
        replacebr();
        deletespan();
        replacen();
        replacelt();
        replacegt();
        replacenbsp();
    }

    return (
        <div>
            <Editor
                ref={mdEditor}
                value={value || ""}
                style={{height: '300px'}}
                renderHTML={text => mdParser.render(text)}
                onChange={handleEditorChange}
                onImageUpload={handleImageUpload}
            />
            <a onClick={deletespan}>删除html标签</a>
            <a onClick={replacelt} style={{marginLeft: 10}}>替换lt;标签</a>
            <a onClick={replacegt} style={{marginLeft: 10}}>替换gt;标签</a>
            <a onClick={replacenbsp} style={{marginLeft: 10}}>替换nbsp;标签</a>
            <a onClick={replacebr} style={{marginLeft: 10}}>替换br标签</a>
            <a onClick={replacen} style={{marginLeft: 10}}>缩进</a>
            <a onClick={replaceall} style={{marginLeft: 10}}>一键处理</a>
        </div>
    )
}

export default MdEditor;
