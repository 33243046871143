import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {postTeacher} from "../../../lib/api_teachers";
import {Modal, Input, Form,Radio} from 'antd';
import md5 from 'md5';
import FormDanXuan from './form_danxuan';
import FormDanXuanAnswer from './form_danxuan_answer';
import MdEditor from '../../../components/md-editor/md-editor';
import {putQuestion} from "../../../lib/api_questions";
import FormPython from './form_python';
import TagsSelect from "./tags_select";


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const QuestionAddModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(data)
    }, []);

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.id = data.id;
                putQuestion(values)
                // values.password = md5(values.password);
                // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };


    return (
        <Modal
            title="添加Python编程题"
            visible={true}
            onCancel={onCancel}
            onOk={handleOk}
            width={800}
        >
            {/*<Input.TextArea rows={4} placeholder="智能识别" onChange={handleDanXuan}/>*/}
            <Form
                form={form}
            >
                <Form.Item
                    label="摘要标题"
                    name="title"
                    rules={[{
                        required: true,
                        message: '请输入摘要标题'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    // label="题目配置"
                    name="content"
                    rules={[{
                        required: true,
                        message: '请输入题目配置'
                    }]}
                >
                    <FormPython/>

                </Form.Item>
                <Form.Item
                    label="标签"
                    name="tags"
                >
                    <TagsSelect/>
                </Form.Item>

            </Form>
        </Modal>
    )
};


QuestionAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default QuestionAddModal;