import React, {useState, useEffect} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Table, Card} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import TaskForm from './task_form';
import EasyFormModal from '../../../components/easy_form_modal';
import {getTasks, postTask, putTask} from "../../../lib/api_tasks";


/**
 * 任务列表
 * @returns {JSX.Element}
 * @constructor
 */
const Tasks = () => {
    const [list, setList] = useState([]);
    const [addModal, setAddModal] = useState(null);
    const [editRecord, setEditRecord] = useState(null);

    /**
     * 获取任务列表
     */
    const fetchData = () => {
        getTasks()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                }
            })
    };

    useEffect(fetchData, []);

    /**
     * 表格列
     */
    const columns = [
        {
            title: "编号",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "名称",
            dataIndex: "title",
            key: "title",
            render: (title, record) => (
                <div>
                    <a onClick={() => {
                        setEditRecord(record);
                    }}>{title}</a>
                </div>
            )
        },
        {
            title: "操作",
            key: "action",
            render: (text, record) => (
                <div>
                    <a onClick={() => {
                        setEditRecord(record);
                    }}>修改</a>
                </div>
            )
        }
    ]

    /**
     * 渲染
     */
    return (
        <PageContent title={"任务管理"}>
            <Card bordered={false}>
                <Button type='primary' icon={<PlusOutlined/>}
                        onClick={() => setAddModal({})}
                        style={{marginBottom: 10}}>添加任务</Button>

                <Table
                    dataSource={list}
                    rowKey={"id"}
                    pagination={false}
                    size={"small"}
                    columns={columns}
                />

                {!!addModal && <EasyFormModal
                    data={addModal}
                    title={"添加任务"}
                    onOk={() => {
                        setAddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setAddModal(false);
                    }}
                    formComponent={TaskForm}
                    restFunc={postTask}
                    width={1000}
                />}
                {!!editRecord && <EasyFormModal
                    data={editRecord}
                    title={"修改任务"}
                    onOk={() => {
                        setEditRecord(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        setEditRecord(null);
                    }}
                    formComponent={TaskForm}
                    restFunc={putTask}
                    width={1000}
                />}
            </Card>
        </PageContent>
    );
};


export default Tasks;


