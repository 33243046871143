import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input, Button, Select} from 'antd';
import MdEditor from '../../../components/md-editor/md-editor';
import {CloseOutlined} from '@ant-design/icons';


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const FormOptions = ({value, onChange}) => {
    const [config, setConfig] = useState([{
        key: "A",
        value: "",
    }]);

    useEffect(() => {
        if(value){
            setConfig(value);
        }
    }, [value]);

    const handleChange = () => {
        setConfig([...config]);
        onChange && onChange(config);
    };

    const handleAdd = ()=>{
        config.push({
            key: "A",
            value: "",
        });
        handleChange();
    };


    const handleDelete = (index) =>{
        config.splice(index, 1);
        handleChange();
    };

    return (
        <div>
            {config.map((item,index)=>(
                <div style={{
                    display: "flex"
                }}>
                    <Select value={item.key} style={{ width: 80 }}
                            onChange={(value)=>{
                                item.key = value;
                                handleChange();
                            }}
                    >
                        <Select.Option value="A">A</Select.Option>
                        <Select.Option value="B">B</Select.Option>
                        <Select.Option value="C">C</Select.Option>
                        <Select.Option value="D">D</Select.Option>
                        <Select.Option value="E">E</Select.Option>
                        <Select.Option value="F">F</Select.Option>
                        <Select.Option value="G">G</Select.Option>
                    </Select>
                    {/*<MdEditor value={item.value} onChange={(value)=>{*/}
                        {/*item.value = value;*/}
                        {/*handleChange();*/}
                    {/*}}/>*/}

                    <MdEditor value={item.value} onChange={(value)=>{
                        item.value = value;
                        handleChange();
                    }} />
                    {/*<Input.TextArea value={item.value} onChange={(e)=>{*/}
                    {/*    item.value = e.target.value;*/}
                    {/*    handleChange();*/}
                    {/*}} autoSize/>*/}

                    <Button icon={<CloseOutlined />} onClick={()=>handleDelete(index)}></Button>

                </div>
            ))}
            <Button onClick={handleAdd}>+</Button>

        </div>
    )
};


FormOptions.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default FormOptions;