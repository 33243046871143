import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {PlusOutlined, DeleteOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import {Table, Divider, Popconfirm, Button, message, Tag, Input, Card, Space, Row, Col, Popover} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getQuestions, delQuestion} from "../../../lib/api_questions";
import QustionAddModal from './question_add_modal_danxuan';
import QuestionEditModal from './question_edit_modal_danxuan';
import QustionAddModalPanDuan from './question_add_modal_panduan';
import QuestionEditModalPanDuan from './question_edit_modal_panduan';
import QustionAddModalPython from './question_add_modal_python';
import QustionEditModalPython from './question_edit_modal_python';
import QustionAddModalScratch from './question_add_modal_scratch';
import QustionEditModalScratch from './question_edit_modal_scratch';
import Question_add_modal_duoxuan from "./question_add_modal_duoxuan";
import Question_edit_modal_duoxuan from "./question_edit_modal_duoxuan";

import ExaminationModal from './examination_modal';
import styles from './index.module.css';
import TagsEditModal from "./tags_edit_modal";
import Draggable from 'react-draggable'
import QuestionDetail from "./question_detail";
import useUrlState from "@ahooksjs/use-url-state";

const {Column} = Table;

const Questions = () => {
    const [list, setlist] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, seteditModal] = useState(null);
    const [addModalpanduan, setaddModalpanduan] = useState(false);
    const [editModalpanduan, seteditModalpanduan] = useState(null);
    const [addModalpython, setaddModalpython] = useState(false);
    const [editModalpython, seteditModalpython] = useState(null);
    const [selected, setselected] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createPaper, setcreatePaper] = useState(null);
    const [addModalscratch, setaddModalscratch] = useState(false);
    const [editModalscratch, seteditModalscratch] = useState(null);
    const [addModalduoxuan, setaddModalduoxuan] = useState(false);
    const [editModalduoxuan, seteditModalduoxuan] = useState(null);
    const [showTagsEditModal, setshowTagsEditModal] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [state, setState] = useUrlState({
        page: 1, pageSize: 10, key: "",
    });
    const [total, setTotal] = useState(0);

    const fetchData = () => {
        getQuestions(state)
            .then((res) => {
                if (res.code === 0) {
                    setlist(res.data.records);
                    setTotal(res.data.total);
                }
            })
    };

    useEffect(fetchData, [state]);

    const handleDelete = (id) => {
        // console.log(selectedRows)
        delQuestion(selectedRows.map(m => m.id))
            .then((result) => {
                if (result.code === 0) {
                    fetchData();
                }
            })
    };

    const handleAddPocket = () => {
        let index = 0;
        for (let i in selectedRows) {
            let record = selectedRows[i];
            if (selected.some(m => m.id === record.id)) {
                // message.warn("重复添加")
            } else {
                selected.push(record);
                index += 1;
            }
        }
        setselected([...selected]);
        message.success(`成功添加${index}条`);
    };

    const createExamPaper = () => {
        setcreatePaper(selected);
    };

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        // selectedRowKeys,
        onChange: onSelectChange,
    };

    const onSearch = (value, _e, info) => setState({...state, key: value});

    const handlerPageChange = (page, pageSize) => {
        setState({
            ...state,
            page, pageSize
        })
    };


    return (
        <PageContent title={"试题库"}>
            <Draggable>
                <div className={styles.pocket}>
                    <div>单选题：{selected.filter(m => m.type === "单选题").length}</div>
                    <div>单选题：{selected.filter(m => m.type === "多选题").length}</div>
                    <div>判断题：{selected.filter(m => m.type === "判断题").length}</div>
                    <div>python题：{selected.filter(m => m.type === "python").length}</div>
                    <div>scratch题：{selected.filter(m => m.type === "scratch").length}</div>
                    <Space>
                        <Button onClick={createExamPaper}>生成试卷</Button>
                        <Button onClick={() => setselected([])}>清空</Button>
                    </Space>
                </div>
            </Draggable>
            <Card bordered={false}>
                <Input.Search onSearch={onSearch} enterButton style={{marginBottom: 10}}/>

                <Row justify={"space-between"}
                     gutter={[8,8]}
                     style={{marginBottom: 10}}>
                    <Col>
                        <Space>
                            <Button icon={<ShoppingCartOutlined/>}
                                    disabled={selectedRows.length === 0}
                                    onClick={handleAddPocket}>添加到试题篮</Button>

                            <Button icon={<ShoppingCartOutlined/>}
                                    disabled={selectedRows.length === 0}
                                    onClick={() => setshowTagsEditModal(true)}>设置标签</Button>

                            <Popconfirm
                                title={`是否删除${selectedRows.length}条记录？`}
                                onConfirm={handleDelete}
                                // onCancel={cancel}
                                okText="删除"
                                cancelText="取消"
                                disabled={selectedRows.length === 0}
                            >
                                <Button disabled={selectedRows.length === 0} icon={<DeleteOutlined/>}
                                        danger>删除</Button>
                            </Popconfirm>

                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setAddModal(true)}>单选题</Button>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setaddModalduoxuan(true)}>多选题</Button>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setaddModalpanduan(true)}>判断题</Button>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setaddModalpython(true)}>Python</Button>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setaddModalscratch(true)}>Scratch</Button>
                        </Space>
                    </Col>

                </Row>
                <Table dataSource={list} rowKey={"id"}
                       pagination={{
                           current: parseInt(state.page),
                           pageSize: parseInt(state.pageSize),
                           total: total,
                           position: ["bottomCenter"],
                           onChange: handlerPageChange,
                           size: "default",
                           showQuickJumper:true,
                           showTotal:  (total) => `共 ${total} 条记录`
                       }}
                       size="small"
                       rowSelection={rowSelection}
                    // scroll={{
                    //     // x: 1500,
                    //     y: `calc(100vh - 230px)`,
                    // }}
                >
                    <Column
                        title="编号"
                        dataIndex="id"
                        key="id"
                        width={60}
                        render={(id, record) => (
                            <>
                                {record.type === "单选题" && <a onClick={() => seteditModal(record)}>{id}</a>}
                                {record.type === "多选题" && <a onClick={() => seteditModalduoxuan(record)}>{id}</a>}
                                {record.type === "判断题" && <a onClick={() => seteditModalpanduan(record)}>{id}</a>}
                                {record.type === "python" && <a onClick={() => seteditModalpython(record)}>{id}</a>}
                                {record.type === "scratch" && <a onClick={() => seteditModalscratch(record)}>{id}</a>}
                            </>
                        )}
                    />

                    <Column
                        title="类型"
                        dataIndex="type"
                        width={100}
                        key="类型"
                    />
                    <Column
                        title="标题"
                        dataIndex="title"
                        key="标题"
                        ellipsis={true}
                        render={(title, record) => {
                            return (<Popover mouseEnterDelay={1} content={<QuestionDetail data={record}/>}>
                                {title}
                            </Popover>)
                        }}
                    />
                    <Column
                        title="标签"
                        dataIndex="tags"
                        width={200}
                        render={(tags) => tags ? JSON.parse(tags).map((tag) => (
                            <Tag color="blue" key={tag}>{tag}</Tag>)) : null}
                    />
                </Table>
            </Card>
            {addModal &&
                <QustionAddModal
                    onOk={() => {
                        setAddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setAddModal(false);
                    }}
                    visible={addModal}
                />
            }
            {editModal &&
                <QuestionEditModal
                    data={editModal}
                    onOk={() => {
                        seteditModal(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        seteditModal(null);
                    }}
                />
            }
            {addModalduoxuan &&
                <Question_add_modal_duoxuan
                    onOk={() => {
                        setaddModalduoxuan(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setaddModalduoxuan(false);
                    }}
                    visible={addModalduoxuan}
                />
            }
            {editModalduoxuan &&
                <Question_edit_modal_duoxuan
                    data={editModalduoxuan}
                    onOk={() => {
                        seteditModalduoxuan(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        seteditModalduoxuan(null);
                    }}
                />
            }

            {addModalpanduan &&
                <QustionAddModalPanDuan
                    onOk={() => {
                        setaddModalpanduan(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setaddModalpanduan(false);
                    }}
                    visible={addModalpanduan}
                />
            }
            {editModalpanduan &&
                <QuestionEditModalPanDuan
                    data={editModalpanduan}
                    onOk={() => {
                        seteditModalpanduan(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        seteditModalpanduan(null);
                    }}
                />
            }

            {addModalpython &&
                <QustionAddModalPython
                    onOk={() => {
                        setaddModalpython(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setaddModalpython(false);
                    }}
                    visible={addModalpython}
                />
            }
            {addModalscratch &&
                <QustionAddModalScratch
                    onOk={() => {
                        setaddModalscratch(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setaddModalscratch(false);
                    }}
                    visible={addModalscratch}
                />
            }
            {editModalscratch &&
                <QustionEditModalScratch
                    data={editModalscratch}
                    onOk={() => {
                        seteditModalscratch(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        seteditModalscratch(null);
                    }}
                />
            }
            {editModalpython &&
                <QustionEditModalPython
                    data={editModalpython}
                    onOk={() => {
                        seteditModalpython(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        seteditModalpython(null);
                    }}
                />
            }
            {createPaper &&
                <ExaminationModal
                    data={createPaper}
                    onOk={() => {
                        setcreatePaper(null);
                        // fetchData();
                    }}
                    onCancel={() => {
                        setcreatePaper(null);
                    }}
                />
            }
            {showTagsEditModal &&
                <TagsEditModal
                    ids={selectedRows.map(m => m.id)}
                    onOk={() => {
                        setshowTagsEditModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setshowTagsEditModal(false);
                    }}
                />
            }

        </PageContent>
    );
};

export default Questions;
