/**
 * @name: 相册
 * @description:
 * @author: Wood
 * @date: 2022/12/23
 * @update:
 */
import PropTypes from 'prop-types';
import React, {Component, useState, useEffect, useRef} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Row, Col, Tag, Button, DatePicker, List, Image, Card, Input, Popover} from 'antd';
import VirtualList from 'rc-virtual-list';

import PageContent from '../../../components/page-content/page-content';
import moment from 'moment';
// import styles from './notification.module.css';
// import RoomName from '../../../components/rooms/room-name';
import {getNotificationList, sendNotification} from "../../../lib/api_notification";

import {getPhotos} from "../../../lib/api_photos";
import {useInfiniteScroll} from 'ahooks';

const {Search} = Input;

const ContainerHeight = 400;
const PAGE_SIZE = 48;

const Photos = ({}) => {
    const ref = useRef(null);
    const [keyword, setKeyword] = useState('');
    // const [data, setdata] = useState([]);

    const fetchData = (page, pageSize, keyword) => {
        return new Promise((resolve) => {
            getPhotos({page, pageSize, key: keyword}).then(res => {
                if (res.code === 0) {
                    resolve({
                        list: res.data.list,
                        total: res.data.total,
                    });
                }
            })
        })

    };

    // useEffect(fetchData, []);

    const {data, loading, loadMore, loadingMore, noMore, reload} = useInfiniteScroll(
        (d) => {
            const page = d ? Math.ceil(d.list.length / PAGE_SIZE) + 1 : 1;
            return fetchData(page, PAGE_SIZE, keyword);
        },
        {
            target: ref,
            isNoMore: (d) => {
                console.log(d);
                if (d) {
                    if (d.list.length < d.total) {
                        return false;
                    }
                }
                return true;
                // return d? d.list.length < d.total: false
            },
            reloadDeps: [keyword]
        },
        )
    ;
    return (
        <PageContent title={"相册"} routes={[
            {
                path: '',
                breadcrumbName: "首页",
            },
            {
                path: 'students',
                breadcrumbName: '学生管理',
            },
        ]}>
            <Card bordered={false}>
                <Search
                    placeholder="标签/姓名/备注等"
                    onSearch={(value) => {
                        console.log(value);
                        setKeyword(value);
                        // reload();
                    }}
                    enterButton
                    style={{
                        width: 300,
                        marginBottom: 12
                    }}
                />
                <div ref={ref} style={{height: 'calc(100vh - 180px)', overflow: 'auto'}}>
                    {loading ? (
                        <p>loading</p>
                    ) : (
                        <Row gutter={[12, 12]}>
                            {data.list.map((item) => (
                                <Col key={item.id}>
                                    <div>
                                        {item.file_type == 'image' && <Image
                                            width={200}
                                            src={item.photo_url}
                                        />}
                                        {item.file_type == 'video' &&
                                        <video
                                            src={item.photo_url}
                                            preload="none"
                                            controls="controls"
                                            width={200}
                                        >
                                            您的浏览器不支持 video 标签。
                                        </video>
                                        }
                                    </div>
                                    <div style={{width: 200, overflow: "hidden"}}>
                                        <div>
                                            {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
                                        </div>
                                        {item.tags} <Popover content={<div style={{width: 300}}>{item.remark}</div>}
                                                             title="点评内容">
                                        <a style={{marginLeft: 6}}>详情</a>
                                    </Popover>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    )}

                    <div style={{marginTop: 8}}>
                        {!noMore && (
                            <button type="button" onClick={loadMore} disabled={loadingMore}>
                                {loadingMore ? 'Loading more...' : 'Click to load more'}
                            </button>
                        )}

                        {noMore && <span>No more data</span>}
                    </div>
                </div>
            </Card>
        </PageContent>
    )
}


export default Photos;

