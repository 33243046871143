import React from "react";
import {Typography} from 'antd';

const {Text} = Typography;

/**
 * 比赛状态，未开始，进行中，已结束
 * @param status
 * @returns {JSX.Element|null}
 * @constructor
 */
const ContestStatus = ({status}) => {
    if (status === -1) return (<Text type="success" style={{fontWeight: "bold"}}>未开始</Text>);
    if (status === 0) return (<Text type="warning" style={{fontWeight: "bold"}}>进行中</Text>);
    if (status === 1) return (<Text type="danger" style={{fontWeight: "bold"}}>已结束</Text>);
    return null;
}

export default React.memo(ContestStatus);