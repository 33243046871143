import React from 'react';
import {withRouter} from 'react-router-dom'

import MdEditor from '../../../components/md-editor/md-editor';
import {postChallenge} from "../../../lib/api_challenges";
import styles from './challenges.module.css';
import message from 'antd/lib/message';
import 'antd/lib/message/style/css';

import {Form, Slider, Button, Tag, Select,Radio} from 'antd';
import TagsSelector from './tags_selector';
import {CloseOutlined} from '@ant-design/icons';

// import Container from '../components/container';
import ChoiceTag from './choice_tag';
import PageContent from '../../../components/page-content/page-content';

const {CheckableTag} = Tag;

class ChallengesAddChoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            name: '',
            config: {
                question: '',    //问题
                options: [], // 选项
                answer: []   // 答案
            },
            tutorial_content: '',
            tags: '[]',
            difficulty: 0,

        }
    }

    saveChanges = (e) => {
        e.preventDefault();
        // if(!this.state.pkey){
        //     return;
        // }

        if (!this.state.config.question) {
            message.error('请输入题目');
            return;
        }

        if (this.state.config.options.length === 0) {
            message.error('请输入选择项');
            return;
        }
        // 处理数据
        let config = {
            question: this.state.config.question,
            answer: [],
            options: {}
        };
        // 处理options
        this.state.config.options.forEach((item, index) => {
            let tag = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index];
            config.options[tag] = item.content;
            if (item.isAnswer === true) {
                config.answer.push(tag);
            }
        });

        // this.state.config.answer = [];
        // this.state.config.options.forEach((item, index) => {
        //     if (item.isAnswer === true) {
        //         this.state.config.answer.push(index);
        //     }
        // });

        if (config.answer.length === 0) {
            message.error('请设置答案');
            return;
        }
        if (this.state.type === 1) {
            if (config.answer.length > 1) {
                message.error('单选题只能设置一个答案');
                return;
            }
        }
        // console.log(config)
        // this.state.config.options.filter(item=>item.isAnswer===true).map(item=> {return item.id})
        postChallenge({
            type: this.state.type,
            name: this.state.config.question,
            config: JSON.stringify(config),
            tutorial_content: this.state.tutorial_content,
            tags: this.state.tags,
            difficulty: this.state.difficulty,
        })
            .then(res => {
                if (res.code === 0) {

                    // 插入成功
                    this.props.history.push("/school/challenges/");
                    return;
                }
            })
            .catch(err => {

            });
    }

    render() {
        // console.log(this.state.config)
        return (
            <PageContent title="添加选择题">

                <div className={styles['challenges-detail']}>

                    <Form {...{
                        labelCol: {
                            xs: {span: 24},
                            sm: {span: 4},
                        },
                        wrapperCol: {
                            xs: {span: 24},
                            sm: {span: 16},
                        },
                    }}
                          onSubmit={this.saveChanges}>

                        <Form.Item label="题型">
                            <Select defaultValue={1}
                                    value={this.state.type}
                                    style={{width: 120}}
                                    onChange={value => {
                                        this.state.type = value;
                                        this.setState({})
                                    }}>
                                <Select.Option value={1}>单选题</Select.Option>
                                <Select.Option value={2}>多选题</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="难度">
                            <Radio.Group buttonStyle="solid"
                                         value={this.state.difficulty}
                                         onChange={e => {
                                             this.state.difficulty = e.target.value;
                                             this.setState({})
                                         }}
                            >
                                <Radio.Button value={0}>简单</Radio.Button>
                                <Radio.Button value={1}>一般</Radio.Button>
                                <Radio.Button value={2}>困难</Radio.Button>
                                <Radio.Button value={3}>高级</Radio.Button>
                                <Radio.Button value={4}>专家</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="题目" required>
                            <MdEditor value={this.state.config.question}
                                      onChange={value => {
                                          this.state.config.question = value;
                                          this.setState({})
                                      }}/>
                        </Form.Item>
                        <Form.Item label="选项" required>
                            {this.state.config.options.map((item, index) => {
                                    return (
                                        <div className={'flex'} key={index}>
                                            <ChoiceTag index={index}/>.
                                            {/*<Tag>{index + 1}</Tag>.*/}
                                            <MdEditor value={item.content}
                                                      onChange={value => {
                                                          item.content = value;
                                                          this.setState({})
                                                      }}/>
                                            <a onClick={() => {
                                                this.state.config.options.splice(index, 1);
                                                this.setState({})
                                            }}><CloseOutlined /></a>
                                        </div>)
                                }
                            )}

                            <Button shape="circle" icon="plus" onClick={() => {
                                this.state.config.options.push({
                                    id: '',
                                    content: '',
                                    isAnswer: false
                                })
                                this.setState({})
                            }}/>
                        </Form.Item>
                        <Form.Item label="答案" required>
                            {this.state.config.options.map((item, index) => {
                                    return (
                                        <CheckableTag
                                            checked={item.isAnswer}
                                            color="green"
                                            key={index}
                                            onChange={() => {
                                                item.isAnswer = !item.isAnswer;
                                                this.setState({})
                                            }}
                                        >
                                            <ChoiceTag index={index}/>
                                        </CheckableTag>
                                    )
                                }
                            )}
                        </Form.Item>
                        <Form.Item label="题解">
                            <MdEditor value={this.state.tutorial_content}
                                      onChange={value => this.setState({tutorial_content: value})}/>

                        </Form.Item>
                        <Form.Item label="标签">
                            <TagsSelector
                                value={this.state.tags}
                                onChange={(value) => {
                                    console.log(value)
                                    this.setState({tags: value})
                                }}
                            />

                        </Form.Item>
                        <Form.Item {...{
                            wrapperCol: {
                                xs: {
                                    span: 24,
                                    offset: 0,
                                },
                                sm: {
                                    span: 18,
                                    offset: 4,
                                },
                            }
                        }}>
                            <Button type='primary' htmlType="submit" size="large" className='button-color-green'
                                    icon="save">保存</Button>
                        </Form.Item>

                    </Form>

                </div>
            </PageContent>
        )
    }
}


ChallengesAddChoice.propTypes = {
    // challenges: PropTypes.shape({
    //     offset: PropTypes.number,
    //     data: PropTypes.array
    // }),
    // addChallenges: PropTypes.func,
    // clearChallenges: PropTypes.func
};


export default withRouter(ChallengesAddChoice);
