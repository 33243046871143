import React from 'react';
import {Form, message, Modal} from "antd";
import {postContest} from "../../../lib/api_contest";
import ContestForm from "./contest_form";

/**
 * 比赛添加模态框
 * @param onOk
 * @param onCancel
 * @returns {JSX.Element}
 * @constructor
 */
const ContestAddModal = ({onOk, onCancel}) => {
    const [form] = Form.useForm();

    /**
     * 表单提交
     */
    const handleFinish = () => {
        form.validateFields()
            .then((values) => {
                console.log('Received values of form: ', values);
                postContest({
                    ...values,
                    start_time: values.start_time.format("YYYY-MM-DD HH:mm:ss"),
                    end_time: values.end_time.format("YYYY-MM-DD HH:mm:ss"),
                    duration: values.end_time.diff(values.start_time, "seconds"),
                }).then(res => {
                    if (res.code === 0) {
                        message.success("保存成功!");
                        handleOk();
                    } else {
                        message.error(res.message);
                    }
                })
            })
    };

    /**
     * 点击确定按钮
     */
    const handleOk = () => {
        onOk && onOk();
    }

    /**
     * 点击取消按钮
     */
    const handleCancel = () => {
        onCancel && onCancel();
    }

    /**
     * 渲染
     */
    return (
        <Modal
            title={"创建比赛"}
            open={true}
            onCancel={handleCancel}
            onOk={handleFinish}
            width={800}
        >
            <ContestForm form={form}
                         onFinish={handleFinish}
                         initialValues={{
                             type: 1,
                             auth: 0,
                             visible: 1,
                             user_id: 15,
                             author: "毛老师"
                         }}/>
        </Modal>)
}

export default ContestAddModal;