import React, {useEffect} from 'react';
import {Form, message, Modal} from "antd";
import {getContest, putContest} from "../../../lib/api_contest";
import moment from "moment";
import ContestForm from "./contest_form";

/**
 * 编辑比赛对话框
 * @param id 比赛id
 * @param onOk
 * @param onCancel
 * @returns {JSX.Element}
 * @constructor
 */
const ContestEditModal = ({id, onOk, onCancel}) => {
    const [form] = Form.useForm();

    /**
     * 读取数据
     */
    const fetchData = () => {
        getContest(id)
            .then(res => {
                if (res.code === 0) {
                    form.setFieldsValue({
                        ...res.data,
                        start_time: moment(res.data.start_time),
                        end_time: moment(res.data.end_time),
                    });
                }
            })
    };

    /**
     * 组件挂载时加载数据
     */
    useEffect(fetchData, []);

    /**
     * 提交表单
     */
    const handleFinish = () => {
        form.validateFields()
            .then((values) => {
                console.log('Received values of form: ', values);
                putContest(id, {
                    ...values,
                    start_time: values.start_time.format("YYYY-MM-DD HH:mm:ss"),
                    end_time: values.end_time.format("YYYY-MM-DD HH:mm:ss"),
                    duration: values.end_time.diff(values.start_time, "seconds"),
                }).then(res => {
                    if (res.code === 0) {
                        message.success("保存成功!");
                        handleOk();
                    } else {
                        message.error(res.message);
                    }
                })
            })
    };

    /**
     * 点击确定
     */
    const handleOk = () => {
        onOk && onOk();
    }

    /**
     * 点击取消
     */
    const handleCancel = () => {
        onCancel && onCancel();
    }

    /**
     * 渲染
     */
    return (
        <Modal
            title={"编辑比赛"}
            open={true}
            onCancel={handleCancel}
            onOk={handleFinish}
            width={800}
        >
            <ContestForm form={form} onFinish={handleFinish}/>
        </Modal>)
}

export default ContestEditModal;