import React,{useEffect, useState} from 'react';
import {Form, Input, InputNumber,DatePicker,Select} from 'antd';
import {getPapers} from "../../../lib/api_papers";
import AliyunOSSUpload from '../../../components/aliyun-oss-upload';
import DictSelect from '../../../components/dict/dict-radio-group';
import default_form_item_layout from "../../../config/default_form_item_layout";

const { RangePicker } = DatePicker;


const CourseEditForm = ({form, onFinish, initialValues}) => {
    const [papers, setpapers] = useState([]);
    // 获得试卷列表
    useEffect(()=>{
        getPapers().then(res=>{
            if(res.code === 0){
                setpapers(res.data);
            }
        })
    },[]);

    return (
        <Form {...default_form_item_layout}
              form={form}
              onFinish={onFinish}
              initialValues={initialValues}
        >
            <Form.Item name="title" label={"测验名称"} rules={[
                {
                    required: true,
                    message: '请输入测验名称',
                }
            ]}>
                <Input/>
            </Form.Item>
            <Form.Item name="begin_time" label={"开始时间"} rules={[
                {
                    required: true,
                    message: '请输入开始时间',
                }
            ]}>
                <DatePicker showTime format="YYYY-MM-DD HH:mm"/>
            </Form.Item>
            <Form.Item name="end_time" label={"结束时间"} rules={[
                {
                    required: true,
                    message: '请输入结束时间',
                }
            ]}>
                <DatePicker showTime format="YYYY-MM-DD HH:mm"/>
            </Form.Item>

            <Form.Item name="paper_id" label={"试卷"} rules={[
                {
                    required: true,
                    message: '请选择试卷',
                }
            ]}>
                <Select>
                    {papers.map(m=>(
                        <Select.Option value={m.id}>{m.title}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

        </Form>
    )
};


export default CourseEditForm;